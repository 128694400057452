import React, { Component } from 'react';

import { SpinnerOverlay, SpinnerContainer } from '../../components/with-spinner/with-spinner.styles';

class Logout extends Component {
  isSnsUser = () => {
    return this.props.snsAuth && this.props.snsAuth.snsLogin && this.props.snsAuth.snsLogin === true;
  };

  componentDidMount = () => {
    if (this.isSnsUser()) {
      this.props.snsSignOut(this.props.match.params.lang);
    } else {
      const { match, history } = this.props;
      this.props.signOut();
      this.props.clearCurrentUser();
      history.push(`/${match.params.lang}/`);
    }
  };

  render() {
    return (
      <SpinnerOverlay>
        <SpinnerContainer />
      </SpinnerOverlay>
    );
  }
}

export default Logout;
