import React, { Component as ReactComponent } from 'react';
import PropTypes from 'prop-types';
import Route from 'react-router-dom/Route';
import Redirect from 'react-router-dom/Redirect';
import { isOwner } from '../../../utils';
import { state } from '../../../redux/sagas/aws-cognito-redux-saga';

class PrivateRoute extends ReactComponent {
  render () {
    const { component: Component, ...rest } = this.props;
    return (<Route
    {...rest}
    render={props =>
      rest.auth.isSignedIn === state.AUTH_SUCCESS ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: `/${rest.computedMatch.params.lang}/login`, state: { from: props.location } }} />
      )
    }
    />);
  }
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    isSignedIn: PropTypes.string,
  }).isRequired,
  locale: PropTypes.string.isRequired,
};

const OwnerRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.auth.isSignedIn === state.AUTH_SUCCESS && rest.user && rest.user.role && isOwner(rest.user.role) ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: `/${rest.computedMatch.params.lang}/login`, state: { from: props.location } }} />
      )
    }
  />
);

OwnerRoute.propTypes = {
  component: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    isSignedIn: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
  locale: PropTypes.string.isRequired,
};

export default PrivateRoute;
export const OwnerPrivateRoute = OwnerRoute;
