import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { state } from '../../redux/sagas/aws-cognito-redux-saga';
import Hero from './Hero';
import ModuleList from './ModuleList';
import { MODULE_BG_COLOR } from '../../styles/variables.style';

const Wrapper = styled.div`
  background-color: ${MODULE_BG_COLOR};
`;

class HomePage extends Component {
  static propTypes = {
    sections: PropTypes.arrayOf(PropTypes.shape()),
    fetchSections: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        lang: PropTypes.string,
      }),
    }).isRequired,
    auth: PropTypes.shape({
      isSignedIn: PropTypes.string,
    }).isRequired,
    siteOptions: PropTypes.shape({}).isRequired,
  };

  static defaultProps = {
    sections: [],
  };

  componentDidMount = () => {
    const { sections, fetchSections, match } = this.props;
    if (!sections.length) {
      fetchSections({ order: '-fields.date', moduleOrder: 'fields.moduleTitle' }, match.params.lang);
    }
  };

  render() {
    const { sections, match, auth, authRedirect, siteOptions } = this.props;
    const { lang } = match.params;

    return (
      <Wrapper>
        {auth.isSignedIn !== state.AUTH_SUCCESS ? (
          <div>
            <Hero lang={lang} copy={siteOptions.siteCopyELearningDashboardPage} />
            <ModuleList
              sections={sections.length > 0 ? sections : undefined}
              locale={lang}
              copy={siteOptions.siteCopyELearningDashboardPage}
            />
          </div>
        ) : (
          <Redirect
            to={{
              pathname: authRedirect ? authRedirect : `/${lang}/dashboard`,
              state: {
                from: this.props.location,
              },
            }}
          />
        )}
      </Wrapper>
    );
  }
}

export default HomePage;
