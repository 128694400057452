import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { ContentWrapper, Wrapper, Title, Subtitle, Button } from './NotFoundPage.style';

const NotFoundPage = ({ copy }) => (
  <Wrapper>
    <ContentWrapper>
      <Title>{copy.error_page_title}</Title>
      <Subtitle>{copy.error_page_subtitle}</Subtitle>
      <Link to="/" href="/">
        <Button>{copy.error_page_button}</Button>
      </Link>
    </ContentWrapper>
  </Wrapper>
);

NotFoundPage.defaultProps = {
  copy: {
    error_page_title: 'Apologies, we can’t find the page you’re looking for.',
    error_page_button: 'Back To Homepage',
    error_page_subtitle: 'You might want to return home and try again.',
  },
};

export default connect(({ siteOptions }) => ({ copy: siteOptions.siteCopyELearningNotFoundPage }))(NotFoundPage);
