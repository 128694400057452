import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import fetchSections from '../../redux/actions/sections';
import { state } from '../../redux/sagas/aws-cognito-redux-saga';
import fetchQuiz from '../../redux/actions/quizzes';
import { fetchUserProgress, updateUserProgress } from '../../redux/actions/userProgress';
import { fetchUser } from '../../redux/actions/user';
import fetchModules from '../../redux/actions/modules';
import getQuizBySlug from '../../redux/selectors/quizzes';
import BadgeImg from '../../assets/images/badge.png';
import { checkIfSameCertificate, makeCertificateData } from '../../utils';
import { createCertificateService, getCertificateService } from '../../services/api';
import Placeholder from '../../components/Placeholder';
import AnswerReview from './AnswerReview';
import UserAgent from '../../utils/UserAgent';

import {
  QuizResultsHero,
  RegisterBtnWrapper,
  ReviewBtnWrapper,
  Score,
  Content,
  ContentText,
  ProfileInfo,
  ProfileImageWrapper,
  ProfileName,
  ProfileTitle,
  Badge,
} from './QuizResultsPage.style';

const Wrapper = styled.div``;

class QuizResultsPage extends Component {
  static propTypes = {
    auth: PropTypes.shape({
      isSignedIn: PropTypes.string,
    }).isRequired,
    quiz: PropTypes.shape(),
    user: PropTypes.shape().isRequired,
    fetchUserProgress: PropTypes.func.isRequired,
  };

  static defaultProps = {
    quiz: {},
    copy: {
      next: 'Next',
      quiz: 'Quiz',
      previous: 'Previous',
      get_results: 'Get Results',
      quiz_subtitle: 'You have reached the end of this course. Take this quiz, see how much you’ve learnt!',
      review_course: 'Review Course',
      quiz_results_copy:
        'Congratulations! You have completed this module. Take the skill test to get certified by Shiseido. Good luck!',
      register_button_copy: 'Register for skill test',
      retake_quiz: 'Retake quiz',
      quiz_not_completed_copy: 'You need a full score to pass the test, please try again!',
      quiz_all_completed:
        'Congratulations! You have completed all the crouses on our site. Download your certificate of achievement and register for skill test. Good luck!',
      download_certificate: 'Download Certificate',
    },
    modules: [],
  };

  isQuizFinished = props => {
    const { quiz, match, history, answers } = props;
    if (!quiz) return false;
    if (answers.length === quiz.questions.length) {
      return true;
    }
    history.push(`/${match.params.lang}/course/${match.params.section}/${match.params.module}/quiz`);
  };

  state = {
    isQuizFinished: this.isQuizFinished(this.props),
    isCompleted: undefined,
    allQuizesInSectionCompleted: false,
  };

  componentDidMount() {
    const {
      quiz,
      fetchQuiz,
      match,
      auth,
      userProgress,
      fetchUserProgress,
      fetchUser,
      fetchSections,
      user,
      modules,
      fetchModules,
      sections,
    } = this.props;

    if (auth.isSignedIn === state.AUTH_SUCCESS && !quiz.name) {
      fetchQuiz(match.params.module, match.params.lang);
    }

    if (!sections.length) {
      fetchSections({ order: '-fields.date', moduleOrder: 'fields.moduleTitle' }, match.params.lang);
    }

    if (auth.isSignedIn === state.AUTH_SUCCESS && !userProgress.chapters) {
      fetchUserProgress(auth.info.username);
    }

    if (auth.isSignedIn === state.AUTH_SUCCESS && auth.info && auth.info.username && user.length === 0) {
      fetchUser(auth.info.username);
    }

    if (modules.length === 0) {
      fetchModules({ limit: 15, order: 'fields.moduleTitle' }, match.params.lang);
    }

    if (this.state.isCompleted === undefined && sections.length && userProgress.completedQuizzes) {
      this.getResult(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      auth,
      quiz,
      user,
      match,
      fetchQuiz,
      userProgress,
      fetchUserProgress,
      fetchUser,
      modules,
      fetchModules,
      sections,
    } = nextProps;
    if (this.props.auth.isSignedIn !== auth.isSignedIn && auth.isSignedIn === state.AUTH_SUCCESS && !quiz.name) {
      fetchQuiz(match.params.module, match.params.lang);
    }
    if (
      this.props.auth.isSignedIn !== auth.isSignedIn &&
      auth.isSignedIn === state.AUTH_SUCCESS &&
      !userProgress.chapters
    ) {
      fetchUserProgress(auth.info.username);
    }
    if (this.props.auth.isSignedIn !== auth.isSignedIn && auth.isSignedIn === state.AUTH_SUCCESS && user.length === 0) {
      fetchUser(auth.info.username);
    }
    if (this.props.userProgress.chapters !== nextProps.userProgress.chapters) {
      this.setState({
        isQuizFinished: this.isQuizFinished(nextProps),
      });
    }

    if (modules.length === 0) {
      fetchModules({ limit: 15, order: 'fields.moduleTitle' }, match.params.lang);
    }

    if (this.state.isCompleted === undefined && sections.length && userProgress.completedQuizzes) {
      this.getResult(nextProps);
    }
  }

  getScore = () => {
    const { quiz, answers } = this.props;
    return `${
      quiz.questions
        ? quiz.questions.reduce((score, question, i) => (answers[i][question.correctAnswer - 1] ? score + 1 : score), 0)
        : 0
    }/${answers.length}`;
  };

  getResult = props => {
    const { answers, copy, updateUserProgress, auth, userProgress, quiz, minimumPassingScorePercentage } = props;
    const newUserProgress = { ...userProgress };
    const correctAnswers = answers.filter((answer, i) => answer[quiz.questions[i].correctAnswer - 1]);
    const isCompleted = (correctAnswers.length / quiz.questions.length) * 100 >= minimumPassingScorePercentage;

    this.setState({ isCompleted });
    if (isCompleted) {
      if (!newUserProgress.completedQuizzes) {
        newUserProgress.completedQuizzes = [quiz.relatedModule];
      } else {
        newUserProgress.completedQuizzes = [...new Set(newUserProgress.completedQuizzes.concat(quiz.relatedModule))];
      }
    }

    const newUserProgressData = JSON.stringify(newUserProgress);
    updateUserProgress(auth.info.username, newUserProgressData);
    this.checkIfCompletedSection(props, newUserProgress);

    TagManager.dataLayer({
      dataLayer: {
        event: 'el_quiz',
        el_display: isCompleted ? copy.quiz_results_copy : copy.quiz_not_completed_copy,
      },
    });
  };

  checkIfCompletedSection = (props, newUserProgress) => {
    const { match, sections } = props;
    const { module } = match.params;
    const currentSection = sections.find(section => {
      return section.modules.find(m => m.moduleSlug === module);
    });
    const allQuizesInSectionCompleted = currentSection.modules.every(m =>
      newUserProgress.completedQuizzes.includes(m.moduleSlug)
    );
    this.setState({ allQuizesInSectionCompleted });
  };

  openPDF = url => {
    const isApp = UserAgent.isApp();

    if (isApp && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(url);
      return;
    }

    const link = document.createElement('a');
    link.target = '_blank';
    link.href = url;
    link.click();
  }

  onClickDownload = async section => {
    const { auth, match, user, userProgress, updateUserProgress } = this.props;
    const locale = match.params.lang;

    if (auth.isSignedIn === state.AUTH_UNKNOWN || auth.isSignedIn === state.AUTH_FAIL) {
      return;
    }

    if (!userProgress.certificates) {
      userProgress.certificates = [];
    }

    const savedCertificate = userProgress.certificates.find(checkIfSameCertificate({ locale, user, section }));
    const certificate = savedCertificate ? await getCertificateService(savedCertificate.key) : await createCertificateService(locale, user, section);

    if (!certificate.success) {
      return;
    }

    if (!savedCertificate) {
      userProgress.certificates.push({ ...makeCertificateData({ locale, user, section }), key: certificate.key });
      const userProgressData = JSON.stringify(userProgress);
      updateUserProgress(auth.info.username, userProgressData);
    }

    this.openPDF(certificate.url);
  }

  render() {
    const { answers, sections, match, user, userProgress, copy, quiz } = this.props;
    const { isQuizFinished, isCompleted, allQuizesInSectionCompleted } = this.state;

    let score = '0';
    if (isQuizFinished) {
      score = this.getScore();
    }

    const currentSection = sections.find(section => {
      return section.modules.find(m => m.moduleSlug === match.params.module);
    });

    return isQuizFinished ? (
      <Wrapper>
        <QuizResultsHero>
          {userProgress.chapters ? <Score>{score}</Score> : <Placeholder w="50px" h="70px" />}
        </QuizResultsHero>
        {user && (
          <ProfileInfo>
            <ProfileImageWrapper>{allQuizesInSectionCompleted && <Badge src={BadgeImg} />}</ProfileImageWrapper>
            {user ? (
              <div>
                <ProfileName>
                  {user.given_name} {user.family_name}
                </ProfileName>
                <ProfileTitle>{user.job_title}</ProfileTitle>
              </div>
            ) : (
              <Placeholder w="100px" h="100px" />
            )}
          </ProfileInfo>
        )}
        <Content>
          {isCompleted ? (
            <ContentText>{copy.quiz_results_copy}</ContentText>
          ) : (
            <ContentText>{copy.quiz_not_completed_copy}</ContentText>
          )}
          {isCompleted && allQuizesInSectionCompleted ? <ContentText>{copy.quiz_all_completed}</ContentText> : null}
          <AnswerReview answers={answers} questions={quiz.questions} copy={copy} />
          <RegisterBtnWrapper>
            {isCompleted ? (
              <Fragment>
                {allQuizesInSectionCompleted && (
                  <Fragment>
                    <a
                      href="#download"
                      className="download"
                      onClick={e => {
                        e.preventDefault();
                        this.onClickDownload(currentSection);
                      }}
                    >
                      {copy.download_certificate}
                    </a>
                  </Fragment>
                )}
                <Link to={`/${match.params.lang}/dashboard`} href={`/${match.params.lang}/dashboard`}>
                  {copy.register_button_copy}
                </Link>
              </Fragment>
            ) : (
              <Link
                to={`/${match.params.lang}/course/${match.params.section}/${match.params.module}/quiz`}
                href={`/${match.params.lang}/course/${match.params.section}/${match.params.module}/quiz`}
              >
                {copy.retake_quiz}
              </Link>
            )}
          </RegisterBtnWrapper>

          <ReviewBtnWrapper>
            <Link
              to={`/${match.params.lang}/course/${match.params.section}/${match.params.module}/`}
              href={`/${match.params.lang}/course/${match.params.section}/${match.params.module}/`}
            >
              {copy.review_course}
            </Link>
          </ReviewBtnWrapper>
        </Content>
      </Wrapper>
    ) : (
      <div style={{ marginTop: '200px', marginLeft: 'auto', marginRight: 'auto' }}>
        <Placeholder w="300px" h="100px" />
      </div>
    );
  }
}

const mapState = (state, props) => {
  const quiz = getQuizBySlug(state, props);
  return {
    answers: state.answers,
    auth: state.auth,
    userProgress: state.userProgress.data ? JSON.parse(state.userProgress.data) : {},
    quiz,
    user: state.user,
    copy: state.siteOptions.siteCopyELearningQuizPage,
    modules: state.modules,
    minimumPassingScorePercentage: state.siteOptions.minimumPassingScorePercentage,
    sections: state.sections,
  };
};

const mapDispatch = dispatch => ({
  fetchSections: (options, locale) => dispatch(fetchSections(options, locale)),
  fetchQuiz: (slug, locale) => dispatch(fetchQuiz(slug, locale)),
  fetchUserProgress: username => dispatch(fetchUserProgress(username)),
  fetchUser: username => dispatch(fetchUser(username)),
  fetchModules: (options, locale) => dispatch(fetchModules(options, locale)),
  updateUserProgress: (username, data, isCompleted) => dispatch(updateUserProgress(username, data, isCompleted)),
});

export default connect(mapState, mapDispatch)(QuizResultsPage);
