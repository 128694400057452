import { GET_MODULES, GET_QUIZ } from '../constants';

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_MODULES}_PENDING`:
      return state;
    case `${GET_MODULES}_FULFILLED`: {
      const data = action.payload;
      return [
        ...state,
        ...data.reduce((arr, module) => {
          if (state.length !== 0 && state.find(m => m.moduleTitle === module.moduleTitle)) {
            return arr;
          }
          return arr.concat(module);
        }, []),
      ];
    }
    case `${GET_QUIZ}_FULFILLED`: {
      const data = action.payload;
      const existingModuleIndex = state.findIndex(module => module.moduleSlug === data.relatedModule.moduleSlug);

      return existingModuleIndex >= 0
        ? [...state.slice(0, existingModuleIndex), data.relatedModule, ...state.slice(existingModuleIndex + 1)]
        : state.concat(data.relatedModule);
    }
    case `${GET_MODULES}_REJECTED`:
      return state;
    default:
      return state;
  }
};
