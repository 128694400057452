export const GET_SECTIONS = 'GET_SECTIONS';
export const GET_MODULES = 'GET_MODULES';
export const GET_QUIZ = 'GET_QUIZ';
export const UPDATE_LOCALE_COPY = 'UPDATE_LOCALE_COPY';
export const GET_USER = 'GET_USER';
export const GET_USER_AVATAR = 'GET_USER_AVATAR';
export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR';
export const GET_USER_PROGRESS = 'GET_USER_PROGRESS';
export const UPDATE_USER_PROGRESS = 'UPDATE_USER_PROGRESS';
export const UPDATE_SCREEN_SIZE = 'UPDATE_SCREEN_SIZE';
export const GET_SITE_OPTIONS = 'GET_SITE_OPTIONS';
export const LOAD_SIGN_UP2_FORM = 'LOAD_SIGN_UP2_FORM';
export const GET_TERMS = 'GET_TERMS';
export const GET_FAQ = 'GET_FAQ';
export const CLEAR_USER = 'CLEAR_USER';
export const DO_NOTHING = 'DO_NOTHING';
export const UPDATE_AUTH_REDIRECT = 'UPDATE_AUTH_REDIRECT';
export const UPDATE_ANSWERS = 'UPDATE_ANSWERS';
