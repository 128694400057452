import { GET_QUIZ } from '../constants';

const removeModuleReferences = data =>
  Array.isArray(data)
    ? data.map(entry => ({ ...entry, relatedModule: entry.relatedModule.moduleSlug }))
    : { ...data, relatedModule: data.relatedModule.moduleSlug };

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_QUIZ}_PENDING`:
      return state;
    case `${GET_QUIZ}_FULFILLED`: {
      const data = action.payload;
      return {
        ...state,
        [data.relatedModule.moduleSlug]: removeModuleReferences(data),
      };
    }
    case `${GET_QUIZ}_REJECTED`:
      return state;
    default:
      return state;
  }
};
