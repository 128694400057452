export const DEFAULT_FONT_FAMILY = 'FuturaStd, Helvetica, Arial, sans-serif';
export const MODULE_BG_COLOR = '#e4e1e1';
export const SCREEN_LOCKUP_WIDTH = 1062;
export const MOBILE_PADDING = 20;
export const LIGHT_BLACK = '#333333';
export const BLACK = '#000';
export const GREY = '#d2d2d2';
export const LIGHT_GREY = '#f2f2f2';
export const MEDIUM_GREY = '#e0e0e0';
export const DARK_GREY = '#d8d8d8';
export const ACCENT = '#b8193f';
export const WHITE = '#fff';
export const screenSizes = {
  desktopLarge: 1800,
  desktop: 1200,
  tabletL: 900,
  tabletP: 600,
};
export const NAVIGATION_DESKTOP_HEIGHT = 93;
export const NAVIGATION_MOBILE_HEIGHT = 60;
export const NAVIGATION_DESKTOP_PADDING = 30;
export const NAVIGATION_MOBILE_PADDING = 17;
export const PAGE_WIDTH = 1440;
