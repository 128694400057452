import circularJSON from 'circular-json';

import { toParameterString, fixedFetch } from '../utils';
import config from '../config';
import { config as cognito } from '../redux/sagas/aws-cognito-redux-saga';
import { getCurrentUser } from '../redux/sagas/sns-auth-redux-saga/amplify.bridge';

const makeGETRequest = url =>
  fixedFetch(url, {
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'content-type': 'application/json',
    },
    method: 'GET',
    mode: 'cors',
    referrer: 'no-referrer',
  });

const makeAuthenticatedGETRequest = async url => {
  const token = await cognito.getSession();
  return fixedFetch(url, {
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'content-type': 'application/json',
      Authorization: token.idToken.jwtToken,
    },
    method: 'GET',
    mode: 'cors',
    referrer: 'no-referrer',
  });
};

const makePOSTRequest = async (url, body) => {
  const token = await cognito.getSession();
  return fixedFetch(url, {
    body: JSON.stringify(body), // must match 'Content-Type' header
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'content-type': 'application/json',
      Authorization: token.idToken.jwtToken,
    },
    method: 'POST',
    mode: 'cors',
    redirect: 'follow',
    referrer: 'no-referrer',
  });
};

export const getContent = input => {
  const params = toParameterString(input);
  return makeGETRequest(`/api/content?${params}`).then(data => {
    const rebuiltData = circularJSON.parse(data);
    if (rebuiltData.status && rebuiltData.status !== 200) {
      throw rebuiltData;
    }
    return rebuiltData;
  });
};

export const fetchUserService = username => {
  const params = toParameterString({ username });
  return makeAuthenticatedGETRequest(`${config.EXPRESS_API}/api/user?${params}`);
};

export const fetchUserAvatarService = key => {
  const params = toParameterString({ key });
  return makeAuthenticatedGETRequest(`${config.EXPRESS_API}/api/asset?${params}`);
};

export const updateUserProgressService = (userId, userProgress) => {
  return makePOSTRequest(`/api/user/progress`, {
    userId,
    userProgress,
  });
};

export const createCertificateService = (locale, user, section) => {
  const certificateData = {
    locale,
    user: {
      id: user.userId,
      name: `${user.given_name} ${user.family_name}`,
    },
    certificate: {
      course: {
        slug: section.slug,
        name: section.sectionTitle,
        level: section.level,
      },
      chapters: section.modules.map(m => m.moduleTitle),
    }
  };

  return makePOSTRequest(`${config.EXPRESS_API}/api/user/certificates`, certificateData);
};

export const getCertificateService = key => {
  const params = toParameterString({ key });
  return makeAuthenticatedGETRequest(`${config.EXPRESS_API}/api/user/certificates?${params}`);
};

export const fetchUserProgressService = username => {
  const params = toParameterString({ username });
  return makeAuthenticatedGETRequest(`/api/user/progress?${params}`);
};

export const fetchStylistsProgressService = username => {
  return makeAuthenticatedGETRequest(`${config.EXPRESS_API}/api/user/${username}/stylists`);
};

const makeAuthPOSTRequest = async (url, body, token) => {
  return fixedFetch(url, {
    cache: 'no-cache',
    // credentials: 'include',
    mode: 'cors',
    method: 'POST',
    referrer: 'no-referrer',
    headers: new Headers({
      'content-type': 'application/json',
      Authorization: token,
    }),
    body: JSON.stringify(body),
  });
};

export const disableProviderForUser = async provider => {
  const user = await getCurrentUser();
  const jwtToken = user.signInUserSession.idToken.jwtToken;
  const payload = user.signInUserSession.idToken.payload;
  const identities = payload.identities;
  const facebookIdentity = identities.filter(i => i.providerName === provider)[0];
  const body = {
    providerName: provider,
    userPoolId: user.pool.userPoolId,
    userName: user.username,
    linkedUserId: facebookIdentity.userId,
  };

  return makeAuthPOSTRequest(`${config.SNS_API}/api/sns/disable-provider`, body, jwtToken).then(response => {
    if (response.status && response.status !== 200) {
      throw response;
    }
    return response;
  });
};

export const linkProviderForUser = async (providerName, id, masterEmail) => {
  const user = await getCurrentUser();
  const jwtToken = user.signInUserSession.idToken.jwtToken;

  const body = {
    providerName: providerName,
    userPoolId: user.pool.userPoolId,
    userName: user.username,
    linkId: id,
    masterEmail,
  };

  return makeAuthPOSTRequest(`${config.SNS_API}/api/sns/link-provider`, body, jwtToken).then(response => {
    if (response.status && response.status !== 200) {
      throw response;
    }
    return response;
  });
};
