import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { state } from '../../redux/sagas/aws-cognito-redux-saga';
import fetchQuiz from '../../redux/actions/quizzes';
import updateAnswers from '../../redux/actions/answers';
import { fetchUser } from '../../redux/actions/user';
import { fetchUserProgress } from '../../redux/actions/userProgress';
import getQuizBySlug from '../../redux/selectors/quizzes';
import { getQuizRelatedModule } from '../../redux/selectors/modules';

import Placeholder from '../../components/Placeholder';

import QuizContent from './QuizContent';

import { QuizHero, QuizHeroTitle } from './QuizPage.style';

const Wrapper = styled.div``;

class QuizPage extends Component {
  static propTypes = {
    auth: PropTypes.shape({
      isSignedIn: PropTypes.string,
    }).isRequired,
    quiz: PropTypes.shape(),
    fetchUserProgress: PropTypes.func.isRequired,
  };

  static defaultProps = {
    quiz: {},
    copy: {
      next: 'Next',
      quiz: 'Quiz',
      previous: 'Previous',
      get_results: 'Get Results',
      quiz_subtitle: 'You have reached the end of this course. Take this quiz, see how much you’ve learnt!',
      review_course: 'Review Course',
      quiz_results_copy:
        'Congratulations! You have completed this module. Take the skill test to get certified by Shiseido. Good luck!',
      register_button_copy: 'Register for skill test',
    },
  };

  state = {
    answers: this.props.quiz.questions
      ? this.props.quiz.questions.map(question => question.answers.map(() => false))
      : [[]],
  };

  constructor(props) {
    super(props);
    const initialAnswers = props.quiz.questions
      ? props.quiz.questions.map(question => question.answers.map(() => false))
      : [[]];
    props.updateAnswers(initialAnswers);
  }

  componentDidMount() {
    const { quiz, fetchQuiz, match, auth, userProgress, fetchUserProgress, user } = this.props;

    if (auth.info && auth.info.username && !user.userId) {
      fetchUser(auth.info.username);
      fetchUserProgress(auth.info.username);
    }

    if (auth.isSignedIn === state.AUTH_SUCCESS && !quiz.name) {
      fetchQuiz(match.params.module, match.params.lang);
    }

    if (auth.isSignedIn === state.AUTH_SUCCESS && auth.info && auth.info.username && !userProgress.chapters) {
      fetchUserProgress(auth.info.username);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { auth, quiz, match, fetchQuiz, userProgress, fetchUserProgress, history, updateAnswers } = nextProps;

    if (auth.isSignedIn === state.AUTH_FAIL) {
      history.push(`/${match.params.lang}/login`);
    }

    if (this.props.auth.isSignedIn !== auth.isSignedIn && auth.isSignedIn === state.AUTH_SUCCESS && !quiz.name) {
      fetchQuiz(match.params.module, match.params.lang);
    }
    if (
      this.props.auth.isSignedIn !== auth.isSignedIn &&
      auth.isSignedIn === state.AUTH_SUCCESS &&
      !userProgress.chapters
    ) {
      fetchUserProgress(auth.info.username);
    }
    if (!this.props.quiz.questions && nextProps.quiz.questions) {
      this.setState({
        answers: nextProps.quiz.questions.map(question => question.answers.map(() => false)),
      });
      const initialAnswers = nextProps.quiz.questions.map(question => question.answers.map(() => false));
      updateAnswers(initialAnswers);
    }
  }

  handleChooseAnswer = (currentQuestionIndex, answerIndex) => () => {
    this.setState(({ answers }) => ({
      answers: answers.map(
        (q, i) => (i === currentQuestionIndex ? q.map((a, i) => (i === answerIndex ? true : false)) : q)
      ),
    }));
    const { answers, updateAnswers } = this.props;
    updateAnswers(
      answers.map((q, i) => (i === currentQuestionIndex ? q.map((a, i) => (i === answerIndex ? true : false)) : q))
    );
  };

  goResultPage = () => {
    const { match, history } = this.props;
    const { section, module } = match.params;
    history.push(`/${match.params.lang}/course/${section}/${module}/results`);
  };

  render() {
    const { quiz, module, copy, answers } = this.props;
    return (
      <Wrapper>
        <QuizHero url={module.moduleHero && module.moduleHero.file.url}>
          {module.moduleTitle ? <QuizHeroTitle>{module.moduleTitle}</QuizHeroTitle> : <Placeholder w="30%" h="40px" />}
          <p>{copy.quiz_subtitle}</p>
        </QuizHero>
        <QuizContent
          quiz={quiz}
          answers={answers}
          onChooseAnswer={this.handleChooseAnswer}
          onQuizFinished={this.goResultPage}
          copy={copy}
        />
      </Wrapper>
    );
  }
}

const mapState = (state, props) => {
  const quiz = getQuizBySlug(state, props);
  return {
    answers: state.answers,
    auth: state.auth,
    userProgress: state.userProgress.data ? JSON.parse(state.userProgress.data) : {},
    quiz,
    user: state.user,
    module: quiz ? getQuizRelatedModule(state, quiz) : {},
    copy: state.siteOptions.siteCopyELearningQuizPage,
  };
};

const mapDispatch = dispatch => ({
  fetchQuiz: (slug, locale) => dispatch(fetchQuiz(slug, locale)),
  fetchUser: username => dispatch(fetchUser(username)),
  fetchUserProgress: username => dispatch(fetchUserProgress(username)),
  updateAnswers: answers => dispatch(updateAnswers(answers)),
});

export default connect(mapState, mapDispatch)(QuizPage);
