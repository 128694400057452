import { GET_SITE_OPTIONS } from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case `${GET_SITE_OPTIONS}_PENDING`:
      return state;
    case `${GET_SITE_OPTIONS}_FULFILLED`: {
      const siteOptions = action.payload;
      return { ...state, ...siteOptions };
    }
    case `${GET_SITE_OPTIONS}_REJECTED`: {
      return state;
    }
    case 'UPDATE_LOCALE': {
      return { ...state, locale: action.locale };
    }
    default:
      return state;
  }
};
