import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { state } from '../../redux/sagas/aws-cognito-redux-saga';
import { reducer } from '../../redux/sagas/aws-cognito-redux-saga';

import { fetchUser } from '../../redux/actions/user';
import fetchSections from '../../redux/actions/sections';

import { fetchUserProgress } from '../../redux/actions/userProgress';

import Loading from '../../components/Loading';
import ModuleCard from '../../components/ModuleCard';

import QuizIcon from '../../assets/images/quiz.png';
import QuizIconWhite from '../../assets/images/quiz_white.png';

import { MOBILE_PADDING, WHITE, SCREEN_LOCKUP_WIDTH } from '../../styles/variables.style';
import media from '../../styles/media.style';

const Wrapper = styled.div`
  background-color: #e4e1e1;
`;

const ListWrapper = styled.div`
  width: 100%;
  max-width: ${SCREEN_LOCKUP_WIDTH + MOBILE_PADDING}px;
  margin: 0 auto;
  padding: 3em ${MOBILE_PADDING}px;

  ${media.tabletL`
    padding: 5em ${MOBILE_PADDING}px;
  `};
`;

const Hero = styled.div`
  height: 265px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeroTitle = styled.h1`
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: ${WHITE};

  ${media.tabletL`
    font-size: 38px;
  `};
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;

  li {
    margin-bottom: 20px;
  }

  ${media.tabletL`
    flex-direction: row;
    flex-wrap: wrap;
    li {
      width: 32%;
      margin: 1.3% 1.3% 0 0;
    }
  `};
`;

const BackButton = styled.div`
    width: 248px;
    height: 57px;
    background-color: black;
    color: white;
    line-height: 57px;
    text-align: center;
    margin: 0 auto 100px auto;
    text-transform: uppercase;

    ${media.tabletL`
      width: 320px;
      height: 64px;
      line-height: 64px;
      margin-top: 20px;
    `};
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
`;

class SectionPage extends Component {
  static defaultProps = {
    modules: [{ key: 1 }, { key: 2 }, { key: 3 }],
    sections: [],
    copy: {
      chapters: 'lessons',
      minutes: 'mins',
      back: 'back',
    }
  };

  UNSAFE_componentWillMount = () => {
    const { sections, fetchSections, match } = this.props;

    if (sections.length === 0) {
      fetchSections({ order: '-fields.date', moduleOrder: 'fields.moduleTitle' }, match.params.lang);
    }
  };

  componentWillReceiveProps(nextProps) {
    const { auth, user, fetchUser, fetchUserProgress } = nextProps;

    if (auth.info && auth.info.username && user.length === 0) {
      fetchUser(auth.info.username);
      fetchUserProgress(auth.info.username);
    }
  }

  render() {
    const { sections, match, copy, userProgress, auth } = this.props;
    const locale = match.params.lang;
      const currentSection = sections.find(item => {
        return item.slug === match.params.section;
      });

    if (!userProgress.data && auth.isSignedIn === state.AUTH_SUCCESS) {
      return <Loading />;
    }

    let userProgressData;
    if (userProgress.data) {
      userProgressData = JSON.parse(userProgress.data);
    }

    const contents = currentSection ? currentSection.modules.map(m => ({
      key: m.moduleSlug,
      link: `/${locale}/course/${currentSection.slug}/${m.moduleSlug}`,
      thumbnailUrl: m.moduleThumbnail.file.url,
      category: m.moduleCategory,
      badgeImage: userProgressData ?
        (userProgressData.completedQuizzes.includes(m.moduleSlug) ? QuizIcon : QuizIconWhite) :
        QuizIconWhite,
      title: m.moduleTitle,
      infoList: [
        ` ${m.moduleChapters.filter(c => c.memberflag !== false).length} ${copy.chapters} `,
        ` ${m.moduleDuration} ${copy.minutes}`,
      ],
      ...m
    })) : [];


    return currentSection ? (
      <Wrapper>
        <Hero style={{
            backgroundImage: `url(https:${currentSection.heroImage &&
              currentSection.heroImage.file.url}?w=420&fm=jpg&fl=progressive)`,
          }}>
          <HeroTitle>{currentSection.sectionTitle}</HeroTitle>
        </Hero>

        <ListWrapper>
          <List>
            {contents.map(content => (
              <li key={content.key}>
                <ModuleCard content={content} locale={locale} progress={userProgress.data} copy={copy} />
              </li>
            ))}
          </List>
        </ListWrapper>

        <BackButton>
          <Link
            to={auth.isSignedIn === state.AUTH_SUCCESS ? `/${locale}/dashboard` : `/${locale}`}
            href={auth.isSignedIn === state.AUTH_SUCCESS ? `/${locale}/dashboard` : `/${locale}`}>
              {copy.back}
          </Link>
        </BackButton>
      </Wrapper>
    ) : (
      <Loading />
    );
  }
}

const mapStatetoProps = ({ auth, sections, siteOptions, user, userProgress }) => ({
  auth,
  sections,
  copy: siteOptions.siteCopyELearningDashboardPage,
  user,
  userProgress
});

const mapDispatchToProps = dispatch => ({
  signedIn: () => dispatch(reducer.signedIn()),
  signIn: (username, password) => dispatch(reducer.signIn(username, password)),
  fetchUser: username => dispatch(fetchUser(username)),
  fetchSections: (options, locale) => dispatch(fetchSections(options, locale)),
  fetchUserProgress: username => dispatch(fetchUserProgress(username))
});

export default connect(mapStatetoProps, mapDispatchToProps)(SectionPage);
