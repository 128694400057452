import styled from 'styled-components';
import media from '../../styles/media.style';

export const LocaleIndicator = styled.div`
  cursor: pointer;
  font-size: 12px;
  margin-left: 10px;
  text-decoration: underline;
  white-space: nowrap;

  ${media.tabletP`
    font-size: 14px;
  `};

  ${media.tabletL`
    font-size: 16px;
    margin-left: 20px;
  `};
`;

export const Selector = styled.div`
  align-items: center;
  display: flex;
  height: 24px;
  position: relative;
`;

export const Flag = styled.img`
  border: 1px solid grey;
  cursor: pointer;
  height: 24px;
  width: 32px;
`;

export const Panel = styled.div`
  background-color: white;
  border: 1px solid lightgrey;
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  opacity: ${({ open }) => (open ? 1 : 0)};
  padding: 30px;
  position: absolute;
  right: ${({ right }) => right};
  top: ${({ top }) => top};
  transform: translateX(${({ translateX }) => translateX})
    translateY(${({ translateY, open }) => (open ? '0' : translateY)});
  transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out, transform 200ms ease-in-out;
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  width: 300px;
`;

export const Locale = styled.div`
  align-items: center;
  border-radius: 2px;
  color: black;
  cursor: pointer;
  display: flex;
  padding: 5px 30px;
  transition: background-color 100ms ease-in-out;

  &:hover {
    background-color: #eee;
  }
`;

export const SmallFlag = styled.img`
  border: 1px solid lightgrey;
  height: 15px;
  width: 20px;
`;

export const Language = styled.div`
  font-size: 11px;
  letter-spacing: 0.4px;
  margin-left: 10px;
  min-width: 50px;
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'unset')};
`;

export const Region = Language.extend`
  flex-grow: 1;
`;

export const positionSettings = {
  top: {
    top: 'unset',
    bottom: '50px',
    left: 'unset',
    right: '50%',
    translateX: '50%',
    translateY: '10px',
  },
  'top-right': {
    top: 'unset',
    bottom: '50px',
    left: '0',
    right: 'unset',
    translateX: '0',
    translateY: '10px',
  },
  'bottom-left': {
    top: '50px',
    bottom: 'unset',
    left: 'unset',
    right: '0',
    translateX: '0',
    translateY: '-10px',
  },
};
