import snsAuthTypes from './types';
import { CLEAR_USER } from '../../constants';
import { AUTH_SIGN_UP, AUTH_SIGN_IN, AUTH_SIGN_OUT } from '../aws-cognito-redux-saga/actions';

const INITIAL_STATE = {
  authState: snsAuthTypes.SNS_AUTH_NA,
};

const snsAuthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case snsAuthTypes.SNS_AUTH_INITIALIZE:
      return {
        ...INITIAL_STATE,
      };

    case snsAuthTypes.SNS_LOGIN_STARTED:
      return {
        ...state,
        provider: action.payload.provider,
        locale: action.payload.locale,
        authState: action.type,
      };

    case snsAuthTypes.SNS_LOGIN_SUCCESS:
      return {
        ...state,
        authState: action.type,
        error: null,
        snsLogin: true,
      };

    case snsAuthTypes.SNS_LOGIN_SUCCESS_RELOAD:
    case snsAuthTypes.SNS_LOGIN_SUCCESS_RELOADING:
    case snsAuthTypes.SNS_LOGIN_UNLINK_LOGOUT_STARTED:
      return {
        ...state,
        authState: action.type,
      };

    case snsAuthTypes.SNS_LOGIN_FAIL:
      return {
        ...state,
        authState: action.type,
        error: action.payload,
      };

    case snsAuthTypes.SNS_LOGOUT_STARTED:
      return {
        ...state,
        authState: action.type,
        snsUser: {
          ...state.snsUser,
        },
        locale: action.payload,
        error: null,
      };

    case snsAuthTypes.SNS_SIGN_UP_PREPARE:
      console.log(JSON.stringify(action));
      return {
        ...state,
        authState: action.type,
      };

    case snsAuthTypes.SNS_SIGN_UP_STARTED:
      return {
        ...state,
        authState: action.type,
        signUpCode: action.payload.signUpCode,
        provider: action.payload.provider,
        snsUser: {
          salonInfo: action.payload.salonInfo,
        },
        locale: action.payload.locale,
        masterUser: null,
      };

    case snsAuthTypes.SNS_SIGN_UP_TEMP_SIGN_IN_FAIL:
      return {
        ...state,
        authState: action.type,
        error: action.payload,
      };

    case snsAuthTypes.SNS_SIGN_UP_TEMP_SIGNED_IN:
      console.log(JSON.stringify(action));
      return {
        ...state,
        authState: action.type,
        snsUser: {
          ...state.snsUser,
          id: action.payload.id,
          name: action.payload.name,
          email: action.payload.email,
          given_name: action.payload.given_name,
          family_name: action.payload.family_name,
        },
      };

    case snsAuthTypes.SNS_SIGN_UP_TEMP_SIGN_OUT_STARTED:
    case snsAuthTypes.SNS_SIGN_UP_TEMP_SIGNED_OUT:
      return {
        ...state,
        authState: action.type,
      };

    case snsAuthTypes.SNS_SIGN_UP_TEMP_SIGN_OUT_FAIL:
      return {
        ...state,
        authState: action.type,
        error: action.payload,
      };

    case snsAuthTypes.SNS_SIGN_UP_MASTER_STARTED:
    case snsAuthTypes.SNS_SIGN_UP_SUCCESS:
      return {
        ...state,
        authState: action.type,
      };

    case snsAuthTypes.SNS_SIGN_UP_FAIL:
      return {
        ...state,
        authState: action.type,
        error: action.payload,
      };

    /* LINK */
    case snsAuthTypes.SNS_LINK_DISABLE_STARTED:
      return {
        ...state,
        authState: action.type,
      };

    case snsAuthTypes.SNS_LINK_DISABLED:
      return {
        ...state,
        authState: action.type,
      };

    case snsAuthTypes.SNS_LINK_DISABLE_FAIL:
      return {
        ...state,
        authState: action.type,
        error: action.payload,
      };

    case snsAuthTypes.SNS_LINK_TEMP_SIGN_IN_STARTED:
      return {
        ...state,
        authState: action.type,
        locale: action.payload.locale,
        snsUser: {
          ...state.snsUser,
          provider: action.payload.provider,
        },
        masterUser: action.payload.masterUser,
      };

    case snsAuthTypes.SNS_LINK_TEMP_SIGNED_IN:
      return {
        ...state,
        authState: action.type,
        snsUser: {
          ...state.snsUser,
          id: action.payload.id,
        },
      };

    case snsAuthTypes.SNS_LINK_TEMP_SIGN_IN_FAIL:
      return {
        ...state,
        authState: action.type,
        error: action.payload,
      };

    case snsAuthTypes.SNS_LINK_TEMP_SIGN_OUT_STARTED:
    case snsAuthTypes.SNS_LINK_TEMP_SIGNED_OUT:
      return {
        ...state,
        authState: action.type,
      };

    case snsAuthTypes.SNS_LINK_TEMP_SIGN_OUT_FAIL:
      return {
        ...state,
        authState: action.type,
        error: action.payload,
      };

    case snsAuthTypes.SNS_LINK_CREATE_STARTED:
      return {
        ...state,
        authState: action.type,
      };

    case snsAuthTypes.SNS_LINK_MASTER_SIGNED_IN:
      return {
        ...state,
        authState: action.type,
      };

    case snsAuthTypes.SNS_LINK_MASTER_SIGN_IN_FAIL:
      return {
        ...state,
        authState: action.type,
        error: action.payload,
      };

    case snsAuthTypes.SNS_LINK_SUCCESS:
      return {
        ...state,
        authState: action.type,
        masterUser: null,
        snsUser: null,
      };

    case snsAuthTypes.SNS_LINK_CREATE_FAIL:
      return {
        ...state,
        authState: action.type,
        error: action.payload,
      };

    case `${CLEAR_USER}`:
    case AUTH_SIGN_UP:
    case AUTH_SIGN_IN:
    case AUTH_SIGN_OUT: {
      return {
        ...INITIAL_STATE,
      };
    }

    default:
      return state;
  }
};

export default snsAuthReducer;
