import styled from 'styled-components';

import media from '../../../styles/media.style';
import { SCREEN_LOCKUP_WIDTH, BLACK, WHITE, MOBILE_PADDING } from '../../../styles/variables.style';

export const Section = styled.section`
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 13em ${MOBILE_PADDING}px 0;
  background-image: url('https://images.ctfassets.net/hxo6ijjmlki5/3IxTBS7aSbPfBaQXo6rPZT/ccb59ff390b1807d69ebd65bbf7dc002/e-learning_HERO_VISUAL2021_2x.jpg?w=1440&q=100');

  ${media.tabletL`
    padding: 6em 0 8em;
  `};

  // ${media.x2`
  //   background-image: url('https://images.ctfassets.net/hxo6ijjmlki5/3IxTBS7aSbPfBaQXo6rPZT/ccb59ff390b1807d69ebd65bbf7dc002/e-learning_HERO_VISUAL2021_2x.jpg?w=2880&q=100');
  // `};

  // ${media.x3`
  //   background-image: url('https://images.ctfassets.net/hxo6ijjmlki5/3IxTBS7aSbPfBaQXo6rPZT/ccb59ff390b1807d69ebd65bbf7dc002/e-learning_HERO_VISUAL2021_2x.jpg?w=4320&q=100');
  // `};
`;

export const SectionInner = styled.div`
  width: 100%;
  max-width: ${SCREEN_LOCKUP_WIDTH}px;
  margin: 0 auto;
  color: #fff;
  padding-bottom: 4em;

  ${media.tabletL`
    padding-bottom: 0;
  `};
`;

export const HeroTitle = styled.h1`
  max-width: 600px;
  margin: 0 0 1.5em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 21px;
  padding: 0 19px;

  &.has-download-buttons {
    margin: 0 0 0.5em;
  }

  ${media.tabletL`
    margin: 0 0 3em;
    padding: 0;
    font-size: 50px;

    &.has-download-buttons {
      margin: 0 0 0.5em;
    }
  `};
`;

export const DownloadButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5em;
  margin: 0 0 0.5em;
  font-size: 21px;

  ${media.tabletL`
    justify-content: flex-start;
    gap: 0.1em;
    min-height: 2em;
    margin: 0 0 0.5em;
    font-size: 50px;
  `};
`;

export const GooglePlayButton = styled.a`
  display: inline-block;
  width: 141px;
  height: 42px;
  overflow: hidden;

  ${media.tabletL`
    width: 282px;
    height: 84px;
  `};
`;

export const GooglePlayLogo = styled.img`
  position: relative;
  left: -10.25px;
  top: -10.25px;
  width: 161.5px;
  max-width: none;
  height: 62.5px;

  ${media.tabletL`
    left: -20.5px;
    top: -20.5px;
    width: 323px;
    height: 125px;
  `};
`;

export const AppStoreButton = styled.a`
  display: inline-block;
  width: 126px;
  height: 42px;
  border-radius: 6.5px;
  overflow: hidden;

  ${media.tabletL`
    width: 252px;
    height: 84px;
    border-radius: 13px;
  `};
`;

export const AppStoreLogo = styled.img`
  width: 126px;
  max-width: none;
  height: 42px;
  border-radius: 6.5px;

  ${media.tabletL`
    width: 252px;
    height: 84px;
    border-radius: 13px;
  `};
`;

export const Subtitles = styled.div`
  display: flex;
`;

export const HeroSubtitle = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  border-right: 1px solid #fff;

  h2 {
    font-size: 11px;
    line-height: 1.25em;
    text-transform: uppercase;
    max-width: 240px;
    margin-top: 5px;

    ${media.tabletL`
      font-size: 23px;
    `};

    > span {
      font-weight: 800;
    }
  }
  p {
    max-width: 240px;
    font-size: 19px;
    line-height: 1.25em;
    font-weight: 400;
    margin-bottom: 5px;
  }

  &:last-child {
    border-right: none;
  }

  ${media.tabletL`
    border-left: 1px solid #fff;
    border-right: none;

    &:last-child {
      border-right: 1px solid #fff;
    }
  `};
`;

export const Text = styled.div`
  padding: 0 19px;

  p {
    display: none;

    ${media.tabletL`
      display: block;
    `};
  }
`;

export const CTA = styled.div`
  position: absolute;
  bottom: -31px;
  width: calc(100% - ${MOBILE_PADDING * 2}px);
  left: 50%;
  transform: translateX(-50%);
  background-color: ${BLACK};
  text-transform: uppercase;
  font-weight: 800;

  ${media.tabletL`
    width: auto;
  `};

  a {
    display: inline-block;
    color: ${WHITE};
    width: 100%;
    line-height: 63px;
    text-decoration: none;
    font-size: 11px;
    text-align: center;

    > span {
      font-weight: 800;
      letter-spacing: 1px;
    }

    ${media.tabletL`
      font-size: 15px;
      padding: 0 126px;
      width: auto;
    `};
  }
`;
