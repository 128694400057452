/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const AmplifyConfig = {
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID || 'ap-northeast-1:17e9acaa-1110-41dd-bae3-e1f9928cdfc4',
    region: process.env.REACT_APP_COGNITO_REGION || 'ap-northeast-1',
    userPoolId: process.env.REACT_APP_COGNITO_USERPOOL || 'ap-northeast-1_C2qMOVMJE',
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENTID || '3ouj088nka8e1879fsf3t1n8pv',
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN || 'shiseido-professional-dev.auth.ap-northeast-1.amazoncognito.com',
      scope: ['email', 'openid', 'profile'],
      redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_IN || 'http://localhost:3001/idpredirect/',
      redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_OUT || 'http://localhost:3001/idpredirect/',
      responseType: 'code',
    },
  },
};

export default AmplifyConfig;
