import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { state } from '../../redux/sagas/aws-cognito-redux-saga';
import { reducer } from '../../redux/sagas/aws-cognito-redux-saga';

import { fetchUser } from '../../redux/actions/user';
import fetchSections from '../../redux/actions/sections';
import fetchModules from '../../redux/actions/modules';
import { fetchUserProgress } from '../../redux/actions/userProgress';

import ChapterListItem from './ChapterListItem';
import Loading from '../../components/Loading';

import { MOBILE_PADDING, WHITE, SCREEN_LOCKUP_WIDTH } from '../../styles/variables.style';
import media from '../../styles/media.style';

const Wrapper = styled.div`
  background-color: #e4e1e1;
`;

const Hero = styled.div`
  padding: 35px ${MOBILE_PADDING}px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.tabletL`
    height: 265px;
    padding: 0;
  `};
`;

const ModuleIntro = styled.div`
  padding: 25px ${MOBILE_PADDING}px;
  max-width: ${SCREEN_LOCKUP_WIDTH + MOBILE_PADDING * 2}px;
  margin: 0 auto;

  ${media.tabletL`
    padding: 40px ${MOBILE_PADDING}px;
  `};
`;

const HeroTitle = styled.h1`
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: ${WHITE};

  ${media.tabletL`
    font-size: 38px;
  `};
`;

const ChapterList = styled.ul`
  list-style-type: none;
  padding: 0 ${MOBILE_PADDING}px;
  margin: 20px 0;
  max-width: ${SCREEN_LOCKUP_WIDTH}px;
  margin: 0 auto;

  ${media.tabletL`
    padding: 0;
  `};
`;

const ProgressSection = styled.div`
  padding: 25px ${MOBILE_PADDING}px;
  max-width: ${SCREEN_LOCKUP_WIDTH + MOBILE_PADDING * 2}px;
  margin: 0 auto;

  h2 {
    font-size: 21px;
    .normal {
      margin-left: 30px;
    }
  }
  h3 {
    font-size: 17px;
  }

  ${media.tabletL`
    padding: 0 ${MOBILE_PADDING}px 58px ${MOBILE_PADDING}px;
    h2 {
      font-size: 38px;
      margin-top: 24px;
      margin-bottom: 37px;
      .normal {
        font-weight: normal;
        margin-left: 30px;
      }
    }
    h3 {
      font-size: 22px;
    }
  `};

`;

const ProgressBar = styled.div`
  position: relative;
  display: inline-block;
  height: 15px;
  line-height: 15px;
  width: 100%;
  border-top: 3px solid #000;
  background: linear-gradient(90deg, #000 0%, #000 ${({ percent }) => percent}%, #e4e1e1 ${({ percent }) => percent}%, #e4e1e1 100%);
  text-align: right;

  :before {
    position: absolute;
    left: calc(${({ percent }) => percent}% - 10px);
    top: -20px;
    content: "${({ percent }) => percent}%";
    font-size: 11.25px;
  }
`;

const BottomNavigationLayout = styled.div`
  position: relative;
  height: 57px;
  margin: 40px auto 100px auto;
  max-width: 1062px;

  ${media.tabletL`
    height: 64px;
    margin: 80px auto 100px auto;
  `};
`;

const NavButton = styled.div`
    width: 248px;
    height: 57px;
    background-color: black;
    color: white;
    line-height: 57px;
    text-align: center;
    text-transform: uppercase;
    margin: 20px auto 0 auto;
    ${media.tabletL`
      width: 320px;
      height: 64px;
      line-height: 64px;
      margin: 0;
      ${({ small }) => small && `
        display: inline-block;
        min-width: 160px;
        width: auto;
        a {
          padding: 0 15px;
        }
      `}
      ${({ center }) => center && `
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
      `}
    `};
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
`;

class ModulePage extends Component {
  static defaultProps = {
    modules: [],
    sections: [],
    copy: {
      chapter: 'Ch',
      total: 'Total',
      lesson_progress: 'Lesson progress',
      lesson: 'Lesson',
      lessons: 'Lessons',
      minutes: 'mins',
      passed: 'Passed',
      not_passed: 'Not Passed',
      quiz: 'Quiz',
      member: 'Members only',
      back: 'back',
      course_list: 'top',
    }
  };

  UNSAFE_componentWillMount = () => {
    const { sections, modules, fetchSections, fetchModules, match } = this.props;

    if (sections.length === 0) {
      fetchSections({ order: '-fields.date', moduleOrder: 'fields.moduleTitle' }, match.params.lang);
    }

    if (modules.length === 0) {
      fetchModules({ limit: 30, order: 'fields.moduleTitle' }, match.params.lang);
    }

  };

  componentWillReceiveProps(nextProps) {
    const { auth, user, fetchUser, fetchUserProgress } = nextProps;

    if (auth.info && auth.info.username && user.length === 0) {
      fetchUser(auth.info.username);
      fetchUserProgress(auth.info.username);
    }
  }

  render() {
    const { sections, modules, match, copy, userProgress, auth } = this.props;
    const locale = match.params.lang;
    const currentSection = sections.find(item => {
      return item.slug === match.params.section;
    });
    const currentModule = modules.find(item => {
      return item.moduleSlug === match.params.module;
    });

    if (!userProgress.data && auth.isSignedIn === state.AUTH_SUCCESS) {
      return <Loading />;
    }

    let completedLessons = [];
    let isPassedQuiz;

    if (auth.isSignedIn === state.AUTH_SUCCESS) {
      let userProgressData = userProgress.data;
      let obj = JSON.parse(userProgressData);
      const currentModuleProgress = currentModule && obj.chapters.find(item => item.name === currentModule.moduleSlug);
      if (currentModuleProgress) {
        completedLessons = currentModuleProgress.completed;
      }
      isPassedQuiz = currentModule && obj.completedQuizzes.includes(currentModule.moduleSlug);
    }


    const memberOnlyChaptersLength = currentModule
      ? currentModule.moduleChapters.filter(c => c.memberflag !== false).length
      : 0;

    let percentText = '';
    let percent = 0;
    if (currentModule) {
      const a = currentModule.moduleChapters.filter(c => completedLessons.includes(c.chapterNumber)).length;
      const b = memberOnlyChaptersLength;
      percentText = `${a}/${b}`;
      percent = parseInt(a / b * 100, 10);
    }

    return currentModule ? (
      <Wrapper>
        <Hero style={{
            backgroundImage: `url(https:${currentModule.moduleHero &&
              currentModule.moduleHero.file.url}?w=420&fm=jpg&fl=progressive)`,
          }}>
          <HeroTitle>{currentModule.moduleTitle}</HeroTitle>
        </Hero>

        <ModuleIntro>
          <p>{currentModule.moduleDescription}</p>
          <p>
            {copy.total}: {memberOnlyChaptersLength} {memberOnlyChaptersLength.length === 1 ? copy.lesson : copy.lessons} | {currentModule.moduleDuration} {copy.minutes}
          </p>
        </ModuleIntro>
        <ProgressSection>
          <h2>{copy.lesson_progress}<span className="normal">{percentText}</span></h2>
          <ProgressBar percent={percent} />
          <h3>{copy.quiz} : {isPassedQuiz ? copy.passed : copy.not_passed}</h3>
        </ProgressSection>
        <ChapterList>
          {currentModule.moduleChapters.map((chapter, i) => (
            <ChapterListItem
              sectionSlug={match.params.section}
              slug={currentModule.moduleSlug}
              chapter={chapter}
              key={i}
              pos={i + 1}
              locale={locale}
              copy={copy}
              completed={completedLessons}
            />
          ))}
        </ChapterList>
        <BottomNavigationLayout>
          {currentSection && currentSection.modules.length > 1 && (
            <NavButton small>
              <Link
                to={`/${locale}/course/${match.params.section}`}
                href={`/${locale}/course/${match.params.section}`}>
                {copy.back}
              </Link>
            </NavButton>
          )}
          <NavButton center>
            <Link
              to={auth.isSignedIn === state.AUTH_SUCCESS ? `/${locale}/dashboard` : `/${locale}`}
              href={auth.isSignedIn === state.AUTH_SUCCESS ? `/${locale}/dashboard` : `/${locale}`}>
              {copy.course_list}
            </Link>
          </NavButton>
        </BottomNavigationLayout>
      </Wrapper>
    ) : (
      <Loading />
    );
  }
}

const mapStatetoProps = ({ auth, sections, modules, siteOptions, user, userProgress }) => ({
  auth,
  sections,
  modules,
  copy: siteOptions.siteCopyELearningCoursePage,
  user,
  userProgress
});

const mapDispatchToProps = dispatch => ({
  signedIn: () => dispatch(reducer.signedIn()),
  signIn: (username, password) => dispatch(reducer.signIn(username, password)),
  fetchUser: username => dispatch(fetchUser(username)),
  fetchSections: (options, locale) => dispatch(fetchSections(options, locale)),
  fetchModules: (options, locale) => dispatch(fetchModules(options, locale)),
  fetchUserProgress: username => dispatch(fetchUserProgress(username))
});

export default connect(mapStatetoProps, mapDispatchToProps)(ModulePage);
