import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  margin-top: 50px;
`;

export const TextInput = styled.input`
  width: 100%;
  border: 0;
  border-bottom: 1px solid #000;
  padding: 8px 0;
  font-size: 17px;

  ::placeholder {
    color: #cacaca;
  }

  :focus {
    outline: none;
  }

  :disabled {
    background-color: #eee;
    color: #999;
    padding: 8px;
  }
`;

export const Label = styled.p`
  text-transform: uppercase;
  font-size: 11px;
  margin-top: 7px;
  letter-spacing: 0.5px;
  white-space: nowrap;
`;

export const LabelError = styled.p`
  text-transform: uppercase;
  font-size: 11px;
  margin-top: 7px;
  letter-spacing: 0.5px;
  color: red;
`;

export const LabelWarning = styled.p`
  text-transform: uppercase;
  font-size: 11px;
  margin-top: 7px;
  letter-spacing: 0.5px;
  color: orange;
`;

export const Annotation = styled.span`
  font-size: 6px;
  margin-left: 12px;
  color: ${({ color }) => color};
`;
