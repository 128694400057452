import { getUA } from "react-device-detect";

export default {
    isApp: function () {
        return getUA.startsWith('ShiseidoSTEPS/');
    },
    isWeb: function () {
        return !getUA.startsWith('ShiseidoSTEPS/');
    },
    isAndroid: function () {
        if (!this.isApp()) { return false; }

        return getUA.split(' ').filter(x => x.toLowerCase().startsWith('android/')).length > 0;
    },
    isIos: function () {
        if (!this.isApp()) { return false; }

        return getUA.split(' ').filter(x => x.toLowerCase().startsWith('ios/')).length > 0;
    }
};
