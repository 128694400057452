import React from 'react';
import { ContainerBackground, Title, SignedUpButton, Text } from './SignUp.style';

const SignedUp = ({ copy, locale, snsAuthInitialize }) => {
  snsAuthInitialize();

  return (
    <ContainerBackground>
      <Title>{copy.sign_up_thank}</Title>
      <Text>{copy.check_email}</Text>

      <SignedUpButton href={`/${locale}/login`}>{copy.start_learning}</SignedUpButton>
    </ContainerBackground>
  );
};

SignedUp.defaultProps = {
  copy: {
    sign_up_thank: 'Thank you',
    check_email: `Thank you for setting up your account, we hope you will enjoy learning with us.`,
    start_learning: 'Start Learning',
  },
};

export default SignedUp;
