import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import {
  Title,
  Subtitle,
  Container,
  Button,
  ButtonsWrapper,
  LinkButton,
  RedLabel,
  TermsContainer,
} from './SignUp.style';
import { initialValidatorObject, updateValidatorObject } from '../../../utils';
import Placeholder from '../../Placeholder';

const required = copy => value => (value ? undefined : copy.required || 'Required');
const minlength6 = copy => value => (value.length >= 6 ? undefined : `${copy.minlength6 || 'Min Length 6'}`);
const v_translate = {
  required,
  minlength6,
};
class SignUpIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: '',
      isAgree1: false, //メールアドレス登録に同意チェック
      isAgree2: false, //SNS登録に同意チェック
      isAgree3: false, //apple 
      isShowError1: false, //
      isShowError2: false, //
      isShowError3: false, //apple
    };
    this.targetLink = null;

    const { locale, signUpLinks } = this.props;
    if (locale) {
      this.targetLink = !!(signUpLinks && _.get(signUpLinks[0], 'url'))
        ? signUpLinks[0].url
        : `https://www.shiseido-professional.com/${locale}/contact-us`;
    }
  }

  static defaultProps = {
    copy: {
      // submit: 'Submit',
      // already_a_member: 'I am already a member',
      // not_a_partner: 'Not a partner',
      // signup_terms: 'By clicking Submit, you agree to our',
      // signup_terms_link: 'Terms of Use',
      // signup_terms_url: 'http://www.pro.shiseido.com/terms/',
    },
    signUpLinks: null,
  };

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    signUpLinks: PropTypes.arrayOf(PropTypes.shape()),
  };

  componentDidMount() {
    const {
      snsAuth: { authState: snsAuthState },
    } = this.props;

    if (snsAuthState && snsAuthState !== 'SNS_AUTH_NA' && snsAuthState !== 'SNS_SIGN_UP_MASTER_STARTED') {
      this.props.snsAuthInitialize();
    }

    this.setState(initialValidatorObject(v_translate, this.props));
    this.checkSalonCode();
  }

  componentWillReceiveProps(props) {
    const update = updateValidatorObject(v_translate, this.props.copy, props.copy);
    if (update) {
      this.setState(update);
    }
  }

  checkSalonCode() {
    const salonCode = this.getParam('salonCode', window.location.href);

    if (salonCode) {
      this.setState({
        code: salonCode,
      });

      const { checkCode } = this.props;
      checkCode({ code: salonCode });
    }
  }

  getParam(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  onChangeAgree1(e) {
    this.setState({
      isAgree1: !this.state.isAgree1,
      isShowError1: false,
    });
  }

  onChangeAgree2(e) {
    this.setState({
      isAgree2: !this.state.isAgree2,
      isShowError2: false,
    });
  }
  //agree 3 is apple
  onChangeAgree3(e) {
    this.setState({
      isAgree3: !this.state.isAgree3,
      isShowError3: false,
    });
  }

  registEmail() {
    const { handleSubmit } = this.props;
    if (this.state.isAgree1) {
      handleSubmit();
    } else {
      this.setState({
        isShowError1: true,
      });
    }
  }

  registFacebook() {
    // const { checkCode, snsSignUpRequest } = this.props;
    const { checkCode } = this.props;
    if (this.state.isAgree2) {
      // snsSignUpRequest({ signUpCode: this.state.code, provider: 'Facebook' });
      checkCode({ code: this.state.code }, true, 'Facebook');
    } else {
      this.setState({
        isShowError2: true,
      });
    }
  }

  registApple() {
    // const { checkCode, snsSignUpRequest } = this.props;
    const { checkCode } = this.props;
    if (this.state.isAgree3) {
      // snsSignUpRequest({ signUpCode: this.state.code, provider: 'SignInWithApple' });
      checkCode({ code: this.state.code }, true, 'SignInWithApple');
    } else {
      this.setState({
        isShowError3: true,
      });
    }
  }

  nl2br(str) {
    if (!str) {
      return str;
    }
    var txt = String(str);
    txt = txt.replace(/\r\n/g, '<br />');
    txt = txt.replace(/(\n|\r)/g, '<br />');
    txt = txt.replace(/\\n/g, '<br />');
    return txt;
  }

  render() {
    const { locale, copy, isCodeValid } = this.props;
    const {
      // salon_code_label,
      // codeisinvalid,
      sign_up,
      signup_terms,
      signup_terms_link,
      submit,
      already_a_member,
      not_a_partner,
      register_email,
      register_sns_id,
      url_has_expired,
      no_salon_code,
      privacy_consent,
      about_this_service,
      contact_us,
    } = copy;
    // const { required, minlength6 } = this.state;

    let targetLocale = locale;
    switch (locale) {
      case 'zh_sg':
        targetLocale = 'en_sg';
        break;
      case 'zh_my':
        targetLocale = 'en_my';
        break;
      default:
        break;
    }

    return (
      <Container>
        {/* --------------------------------------------------
        /[locale]/signup
      -------------------------------------------------- */}
        {!this.state.code &&
          isCodeValid == null && (
            <Fragment>
              <Title>{about_this_service ? about_this_service : <Placeholder w="300px" h="40px" />}</Title>
              <RedLabel
                dangerouslySetInnerHTML={{
                  __html: this.nl2br(no_salon_code),
                }}
                style={{ lineHeight: '1.5', marginBottom: '40px' }}
              />
              <LinkButton style={{ textAlign: 'left', fontSize: '14px' }}>
                <a
                  href={`https://www.shiseido-professional.com/${targetLocale}/contact-us`}
                  style={{ textDecoration: 'underline' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {contact_us ? contact_us : <Placeholder w="300px" h="20px" />}
                </a>
              </LinkButton>
            </Fragment>
          )}
        {/* --------------------------------------------------
        /[locale]/signup?code=XXXXXXXXXX (code is valid)
        -------------------------------------------------- */}
        {this.state.code &&
          isCodeValid && (
            <Fragment>
              <Title>{sign_up ? sign_up : <Placeholder w="300px" h="40px" />}</Title>
              {/* --------------------------------------------------
                  メールアドレスで登録
                  -------------------------------------------------- */}
              <Subtitle style={{ fontSize: '26px', marginBottom: '10px' }}>
                {register_email ? register_email : <Placeholder w="300px" h="30px" />}
              </Subtitle>
              <ButtonsWrapper style={{ marginTop: '0', marginBottom: '60px' }}>
                {signup_terms &&
                  signup_terms_link && (
                    <TermsContainer style={{ fontSize: '12px' }}>
                      <label>
                        <input
                          type="checkbox"
                          name="agree1"
                          checked={this.state.isAgree1}
                          onChange={event => this.onChangeAgree1(event)}
                        />
                        {` ${signup_terms} `}
                      </label>
                      <a
                        href={`/${locale}/terms-of-use`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'underline', fontWeight: 'normal' }}
                      >
                        {signup_terms_link}
                      </a>
                    </TermsContainer>
                  )}
                {this.state.isShowError1 && (
                  <RedLabel
                    dangerouslySetInnerHTML={{
                      __html: this.nl2br(privacy_consent),
                    }}
                    style={{
                      marginTop: '0',
                      marginBottom: '10px',
                      fontSize: '12px',
                      textAlign: 'left',
                      width: '100%',
                      lineHeight: '1.5',
                    }}
                  />
                )}
                {submit ? (
                  <Button
                    onClick={event => this.registEmail(event)}
                    className={!this.state.isAgree1 ? 'disabled' : null}
                  >
                    {submit}
                  </Button>
                ) : (
                  <Placeholder w="100%" h="60px" />
                )}
              </ButtonsWrapper>
              {/* --------------------------------------------------
                他のサービスIDで会員登録
                -------------------------------------------------- */}
              <Subtitle style={{ fontSize: '26px', marginBottom: '10px' }}>
                {register_sns_id ? register_sns_id : <Placeholder w="300px" h="30px" />}
              </Subtitle>
              <ButtonsWrapper style={{ marginTop: '0', marginBottom: '60px' }}>
                {signup_terms &&
                  signup_terms_link && (
                    <TermsContainer style={{ fontSize: '12px' }}>
                      <label>
                        <input
                          type="checkbox"
                          name="agree2"
                          checked={this.state.isAgree2}
                          onChange={event => this.onChangeAgree2(event)}
                        />
                        {` ${signup_terms} `}
                      </label>
                      <a
                        href={`/${locale}/terms-of-use`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'underline', fontWeight: 'normal' }}
                      >
                        {signup_terms_link}
                      </a>
                    </TermsContainer>
                  )}
                {this.state.isShowError2 && (
                  <RedLabel
                    dangerouslySetInnerHTML={{
                      __html: this.nl2br(privacy_consent),
                    }}
                    style={{
                      marginTop: '0',
                      marginBottom: '10px',
                      fontSize: '12px',
                      textAlign: 'left',
                      width: '100%',
                      lineHeight: '1.5',
                    }}
                  />
                )}
                {submit ? (
                  <Button
                    onClick={event => this.registFacebook(event)}
                    className={!this.state.isAgree2 ? 'disabled' : null}
                    type="Facebook"
                  >
                    {copy.facebook}
                  </Button>
                ) : (
                  <Placeholder w="100%" h="60px" />
                )}
              </ButtonsWrapper>
              {/* --------------------------------------------------
                Apple signin
                -------------------------------------------------- */}

              <ButtonsWrapper style={{ marginTop: '0', marginBottom: '60px' }}>
                {signup_terms &&
                  signup_terms_link && (
                    <TermsContainer style={{ fontSize: '12px' }}>
                      <label>
                        <input
                          type="checkbox"
                          name="agree3"
                          checked={this.state.isAgree3}
                          onChange={event => this.onChangeAgree3(event)}
                        />
                        {` ${signup_terms} `}
                      </label>
                      <a
                        href={`/${locale}/terms-of-use`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'underline', fontWeight: 'normal' }}
                      >
                        {signup_terms_link}
                      </a>
                    </TermsContainer>
                  )}
                {this.state.isShowError3 && (
                  <RedLabel
                    dangerouslySetInnerHTML={{
                      __html: this.nl2br(privacy_consent),
                    }}
                    style={{
                      marginTop: '0',
                      marginBottom: '10px',
                      fontSize: '12px',
                      textAlign: 'left',
                      width: '100%',
                      lineHeight: '1.5',
                    }}
                  />
                )}
                {submit ? (
                  <Button
                    onClick={event => this.registApple(event)}
                    className={!this.state.isAgree3 ? 'disabled' : null}
                    type="SignInWithApple"
                  >
                    {copy.apple}
                  </Button>
                ) : (
                  <Placeholder w="100%" h="60px" />
                )}
              </ButtonsWrapper>
              {/* --------------------------------------------------
                  その他リンク
                -------------------------------------------------- */}
              <LinkButton style={{ textAlign: 'left', fontSize: '14px' }}>
                <Link
                  to={`/${locale}/login`}
                  href={`/${locale}/login`}
                  style={{ fontWeight: 'normal', textDecoration: 'underline' }}
                >
                  {already_a_member ? already_a_member : <Placeholder w="300px" h="20px" />}
                </Link>
              </LinkButton>
              <LinkButton style={{ textAlign: 'left', fontSize: '14px' }}>
                <a
                  href={this.targetLink}
                  target={'_blank'}
                  rel="noopener noreferrer"
                  style={{ fontWeight: 'normal', textDecoration: 'underline' }}
                >
                  {not_a_partner ? not_a_partner : <Placeholder w="300px" h="20px" />}
                </a>
              </LinkButton>
            </Fragment>
          )}
        {/* --------------------------------------------------
        /[locale]/signup?code=XXXXXXXXXX (code is invalid)
        -------------------------------------------------- */}
        {this.state.code &&
          !isCodeValid && (
            <Fragment>
              <Title>{sign_up ? sign_up : <Placeholder w="300px" h="40px" />}</Title>
              <RedLabel>{url_has_expired ? url_has_expired : <Placeholder w="300px" h="20px" />}</RedLabel>
            </Fragment>
          )}
      </Container>
    );
  }
}

export default reduxForm({
  form: 'codeForm',
})(SignUpIndex);
