import AWS from 'aws-sdk/global';
import { get, getUser } from './config';

const getAWSConfig = () => {
  return AWS.config;
}
export default function() {
  const cognitoUser = getUser();

  if (cognitoUser) {
    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          const params = {};
          const endpoint = `cognito-idp.${get().region}.amazonaws.com/${get().UserPoolId}`;
          params.IdentityPoolId = get().IdentityPoolId;
          params.Logins = {};
          params.Logins[endpoint] = session.getIdToken().getJwtToken();
          getAWSConfig().credentials = new AWS.CognitoIdentityCredentials(params);
          getAWSConfig().credentials.refresh(err => {
            if (err) {
              reject(err);
            } else {
              resolve(getAWSConfig().credentials);
            }
          });
        }
      });
    });
  }
  throw new Error('no cognitiveUser value');
}
