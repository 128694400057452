/* eslint-disable */

import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import media from '../../styles/media.style';
import { BodyNoScroll } from '../../styles/mixins.style';

const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  padding: 3em 36px;
  overflow: auto;

  ${media.tabletL`
    position: relative;
    padding: 6em 120px;
  `};
`;

const CloseBtn = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 34px;

  a {
    text-decoration: none;
    color: #000;
  }
`;

const RegistrationModal = ({ match, children }) => (
  <Fragment>
    <Helmet>
      <body style={BodyNoScroll} />{/* eslint-disable-line */}
    </Helmet>
    <Wrapper>
      <Content>
        <CloseBtn>
          <Link to={`/${match.params.lang}`} href={`/${match.params.lang}`}>
            &times;
          </Link>
        </CloseBtn>
        {children}
      </Content>
    </Wrapper>
  </Fragment>
);

RegistrationModal.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      lang: PropTypes.string,
    }),
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default RegistrationModal;
