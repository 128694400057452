import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Wrapper,
  Title,
  Button,
  List,
  ListItem,
  Checkmark,
  SelectedOptionsList,
  SelectedOption,
} from './Dropdown.style';
import CloseIcon from '../CloseIcon/CloseIcon';

class Dropdown extends Component {
  state = {
    open: false,
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  button = React.createRef();

  handleClickOutside = e => {
    if (this.button && this.button.current && !this.button.current.contains(e.target)) {
      this.setState({
        open: false,
      });
    }
  };

  handleOpen = () =>
    this.setState(({ open }) => ({
      open: !open,
    }));

  render() {
    const { title, name, options, activeOptions, onSelect, label, onRemoveFilter, copy } = this.props;
    const { open } = this.state;
    return (
      <Wrapper open={open}>
        {title && <Title>{title}</Title>}
        <Button onClick={this.handleOpen} innerRef={this.button} open={open}>
          {label}
        </Button>
        <SelectedOptionsList>
          {activeOptions[0] !== 'All' &&
            activeOptions.map(option => (
              <li key={option}>
                <SelectedOption onClick={onRemoveFilter(name, option)}>
                  <CloseIcon size="10px" /> <span>{option}</span>
                </SelectedOption>
              </li>
            ))}
        </SelectedOptionsList>
        <List open={open}>
          <li>
            <ListItem onClick={onSelect(name, 'All')} selected={!!activeOptions.find(active => active === 'All')}>
              <Checkmark />
              <span>{copy.all}</span>
            </ListItem>
          </li>
          {options.map(option => (
            <li key={option}>
              <ListItem onClick={onSelect(name, option)} selected={!!activeOptions.find(active => active === option)}>
                <Checkmark />
                <span>{option}</span>
              </ListItem>
            </li>
          ))}
        </List>
      </Wrapper>
    );
  }
}

Dropdown.defaultProps = {
  title: '',
  copy: {
    all: 'All',
  },
};

Dropdown.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  activeOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

export default connect(({ siteOptions }) => ({ copy: siteOptions.siteCopyELearningGlobal }))(Dropdown);
