import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Button, ChildrenWrapper } from './Accordion.style';

class Accordion extends Component {
  state = {
    open: false,
  };

  handleToggle = () => this.setState(({ open }) => ({ open: !open }));

  render() {
    const { title, children, contentHeight } = this.props;
    const { open } = this.state;
    return (
      <Wrapper>
        <Button onClick={this.handleToggle} open={open}>
          {title}
        </Button>
        <ChildrenWrapper open={open} height={contentHeight}>
          {children}
        </ChildrenWrapper>
      </Wrapper>
    );
  }
}

Accordion.defaultProps = {
  title: '',
  contentHeight: 1000,
};

Accordion.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  contentHeight: PropTypes.number,
};

export default Accordion;
