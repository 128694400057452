import AWS from 'aws-sdk/global';

import { CognitoUserPool } from 'amazon-cognito-identity-js';

let appConfig;
let userPool;
let currentUser;

export const set = config => {
  appConfig = {
    region: process.env.REACT_APP_BUCKET_REGION,
    IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    UserPoolId: process.env.REACT_APP_COGNITO_USERPOOL,
    ClientId: process.env.REACT_APP_COGNITO_CLIENTID,
  }

  AWS.config.region = appConfig.region;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: appConfig.IdentityPoolId,
  });

  userPool = new CognitoUserPool({
    UserPoolId: appConfig.UserPoolId,
    ClientId: appConfig.ClientId,
  });
};

export const get = () => {
  return appConfig;
};

export const getUserPool = () => {
  return userPool;
};

export const getUser = () => {
  return userPool.getCurrentUser();
};

export const setCurrentUserSession = user => {
  currentUser = user;
};

export const getCurrentUserSession = () => {
  return currentUser;
};
