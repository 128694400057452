import { connect } from 'react-redux';

import { reducer } from '../../../redux/sagas/aws-cognito-redux-saga';
import { snsAuthAction } from '../../../redux/sagas/sns-auth-redux-saga';

import loadFormDefaults from '../../../redux/actions/signUpForm';

import SignUpComponent from './SignUp';

const mapStatetoProps = state => ({
  auth: state.auth,
  copy: state.siteOptions,
  initialValues: state.signUpForm.data,
  snsAuth: state.snsAuth,
});

const mapDispatchToProps = dispatch => ({
  init: () => {
    dispatch(reducer.init());
  },
  signUp: (username, password, attributes) => {
    dispatch(reducer.signUp(username, password, attributes));
  },
  loadFormDefaults: data => dispatch(loadFormDefaults(data)),
  snsAuthInitialize: () => dispatch(snsAuthAction.snsAuthInitialize()),
  snsSignUpPrepare: () => dispatch(snsAuthAction.snsSignUpPrepare()),
  snsSignUpRequest: signUpCode => dispatch(snsAuthAction.snsSignUpRequest(signUpCode)),
  snsSignUpMasterRequest: data => dispatch(snsAuthAction.snsSignUpMasterRequest(data)),
});

export default connect(mapStatetoProps, mapDispatchToProps)(SignUpComponent);
