import PropTypes from 'prop-types';
import React from 'react';
import showdown from 'showdown';

const converter = new showdown.Converter({
  noHeaderId: true,
});

const Markdown = ({ Component, src }) => <Component dangerouslySetInnerHTML={{ __html: converter.makeHtml(src) }} />;

Markdown.propTypes = {
  Component: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,
};

export default Markdown;
