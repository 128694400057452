import { createSelector } from 'reselect';

const getModules = state => state.modules;
const getSlugFromQuiz = (state, quiz) => quiz.relatedModule;
const getName = (state, props) => props.name;

export const getModule = createSelector([getModules, getName], (modules, name) => modules.name === name);

export const getQuizRelatedModule = createSelector([getModules, getSlugFromQuiz], (modules, slug) =>
  modules.find(module => module.moduleSlug === slug)
);
