import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import LocaleSelector from '../LocaleSelector/LocaleSelector';
import logo from '../../assets/images/sp-logo.svg';
import Placeholder from '../Placeholder';
import SocialIcon from './SocialIcon';

import {
  Container,
  Wrapper,
  Inner,
  Logo,
  PrimaryLinks,
  PrimaryLink,
  Extras,
  IconLinks,
  SocialIcons,
  Misc,
  SecondaryLinks,
  SecondaryLink,
  Copyright,
} from './Footer.style';

const Footer = ({ footerCopyright, locale, primaryLinks, screenSize, secondaryLinks, footerSocialLinks }) => (
  <Container>
    <Wrapper>
      <Inner>
        <Link to={`/${locale}`} href={`/${locale}`}>
          <Logo src={logo} alt="logo" />
        </Link>

        <PrimaryLinks>
          {primaryLinks
            ? primaryLinks.map(link => (
                <PrimaryLink key={link.label}>
                  {link.external ? (
                    <a href={link.url} target="_blank" rel="noopener noreferrer">
                      {link.label}
                    </a>
                  ) : (
                    <Link to={`/${locale}${link.url}`} href={`/${locale}${link.url}`}>
                      {link.label}
                    </Link>
                  )}
                </PrimaryLink>
              ))
            : [0, 1, 2, 3, 4].map(key => (
                <PrimaryLink key={key}>
                  <Placeholder h="10px" w="100px" />
                </PrimaryLink>
              ))}
        </PrimaryLinks>

        <Extras>
          <IconLinks>
            <LocaleSelector locale={locale} position={screenSize > 1 ? 'top' : 'top-right'} full />

            <SocialIcons>
              {footerSocialLinks
                ? footerSocialLinks.map(link => <SocialIcon key={link.url} type={link.type} url={link.url} />)
                : [1, 2, 3].map(key => <Placeholder key={key} h="30px" w="30px" />)}
            </SocialIcons>
          </IconLinks>

          <Misc>
            <SecondaryLinks>
              {secondaryLinks
                ? secondaryLinks.map(link => (
                    <SecondaryLink key={link.label}>
                      {link.external ? (
                        <a href={link.url} target="_blank" rel="noopener noreferrer">
                          {link.label}
                        </a>
                      ) : (
                        <Link to={`/${locale}${link.url}`} href={`/${locale}${link.url}`}>
                          {link.label}
                        </Link>
                      )}
                    </SecondaryLink>
                  ))
                : [1, 2].map(key => (
                    <SecondaryLink key={key}>
                      <Placeholder h="10px" w="75px" />
                    </SecondaryLink>
                  ))}
            </SecondaryLinks>

            <Copyright>{footerCopyright || <Placeholder h="20px" w="250px" />}</Copyright>
          </Misc>
        </Extras>
      </Inner>
    </Wrapper>
  </Container>
);

Footer.defaultProps = {
  footerCopyright: undefined,
  primaryLinks: undefined,
  secondaryLinks: undefined,
  footerSocialLinks: undefined,
};

Footer.propTypes = {
  footerCopyright: PropTypes.string,
  footerSocialLinks: PropTypes.arrayOf(PropTypes.shape()),
  locale: PropTypes.string.isRequired,
  primaryLinks: PropTypes.arrayOf(PropTypes.shape()),
  screenSize: PropTypes.number.isRequired,
  secondaryLinks: PropTypes.arrayOf(PropTypes.shape()),
};

const mapState = state => ({
  footerCopyright: state.siteOptions.footerCopyright,
  footerSocialLinks: state.siteOptions.footerSocialLinks,
  primaryLinks: state.siteOptions.footerPrimaryLinks,
  screenSize: state.screenSize,
  secondaryLinks: state.siteOptions.footerSecondaryLinks,
});

export default connect(mapState)(Footer);
