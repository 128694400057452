import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { getCurrentUser } from '../../redux/sagas/sns-auth-redux-saga/amplify.bridge';

import { connect } from 'react-redux';
import UserActionTypes from '../../redux/sagas/sns-auth-redux-saga/types';
import {
  snsSignUpTempSignInFail,
  snsSignUpTempSignedIn,
  snsSignUpTempSignOutRequest,
  snsSignUpTempSignedOut,
  snsLinkTempSignedIn,
  snsLinkTempSignInFail,
  snsLinkTempSignOutRequest,
  snsLinkTempSignedOut,
  snsLinkCreateRequest,
  snsLoginSuccessReload,
  snsLoginFail,
  snsLoginUnlinkLogoutRequest,
  snsLogoutSuccess,
  snsLogoutFail,
} from '../../redux/sagas/sns-auth-redux-saga/actions';

import * as authActions from '../../redux/sagas/aws-cognito-redux-saga/actions';
import * as authStates from '../../redux/sagas/aws-cognito-redux-saga/states';
import { clearCurrentUser } from '../../redux/actions/user';

import { SpinnerOverlay, SpinnerContainer } from '../../components/with-spinner/with-spinner.styles'; // eslint-disable-line
import { setTimeout } from 'core-js';

class IdpRedirectPage extends React.Component {
  state = {
    loading: true,
  };
  getError = () => {
    const params = queryString.parse(this.props.location.search);
    const { error, error_description } = params;
    return error ? { error, error_description } : null;
  };

  afterSignInRedirect = userData => {
    this.props.snsLoginSuccessReload();
    this.props.loginSuccessAuthUpate(userData.username, userData.signInUserSession);
    this.setState({ loading: false });
    this.props.history.push(`/${this.props.locale}/`);
  };

  componentDidMount() {
    switch (this.props.authState) {
      case UserActionTypes.SNS_SIGN_UP_STARTED:
        {
          const error = this.getError();

          if (error) {
            this.props.snsSignUpTempSignInFail(error);
            setTimeout(() => {
              this.props.history.push(`/${this.props.locale}/signup?code=${this.props.signUpCode}`);
            }, 1000);
            break;
          }
          getCurrentUser().then(userData => {
            if (userData && userData.username && (userData.username.startsWith('Facebook_') || userData.username.startsWith('SignInWithApple_'))) {
              if (userData.attributes) {
                this.props.snsSignUpTempSignedIn({
                  id: userData.username.split('_')[1],
                  given_name: userData.attributes.given_name,
                  family_name: userData.attributes.family_name,
                  name: userData.attributes.name,
                  email: userData.attributes.email,
                });
                this.props.snsSignUpTempSignOutRequest();
              } else if (
                userData.signInUserSession &&
                userData.signInUserSession.idToken &&
                userData.signInUserSession.idToken.payload
              ) {
                this.props.snsSignUpTempSignedIn({
                  id: userData.username.split('_')[1],
                  name: userData.signInUserSession.idToken.payload.name,
                  email: userData.signInUserSession.idToken.payload.email,
                  given_name: userData.signInUserSession.idToken.payload.given_name,
                  family_name: userData.signInUserSession.idToken.payload.family_name,
                });
                this.props.snsSignUpTempSignOutRequest();
              } else {
                this.props.snsSignUpTempSignInFail(new error('no user data'));
                return;
              }
            } else {
              this.afterSignInRedirect(userData);
            }
          });
        }
        break;
      case UserActionTypes.SNS_SIGN_UP_TEMP_SIGN_OUT_STARTED: {
        this.props.snsSignUpTempSignedOut();
        this.props.history.push(`/${this.props.locale}/signup`);
        break;
      }

      case UserActionTypes.SNS_LINK_TEMP_SIGN_IN_STARTED: {
        const error = this.getError();
        if (error) {
          this.props.snsLinkTempSignInFail(error);
          break;
        }

        getCurrentUser().then(userData => {
          if (userData && userData.username && (userData.username.startsWith('Facebook_') || userData.username.startsWith('SignInWithApple_'))) {
            this.props.snsLinkTempSignedIn({
              id: userData.username.split('_')[1],
            });

            this.props.snsLinkTempSignOutRequest();
          } else {
            this.afterSignInRedirect(userData);
          }
        });
        break;
      }

      case UserActionTypes.SNS_LINK_TEMP_SIGN_OUT_STARTED:
        this.props.snsLinkTempSignedOut();

        const { masterUser, snsUser } = this.props;
        this.props.snsLinkCreateRequest({ masterUser, snsUser });
        break;

      case UserActionTypes.SNS_LOGIN_STARTED:
        {
          const error = this.getError();

          if (error) {
            this.props.snsLoginFail(error);
            setTimeout(() => {
              this.props.history.push(`/${this.props.locale}/login`);
            }, 2000);
            break;
          }

          getCurrentUser().then(userData => {
            if (userData && userData.username && (userData.username.startsWith('Facebook_') || userData.username.startsWith('SignInWithApple_'))) {
              this.props.snsLoginUnlinkLogoutRequest();
            } else {
              if (
                userData.signInUserSession &&
                userData.signInUserSession.idToken &&
                userData.signInUserSession.idToken.payload
              ) {
                const { payload } = userData.signInUserSession.idToken;
                if (
                  !payload.identities ||
                  payload.identities.filter(i => i.providerName === this.props.provider).length === 0
                ) {
                  // Amplify glitch workaround: Master User without SNS linked but still can be logged in with federatedSignIn()
                  this.props.snsLoginUnlinkLogoutRequest();
                } else {
                  this.afterSignInRedirect(userData);
                }
              }
            }
          });
        }
        break;

      case UserActionTypes.SNS_LOGIN_UNLINK_LOGOUT_STARTED: {
        this.props.snsLoginFail({ code: 'UnlinkedSnsUser', message: 'No master id links to your SNS account' });

        setTimeout(() => {
          this.props.history.push(`/${this.props.locale}/login`);
        }, 1000);

        break;
      }

      case UserActionTypes.SNS_LOGOUT_STARTED:
        {
          const error = this.getError();

          if (error) {
            this.props.snsLoginFail(error);
            setTimeout(() => {
              this.props.history.push(`/${this.props.locale}/dashboard`);
            }, 2000);
            break;
          }

          this.props.snsLogoutSuccess();
          this.props.clearCurrentUser();
          this.props.history.push(`/${this.props.locale}/`);
        }
        break;

      default:
        this.props.history.push(`/${this.props.locale}/`);
        break;
    }
  }

  render() {
    const { authState } = this.props;

    return (
      <React.Fragment>
        {authState === 'SNS_LINK_SUCCESS' ||
        authState === 'SNS_LINK_TEMP_SIGN_IN_FAIL' ||
        authState === 'SNS_LINK_TEMP_SIGN_OUT_FAIL' ||
        authState === 'SNS_LINK_MASTER_SIGN_IN_FAIL' ? (
          <Redirect to={`/${this.props.locale}/dashboard/settings`} />
        ) : (
          <SpinnerOverlay>
            <SpinnerContainer />
          </SpinnerOverlay>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ snsAuth }) => ({
  authState: snsAuth.authState,
  masterUser: snsAuth.masterUser,
  snsUser: snsAuth.snsUser,
  locale: snsAuth.locale,
  signUpCode: snsAuth.signUpCode,
  provider: snsAuth.provider,
});

const mapDispatchToProps = dispatch => ({
  // SNS Sign Up
  snsSignUpTempSignInFail: error => dispatch(snsSignUpTempSignInFail(error)),
  snsSignUpTempSignedIn: tempFacebookUser => dispatch(snsSignUpTempSignedIn(tempFacebookUser)),
  snsSignUpTempSignOutRequest: () => dispatch(snsSignUpTempSignOutRequest()),
  snsSignUpTempSignedOut: () => dispatch(snsSignUpTempSignedOut()),

  // SNS Link
  snsLinkTempSignedIn: tempSnsUser => dispatch(snsLinkTempSignedIn(tempSnsUser)),
  snsLinkTempSignInFail: error => dispatch(snsLinkTempSignInFail(error)),
  snsLinkTempSignOutRequest: () => dispatch(snsLinkTempSignOutRequest()),
  snsLinkTempSignedOut: () => dispatch(snsLinkTempSignedOut()),
  snsLinkCreateRequest: masterUser => dispatch(snsLinkCreateRequest(masterUser)),

  // SNS Login
  snsLoginSuccessReload: () => dispatch(snsLoginSuccessReload()),
  snsLoginFail: error => dispatch(snsLoginFail(error)),
  loginSuccessAuthUpate: (username, session) =>
    dispatch({
      type: authActions.AUTH_SET_STATE,
      payload: {
        isSignedIn: authStates.AUTH_SUCCESS,
        isConfirmed: authStates.AUTH_SUCCESS,
        info: { username, ...session },
      },
    }),
  snsLoginUnlinkLogoutRequest: () => dispatch(snsLoginUnlinkLogoutRequest()),

  // SNS Logout
  snsLogoutSuccess: () => dispatch(snsLogoutSuccess()),
  snsLogoutFail: error => dispatch(snsLogoutFail(error)),
  clearCurrentUser: () => dispatch(clearCurrentUser()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IdpRedirectPage));
