import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import TagManager from 'react-gtm-module';

import leftArrow from '../../assets/images/left-arrow.svg';
import rightArrow from '../../assets/images/right-arrow.svg';

import Placeholder from '../../components/Placeholder';

import {
  ContentWrapper,
  ContentHeader,
  PreviousBtn,
  NextBtn,
  ContentTitle,
  QuestionNumber,
  ProgressWrapper,
  Progress,
  Question,
  Answer,
  Checkmark,
  ProgressOverflow,
  PlaceholderWrapper,
  ContentInnerWrapper,
  ButtonText,
  BodyWrapper,
  QuestionWrapper,
  AnswersWrapper,
} from './QuizPage.style';

class QuizContent extends Component {
  state = {
    currentQuestion: 0,
  };

  static defaultProps = {
    quiz: {
      questions: [],
    },
  };

  static propTypes = {
    quiz: PropTypes.shape({
      questions: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    answers: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.bool)).isRequired,
    onChooseAnswer: PropTypes.func.isRequired,
    onQuizFinished: PropTypes.func.isRequired,
  };

  handlePrev = () => {
    if (this.state.currentQuestion === 0) {
      const {
        history: {
          push,
          location: { pathname },
        },
      } = this.props;
      push(pathname.substr(0, pathname.lastIndexOf('/quiz')));
    } else {
      this.setState(({ currentQuestion }) => ({
        currentQuestion: currentQuestion - 1,
      }));
    }
  };

  onQuizFinished = (...args) => {
    this.props.onQuizFinished(...args);
  };

  handleNext = () => {
    this.setState(({ currentQuestion }) => ({
      currentQuestion: currentQuestion + 1,
    }));

    TagManager.dataLayer({
      dataLayer: {
        event: 'el_quiz',
        el_display: this.state.currentQuestion + 1,
      },
    });
  };

  render() {
    const { quiz, answers, onChooseAnswer, copy } = this.props;
    const { currentQuestion } = this.state;

    const nextDisabled = quiz.questions ? !answers[currentQuestion].find(a => a) : true;

    const chapterProgress = quiz.questions ? Math.round((currentQuestion + 1) * 100 / quiz.questions.length) : 0;
    const isLastQuestion = quiz.questions ? currentQuestion === quiz.questions.length - 1 : false;

    return (
      <ContentWrapper>
        <ContentInnerWrapper>
          <ContentHeader>
            <PreviousBtn url={leftArrow} onClick={this.handlePrev}>
              <ButtonText>
                <span>{copy.previous}</span>
              </ButtonText>
            </PreviousBtn>
            <NextBtn
              url={rightArrow}
              onClick={isLastQuestion ? this.onQuizFinished : this.handleNext}
              disabled={nextDisabled}
            >
              <ButtonText>
                <span>{isLastQuestion ? copy.get_results : copy.next}</span>
              </ButtonText>
            </NextBtn>
          </ContentHeader>
          <ContentTitle>
            {copy.quiz}{' '}
            {quiz.questions && (
              <QuestionNumber>
                {currentQuestion + 1}/{quiz.questions ? quiz.questions.length : 10}
              </QuestionNumber>
            )}
          </ContentTitle>
          <ProgressWrapper>
            <ProgressOverflow>
              <Progress style={{ transform: `translateX(${-100 + chapterProgress}%)` }}>
                <span>{`${chapterProgress}%`}</span>
              </Progress>
            </ProgressOverflow>
          </ProgressWrapper>
          <BodyWrapper>
            <QuestionWrapper>
              {quiz.questions ? (
                <Question>{quiz.questions[currentQuestion].question}</Question>
              ) : (
                <PlaceholderWrapper>
                  <Placeholder h="40px" w="100%" />
                </PlaceholderWrapper>
              )}
            </QuestionWrapper>
            <AnswersWrapper>
              {quiz.questions
                ? quiz.questions[currentQuestion].answers.map((answer, i) => (
                    <Answer key={answer} onClick={onChooseAnswer(currentQuestion, i)}>
                      <div>
                        <Checkmark selected={answers[currentQuestion][i]} />
                      </div>
                      <div>
                        <p>{answer}</p>
                      </div>
                    </Answer>
                  ))
                : [...new Array(4).keys()].map(i => (
                    <PlaceholderWrapper key={i}>
                      <Placeholder h="29px" w="100%" />
                    </PlaceholderWrapper>
                  ))}
            </AnswersWrapper>
          </BodyWrapper>
        </ContentInnerWrapper>
      </ContentWrapper>
    );
  }
}

export default withRouter(QuizContent);
