import React, { Component } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { Field } from 'redux-form';
import { Link } from 'react-router-dom';

import TextField from '../../TextField/TextField';
import Loading from '../../Loading';
import { initialValidatorObject, updateValidatorObject } from '../../../utils';

import {
  DoubleContainer,
  Title,
  Form,
  Button,
  LinkButton,
  LabelError,
  ButtonsWrapper,
  SpinnerButton,
  Subtitle,
  JobTitleWrapper,
  FieldsLeft,
  FieldsRight,
  DropdownsWrapper,
  DropdownWrapper,
  RedLabel,
} from './SignUp.style';

const _minLength = 6;
const required = copy => value => (value ? undefined : copy.required || 'Required');
const email = copy => value =>
  validator.isEmail(value) ? undefined : copy.enter_a_vaild_email_address || 'Not Valid Email';
const passwordMatch = copy => (value, values) =>
  values.password !== values.passwordMatch && (copy.passwords_must_match || 'Passwords must match');
const minLength = copy => min => value =>
  value && value.length >= min ? undefined : `${copy.minlength6 || `Min Length ${min}`}`;
const containsSmallCase = copy => value =>
  value.match(/[a-z]+/) ? undefined : copy.must_contain_at_least_small || 'Must contain at least one small case letter';
const containsUpperCase = copy => value =>
  value.match(/[A-Z]+/) ? undefined : copy.must_contain_at_least_upper || 'Must contain at least one upper case letter';

const v_translate = {
  required: required,
  enter_a_vaild_email_address: email,
  passwords_must_match: passwordMatch,
  minlength6: minLength,
  must_contain_at_least_small: containsSmallCase,
  must_contain_at_least_upper: containsUpperCase,
};

class SignUpFormEmail extends Component {
  static defaultProps = {
    copy: {
      sign_up_2: 'Sign up 2/2',
      sign_up: 'Sign up',
      already_a_member: 'I am already a member',
    },
  };
  static propTypes = {
    // onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func, // eslint-disable-line
    locale: PropTypes.string.isRequired,
  };

  state = {};

  componentDidMount() {
    this.setState(initialValidatorObject(v_translate, this.props), this.updatePasswordValidators);
  }

  componentWillReceiveProps(props) {
    const update = updateValidatorObject(v_translate, this.props.copy, props.copy);
    if (update) {
      this.setState(update, this.updatePasswordValidators);
    }
  }

  updatePasswordValidators = () => {
    this.setState({
      passwordValidators: [
        this.state.minlength6(_minLength),
        this.state.must_contain_at_least_upper,
        this.state.must_contain_at_least_small,
      ],
    });
  };

  handleSubmit = (e, ...args) => {
    e.preventDefault();
    const { handleSubmit, signUp } = this.props;
    this.setState(
      {
        requestSent: true,
      },
      () => handleSubmit(signUp)(e, ...args)
    );
  };

  isSnsSignUp = () => {
    return this.props.snsAuth.authState === 'SNS_SIGN_UP_TEMP_SIGNED_OUT';
  };

  isFacebookSignUp = () => {
    return this.props.snsAuth.provider === 'Facebook';
  };

  isAppleSignUp = () => {
    return this.props.snsAuth.provider === 'SignInWithApple';
  };

  render() {
    const { error, locale, userData, copy } = this.props;
    if (!userData) return null;
    const {
      // sign_up_2,
      given_name,
      family_name,
      country,
      city,
      job_title,
      experience,
      interest,
      email,
      password,
      password_rule,
      confirm_password,
      sign_up,
      already_a_member,
      given_name_placeholder,
      family_name_placeholder,
      country_placeholder,
      city_placeholder,
      job_title_placeholder,
      experience_placeholder,
      interest_placeholder,
      requiredconditions,
      optional,
    } = copy;
    const { required, enter_a_vaild_email_address, passwords_must_match, passwordValidators, requestSent } = this.state;
    return (
      <DoubleContainer>
        <Title>{sign_up}</Title>
        <Subtitle>
          <span>{userData.userType === 'owner' ? 'Master' : 'User'}</span>, {userData.salon}, {userData.city}
        </Subtitle>
        <Subtitle>{password_rule}</Subtitle>
        <RedLabel>{requiredconditions}</RedLabel>
        <Form>
          <FieldsLeft>
            <Field
              component={props => <TextField required={true} {...props} />}
              name="custom:given_name"
              validate={[required].filter(it => it)}
              type="text"
              placeholder={given_name_placeholder || 'Your given name'}
              label={given_name || 'given name'}
            />
            <Field
              name="custom:family_name"
              validate={[required].filter(it => it)}
              component={props => <TextField required={true} {...props} />}
              type="text"
              placeholder={family_name_placeholder || 'Your family name'}
              label={family_name || 'family name'}
            />

            <DropdownsWrapper>
              <DropdownWrapper>
                <Field
                  name="custom:country"
                  validate={[required].filter(it => it)}
                  component={props => <TextField required={true} {...props} />}
                  type="text"
                  placeholder={country_placeholder || 'Country'}
                  label={country || 'your country'}
                />
              </DropdownWrapper>
              <DropdownWrapper>
                <Field
                  name="custom:city"
                  validate={[required].filter(it => it)}
                  component={props => <TextField required={true} {...props} />}
                  type="text"
                  placeholder={city_placeholder || 'City'}
                  label={city || 'City'}
                />
              </DropdownWrapper>
            </DropdownsWrapper>
            <JobTitleWrapper>
              <Field
                name="custom:job_title"
                validate={[required].filter(it => it)}
                component={props => <TextField styles={{ flex: 3 }} required={true} {...props} />}
                type="text"
                placeholder={job_title_placeholder || 'Junior Stylist, Senior...'}
                label={job_title || 'job title'}
              />
              <Field
                name="custom:years_experience"
                validate={[required].filter(it => it)}
                component={props => <TextField styles={{ flex: 1, marginLeft: '20px' }} required={true} {...props} />}
                type="text"
                placeholder={experience_placeholder || '1+ yr'}
                label={experience || 'experience'}
              />
            </JobTitleWrapper>
            <Field
              name="custom:profesional_interest"
              component={props => <TextField optional={true} optionalText={optional || '(optional)'} {...props} />}
              type="text"
              placeholder={interest_placeholder || 'High fashion, Art, Music...'}
              label={interest || 'personal interest'}
            />
          </FieldsLeft>
          <FieldsRight>
            <Field
              name="email"
              validate={[required, enter_a_vaild_email_address].filter(it => it)}
              component={props => <TextField required={true} {...props} disabled={this.isSnsSignUp() && this.isFacebookSignUp()} />}
              type="email"
              placeholder="name@email.com"
              label={email || 'email'}
            />
            <Field
              name="password"
              validate={passwordValidators ? passwordValidators : null}
              component={props => <TextField required={true} {...props} />}
              type="password"
              placeholder="••••••••••"
              label={password || `password (minimum ${_minLength} characters)`}
            />
            <Field
              name="passwordMatch"
              validate={[]
                .concat(passwordValidators)
                .concat([passwords_must_match])
                .filter(it => it)}
              component={props => <TextField required={true} {...props} />}
              type="password"
              placeholder="••••••••••"
              label={confirm_password || 'confirm password'}
            />

            <div style={{ display: 'none' }}>
              <Field component={TextField} name="custom:salon" type="text" placeholder="" label="" />
            </div>

            <ButtonsWrapper>
              {this.isSnsSignUp() && this.isFacebookSignUp() && (
                <Button onClick={this.handleSubmit}>{`${sign_up} (Facebook)`}</Button>
              )}
              {this.isSnsSignUp() && this.isAppleSignUp() && (
                <Button onClick={this.handleSubmit}>{`${sign_up} (Apple)`}</Button>
              )}
              {!this.isSnsSignUp() && (
                <Button onClick={this.handleSubmit}>{sign_up}</Button>
              )}
              {error && <LabelError>{error}</LabelError>}
              <LinkButton style={{ marginTop: '40px' }}>
                <Link to={`/${locale}/login`} href={`/${locale}/login`} style={{ textDecoration: 'underline' }}>
                  {already_a_member}
                </Link>
              </LinkButton>
              {requestSent && (
                <SpinnerButton>
                  <Loading />
                </SpinnerButton>
              )}
            </ButtonsWrapper>
          </FieldsRight>
        </Form>
      </DoubleContainer>
    );
  }
}
export default SignUpFormEmail;
