import { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

class Modal extends Component {
  componentDidMount() {
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
  }

  el = document.createElement('div');

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Modal;
