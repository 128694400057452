import styled from 'styled-components';

import media from '../../styles/media.style';

export const Card = styled.div`
  background: #fff;
  width: 100%;
  height: 200px;

  /* ${media.tabletL`
    height: 230px;
  `}; */
`;

export const CardContent = styled.div`
  flex: 1;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5em 0;

  ${media.tabletL`
    padding: 0;
  `};
`;

export const ThumbnailWrapper = styled.div`
  flex: 1;
  padding: 0.5em 0;

  ${media.tabletL`
    padding: 0;
  `};
`;

export const ModuleSubtitle = styled.div`
  font-size: 11px;
  margin: 0;
  color: #000;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-all;

  ${media.tabletL`
    font-size: 14px;
  `};
`;

export const ModuleSubtitleText = styled.div`
  ${media.tabletL`
    margin-bottom: 5px;
    font-size: 14px;
  `};
`;

export const ModuleSubtitleInfo = styled.div`
  ${media.tabletL`
    font-size: 10px;
  `};
`;

export const ModuleTitle = styled.p`
  font-size: 15px;
  font-weight: 800;
  line-height: 1.5em;
  text-transform: uppercase;
  max-width: 150px;
  margin: 0;
  color: #000;

  ${media.tabletL`
    font-size: 18px;
  `};
`;

export const Button = styled.p`
  font-size: 11px;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  color: #000;
  font-weight: bold;
  text-transform: uppercase;

  > span {
    font-weight: 800;
  }

  ${media.tabletL`
    font-size: 14px;
  `};
`;

export const Thumbnail = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 100%;
  background-image: ${({ url }) => `url(${url}?w=280&fm=jpg&fl=progressive)`};
  background-size: cover;
  background-position: center;
  float: right;

  ${media.desktop`
    width: 140px;
    height: 140px;
  `};
`;

export const ProgressWrapper = styled.div`
  width: 120px;
  height: 120px;
  float: right;
  margin-bottom: 20%;

  ${media.desktop`
    width: 140px;
    height: 140px;
  `};
`;

const centerProps = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActiveBorder = styled.div`
  ${centerProps} text-align: center;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-color: #000;
  background-image: linear-gradient(
      ${({ deg }) => {
        if (deg > 180) {
          return deg - 90;
        }
        return deg + 90;
      }}deg,
      transparent 50%,
      #${({ deg }) => {
          if (deg > 180) {
            return '000';
          }
          return 'd9d6d6';
        }} 50%
    ),
    linear-gradient(90deg, #d9d6d6 50%, transparent 50%);
`;

export const Circle = styled.div`
  ${centerProps} top: 5px;
  left: 5px;
  text-align: center;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-radius: 100%;
  background-color: #fff;
`;

export const Label = styled.div`
  ${centerProps} font-size: 30px;
`;

export const PlaceholderWrapper = styled.div`
  flex: 1;
`;
