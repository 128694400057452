import React, { Component } from 'react';
import styled from 'styled-components';

import media from '../../styles/media.style';
import { BLACK } from '../../styles/variables.style';
import CircleIcon from '../../assets/images/circle.svg';
import TimesIcon from '../../assets/images/times.svg';

import { ReviewBtnWrapper } from './QuizResultsPage.style';

const Container = styled.div`
  text-align: center;
  width: 100%;
`;

const Section = styled.section`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  padding: 12px 0;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  border-top: 1px solid #e0e0e0;
  :last-child {
    border-bottom: 1px solid #e0e0e0;
  }
  ${media.tabletP`
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: unset;
    flex-wrap: unset;
  
    width: 80%;
    margin: auto;
  `};
`;

const ToggleButton = styled.a`
  position: relative;
  cursor: pointer;

  span {
    ::before,
    ::after {
      display: block;
      content: '';
      position: absolute;
      top: 7px;
      left: 0;
      width: 15px;
      height: 1px;
      background-color: ${BLACK};
      border-radius: 1px;
    }

    ::before {
      top: 0;
      left: 7px;
      width: 1px;
      height: 15px;
    }
  }
`;

const IndexTextContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  align-items: center;

  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  -webkit-box-flex: 2;
  -ms-flex: 2;
  flex: 2;
`;

const IndexText = styled.h5`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  margin: 8px;
`;

const QandABlock = styled.div`
  -webkit-box-flex: 8;
  -ms-flex: 8;
  flex: 8;
`;

const Question = styled.p`
  font-size: 16px;
`;

const Answer = styled.p`
  font-weight: bold;
  font-size: 16px;
`;

const CorrectText = styled.p`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;

  align-items: center;

  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const OpenAndCloseIcon = styled.div`
  position: relative;
  transform: translate(5px, 0);

  ::before,
  ::after {
    display: block;
    content: '';
    position: absolute;
    top: 6px;
    left: 0;
    width: 14px;
    height: 2px;
    background-color: ${BLACK};
    border-radius: 1px;
  }

  ${({ isOpen }) =>
    !isOpen &&
    `
    ::before {
      top: 0;
      left: 6px;
      width: 2px;
      height: 14px;
    }
  `};
`;

const ToggleButtonWrapper = styled(ReviewBtnWrapper)`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  margin-bottom: 22px;
`;

export default class AnswerReview extends Component {
  state = { isOpen: false };

  toggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { isOpen } = this.state;
    const { answers, questions, copy } = this.props;
    return (
      <Container>
        <ToggleButtonWrapper>
          <ToggleButton onClick={this.toggleOpen}>{copy.answer}</ToggleButton>
          <OpenAndCloseIcon isOpen={isOpen} />
        </ToggleButtonWrapper>
        {isOpen && (
          <div>
            {questions.map((q, i) => (
              <Section key={q.question}>
                {answers[i][q.correctAnswer - 1] ? (
                  <CorrectText>
                    <Icon src={CircleIcon} />
                  </CorrectText>
                ) : (
                  <CorrectText>
                    <Icon src={TimesIcon} />
                  </CorrectText>
                )}
                <IndexTextContainer>
                  <IndexText>
                    QUIZ {i + 1}/{questions.length}
                  </IndexText>
                </IndexTextContainer>
                <QandABlock>
                  <Question>{q.question}</Question>
                  <Answer>{String.fromCharCode(64 + q.correctAnswer)}. {q.answers[q.correctAnswer - 1]}</Answer>
                </QandABlock>
              </Section>
            ))}
          </div>
        )}
      </Container>
    );
  }
}
