import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = ({ color, size = '17px' }) => (
  <svg width={size} height={size} viewBox="0 0 17 17">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
      <g transform="translate(-287.000000, -22.000000)" stroke={color} strokeWidth="3">
        <g transform="translate(287.000000, 22.000000)">
          <g transform="translate(2.000000, 2.000000)">
            <path d="M8.96551724e-05,12.6790793 L12.6791241,4.48275862e-05" />
            <path d="M12.6792138,12.6790793 L0.000179310345,4.48275862e-05" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

CloseIcon.defaultProps = {
  color: '#000',
  size: '17px',
};

CloseIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

export default CloseIcon;
