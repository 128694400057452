import styled from 'styled-components';

import media from '../../../styles/media.style';
import {
  SCREEN_LOCKUP_WIDTH,
  BLACK,
  WHITE,
  MOBILE_PADDING,
  DARK_GREY,
  LIGHT_GREY,
} from '../../../styles/variables.style';

export const Section = styled.section`
  position: relative;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 6em ${MOBILE_PADDING}px 0;
  background-color: ${WHITE};
`;

export const SectionInner = styled.div`
  width: 100%;
  max-width: ${SCREEN_LOCKUP_WIDTH}px;
  margin: 0 auto;
  color: #fff;
  padding-bottom: 4em;
  display: flex;
  flex-direction: column;

  ${media.tabletL`
    padding-bottom: 0;
    flex-direction: row;
  `};
`;

export const HeroTitle = styled.h1`
  max-width: 600px;
  margin: 0 0 1.5em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 21px;
  padding: 0 19px;
  font-weight: 900;

  ${media.tabletL`
    font-size: 50px;
    margin: 0 0 3em;
    padding: 0;
  `};
`;

export const HeroSignedInWrapper = styled.div`
  flex: 2;
  color: ${BLACK};
  padding-top: 1em;

  p {
    max-width: 780px;
    text-align: center;
    margin: 0;
    font-size: 14px;
  }

  ${media.tabletL`
    p {
      text-align: left;
      margin: 1em 0;
      font-size: 16px;
    }
  `};
`;

export const HeroSignedInTitle = styled.h1`
  max-width: 600px;
  font-size: 14px;
  padding: 0 19px;
  margin: 0 auto;
  text-align: center;
  line-height: 1.6em;
  font-weight: 900;

  span {
    font-weight: 400;
  }

  ${media.tabletL`
    font-size: 50px;
    padding: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0 0 0.7em;
    text-align: left;
    line-height: 1em;

    span {
      font-weight: 800;
    }
  `};
`;

export const ProfileInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1.1;

  ${media.tabletL`
    /* padding-bottom: 0; */
  `};

  ${media.desktop`
    min-height: 270px;
  `};
`;

export const ProfileImageWrapper = styled.div`
  position: relative;
`;

export const ProfileImage = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-image: ${({ url }) => `url(${url})`};

  ${media.tabletL`
    height: 170px;
    width: 170px;
  `};
`;

export const ProfileEditBtn = styled.span`
  position: absolute;
  display: block;
  bottom: 0;
  right: 0;
  background-color: ${WHITE};
  border-radius: 50%;
  border: 1px solid #999999;
  cursor: pointer;
  width: 28px;
  height: 28px;
  padding: 0;

  svg {
    width: 28px;
    height: 28px;
  }

  ${media.tabletL`
    width: 56px;
    height: 56px;
    padding: 0 0 0 2px;

    svg {
      width: 56px;
      height: 56px;
    }
  `};
`;

export const ProfileName = styled.h5`
  font-size: 16px;
  font-weight: 800;
  margin: 0.6em 0 0.3em;
  color: ${BLACK};
  text-transform: uppercase;
  text-align: center;

  ${media.tabletL`
    font-size: 36px;
  `};
`;

export const ProfileTitle = styled.p`
  font-size: 11px;
  color: #666;
  margin: 0 0 2em;
  text-align: center;

  ${media.tabletL`
    font-size: 16px;
  `};
`;

export const DesktopDropdownWrapper = styled.div`
  display: none;

  ${media.tabletL`
    display: flex;
  `};
`;

export const MobileDropdownWrapper = styled.div`
  display: block;

  ${media.tabletL`
    display: none;
  `};
`;

export const CTA = styled.div`
  position: absolute;
  bottom: -29px;
  width: calc(100% - ${MOBILE_PADDING * 2}px);
  right: 50%;
  transform: translateX(50%);
  text-transform: uppercase;

  ${media.tabletL`
    width: 100%;
    max-width: ${SCREEN_LOCKUP_WIDTH - 20}px;
    bottom: -42px;
  `};

  a {
    display: inline-block;
    color: ${WHITE};
    width: 100%;
    line-height: 63px;
    text-decoration: none;
    font-size: 12px;
    text-align: center;

    :visited {
      color: ${WHITE};
    }

    > span {
      font-weight: 800;
      letter-spacing: 1px;
    }

    ${media.tabletL`
      font-size: 15px;
      padding: 0 126px;
      width: auto;
    `};
  }
`;

export const FilterBtn = styled.button`
  cursor: pointer;
  position: relative;
  background-color: ${BLACK};
  border: none;
  font-size: 16px;
  font-weight: 800;
  height: 57px;
  padding: 0 20px;
  line-height: 57px;
  text-transform: uppercase;
  color: ${WHITE};
  width: 100%;
  text-align: left;
  letter-spacing: 1px;
  white-space: nowrap;

  ${media.tabletL`
    height: 85px;
    line-height: 85px;
    padding: 0 40px;
  `};

  span {
    margin-right: 50px;
    font-weight: 800;
    font-size: 12px;
    letter-spacing: 1px;

    ${media.tabletL`
      font-size: 16px;
    `};
  }

  ::after {
    content: '';
    position: absolute;
    top: 42%;
    right: 20px;
    transform: translateY(-50%) rotate(-45deg);
    width: 6px;
    height: 6px;
    border-top: 2px solid ${WHITE};
    border-right: 2px solid ${WHITE};

    ${media.tabletL`
      right: 40px;
    `};
  }

  ::before {
    content: '';
    position: absolute;
    top: 58%;
    right: 20px;
    transform: translateY(-50%) rotate(135deg);
    width: 6px;
    height: 6px;
    border-top: 2px solid ${WHITE};
    border-right: 2px solid ${WHITE};

    ${media.tabletL`
      right: 40px;
    `};
  }
`;

export const FilterMenuWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${WHITE};
  z-index: 999999999;
  padding: 22px 0;
`;

export const CloseBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 ${MOBILE_PADDING}px;
`;

export const CloseBtn = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  margin-bottom: 1em;
  padding: 0;
`;

export const FilterTypeList = styled.div`
  overflow: auto;
  flex: 1;
`;

export const FiltersWrapper = styled.div`
  padding: 15px 0;
  background-color: ${LIGHT_GREY};
`;

export const FilterItem = styled.button`
  height: 45px;
  cursor: pointer;
  color: ${BLACK};
  width: 100%;
  text-align: left;
  border: none;
  padding: 0 30px;
  font-size: 14px;
  text-transform: uppercase;
  background-color: ${LIGHT_GREY};
  display: flex;
  align-items: center;

  span {
    // vertical-align: middle;
    letter-spacing: 1px;
  }

  > span:first-child {
    flex-shrink: 0;
    background-color: ${({ selected }) => (selected ? BLACK : WHITE)};
  }
  > span:last-child {
    white-space: pre-wrap;
    word-break: break-all;
  }

  :hover {
    > span:first-child {
      background-color: ${({ selected }) => (selected ? BLACK : DARK_GREY)};
    }
  }
`;

export const Checkmark = styled.span`
  display: inline-block;
  position: relative;
  width: 25px;
  height: 25px;
  border: 1px solid ${BLACK};
  margin-right: 12px;
  border-radius: 50%;

  ::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%) rotate(-45deg);
    border-bottom: 1px solid ${WHITE};
    border-left: 1px solid ${WHITE};
    width: 8px;
    height: 4px;
  }
`;

export const FilterButtonWrapper = styled.div`
  padding: 0 ${MOBILE_PADDING}px;
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
`;

export const ClearBtn = styled.button`
  height: 45px;
  line-height: 45px;
  background-color: ${WHITE};
  border: 1px solid ${BLACK};
  flex: 1;
  text-align: center;
  margin-right: 8px;

  span {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 12px;
    letter-spacing: 1px;
  }
`;

export const ApplyBtn = ClearBtn.extend`
  background-color: ${BLACK};
  color: ${WHITE};
  margin-right: 0;
`;

export const SearchWrapper = styled.div`
  padding: 30px 0 26px 0;
  display: flex;
  ${media.tabletL`
    padding: 60px 0 160px 0;
  `};
`;

export const SearchInput = styled.input`
    width: 100%;
    border: 0;
    border-bottom: 1px solid #000;
    padding: 8px 0;
    font-size: 17px;

    :focus {
      outline: none;
    }
`;

export const SearchButton = styled.img`
    background-color: #000;
    width: 50px;
    height: 40px;
    padding: 10px;
    margin-left: 10px;
    cursor: pointer;

    ${media.tabletL`
      width: 100px;
      height: 60px;
      margin-left: 20px;
      padding: 13px;
    `};
`;
