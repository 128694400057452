import { css } from 'styled-components';

import { screenSizes } from './variables.style';

// Iterate through the sizes and create a media template
// Use it like this:
//    ${media.tabletP`background: dodgerblue;`}

const breakpoints = Object.keys(screenSizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${screenSizes[label] / 16}em) {
      ${css(...args)};
    }
  `;

  return acc;
}, {});

const pixelDensity = {
  x2: (...args) => css`
    @media only screen and (min-device-pixel-ratio: 2) {
      ${css(...args)};
    }
  `,
  x3: (...args) => css`
    @media only screen and (min-device-pixel-ratio: 3) {
      ${css(...args)};
    }
  `,
};

export default {
  ...breakpoints,
  ...pixelDensity,
};
