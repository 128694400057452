import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import validator from 'validator';

import TextField from '../../TextField/TextField';

import { Container, Title, Form, Button, LabelError } from './ResetPassword.style';
import { initialValidatorObject, updateValidatorObject } from '../../../utils';

const required = copy => value => (value ? undefined : copy.required || 'Required');
const email = copy => value =>
  validator.isEmail(value) ? undefined : copy.enter_a_vaild_email_address || 'Not Valid Email';
const v_translate = {
  required,
  enter_a_vaild_email_address: email,
};

class ResetPasswordForm extends Component {
  static defaultProps = {
    copy: {
      required: 'Required',
    },
    global_copy: {
      reset_password: 'Reset Password',
      send_code: 'Send Code',
      change_password: 'Change Password',
    },
  };

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    auth: PropTypes.shape().isRequired,
    forgotPassword: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired,
  };

  state = {};

  componentDidMount() {
    this.setState(initialValidatorObject(v_translate, this.props));
  }

  componentWillReceiveProps(props) {
    const update = updateValidatorObject(v_translate, this.props.copy, props.copy);
    if (update) {
      this.setState(update);
    }
  }

  render() {
    const { handleSubmit, auth, error, forgotPassword, changePassword, global_copy } = this.props;
    const { required, enter_a_vaild_email_address } = this.state;
    return (
      <Container>
        <Title>{global_copy.reset_password}</Title>
        <Form>
          <Field
            name="email"
            validate={[required, enter_a_vaild_email_address].filter(it => it)}
            component={TextField}
            type="string"
            placeholder="name@email.com"
            label={global_copy.emailField || 'email'}
          />
          {error && <LabelError>{error}</LabelError>}
          {auth.hasSentCode !== 'AUTH_SUCCESS' ? (
            <Button onClick={handleSubmit(forgotPassword)}>{global_copy.send_code}</Button>
          ) : (
            <Button onClick={handleSubmit(changePassword)}>{global_copy.change_password}</Button>
          )}
        </Form>
      </Container>
    );
  }
}

export default ResetPasswordForm;
