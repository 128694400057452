import React, { Component } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { Field } from 'redux-form';
import { Link } from 'react-router-dom';

import TextField from '../../TextField/TextField';
import { initialValidatorObject, updateValidatorObject } from '../../../utils';
import Placeholder from '../../Placeholder';

import {
  Annotation,
  Container,
  Title,
  SubTitle,
  Form,
  Button,
  LinkButton,
  ButtonsWrapper,
  FieldsWrapper,
  LabelError,
} from './SignIn.style';

const required = copy => value => (value ? undefined : copy.required || 'Required');
const email = copy => value =>
  validator.isEmail(value) ? undefined : copy.enter_a_vaild_email_address || 'Not Valid Email';
const v_translate = {
  required,
  enter_a_vaild_email_address: email,
};

const style = {
  label: {
    fontSize: 12,
  },
};

class SignInForm extends Component {
  static defaultProps = {
    global_copy: {
      login: '',
      forgot_password: '',
      sign_up: '',
    },
    copy: {
      required: 'Required',
      enter_a_vaild_email_address: 'Not Valid Email',
    },
  };

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    error: PropTypes.string,
    errorSns: PropTypes.string,
    signIn: PropTypes.func.isRequired,
    signInWithFacebook: PropTypes.func.isRequired,
    signInWithApple: PropTypes.func.isRequired,
  };

  state = {};

  componentDidMount() {
    this.setState(initialValidatorObject(v_translate, this.props));
  }

  componentWillReceiveProps(props) {
    const update = updateValidatorObject(v_translate, this.props.copy, props.copy);
    if (update) {
      this.setState(update);
    }
  }

  handleSignInWithFacebook = () => {
    this.props.signInWithFacebook(this.props.locale);
  };

  handleSignInWithApple = () => {
    this.props.signInWithApple(this.props.locale);
  };

  render() {
    const { authRedirect, handleSubmit, locale, error, errorSns, signIn, copy, global_copy } = this.props;
    const { required, enter_a_vaild_email_address } = this.state;

    return (
      <Container>
        <Title>{global_copy.login || <Placeholder w="200px" h="30px" />}</Title>
        {authRedirect && <Annotation>{copy.user_free_movie}</Annotation>}
        <Form>
          <FieldsWrapper>
            <Field
              style={style.button}
              name="email"
              validate={[required, enter_a_vaild_email_address].filter(it => it)}
              component={TextField}
              type="email"
              placeholder="name@email.com"
              label={global_copy.emailField || 'email'}
            />
            <Field
              style={style.button}
              name="password"
              validate={[required].filter(it => it)}
              component={TextField}
              type="password"
              placeholder="••••••••••"
              label={global_copy.passwordField || 'Password'}
            />

            {error && <LabelError>{error}</LabelError>}
          </FieldsWrapper>
          <ButtonsWrapper>
            <Button onClick={handleSubmit(signIn)}>{global_copy.login || <Placeholder w="100px" h="20px" />}</Button>
            <LinkButton style={{ marginTop: '20px', marginBottom: '40px' }}>
              <Link
                to={`/${locale}/resetpassword`}
                href={`/${locale}/resetpassword`}
                style={{ textDecoration: 'underline' }}
              >
                {global_copy.forgot_password || <Placeholder w="200px" h="20px" />}
              </Link>
            </LinkButton>
          </ButtonsWrapper>
        </Form>
        <ButtonsWrapper style={{ marginBottom: '40px' }}>
          <SubTitle style={{ marginBottom: '20px' }}>{copy.log_in_with_social_media}</SubTitle>
          {errorSns && <LabelError>{errorSns}</LabelError>}
          <Button onClick={this.handleSignInWithFacebook} type="Facebook">
            {copy.log_in_with_facebook || <Placeholder w="100px" h="20px" />}
          </Button>
          <Button onClick={this.handleSignInWithApple} type="">
            {copy.sign_in_with_apple || <Placeholder w="100px" h="20px" />}
          </Button>
        </ButtonsWrapper>
      </Container>
    );
  }
}

export default SignInForm;
