import { connect } from 'react-redux';
import { reducer } from '../../redux/sagas/aws-cognito-redux-saga';
import { snsAuthAction } from '../../redux/sagas/sns-auth-redux-saga';
import { clearCurrentUser } from '../../redux/actions/user';
import Logout from './Logout';

const mapStatetoProps = state => ({
  snsAuth: state.snsAuth,
});

const mapDispatchToProps = dispatch => ({
  signOut: () => {
    dispatch(reducer.signOut());
  },
  snsSignOut: locale => {
    dispatch(snsAuthAction.snsLogoutRequest(locale));
  },
  clearCurrentUser: () => {
    dispatch(clearCurrentUser());
  }
});

export default connect(mapStatetoProps, mapDispatchToProps)(Logout);
