import { connect } from 'react-redux';

import { reducer } from '../../../redux/sagas/aws-cognito-redux-saga';
import { snsAuthAction } from '../../../redux/sagas/sns-auth-redux-saga';
import { clearCurrentUser } from '../../../redux/actions/user';
import { updateAuthRedirect } from '../../../redux/actions/authRedirect';
import SignInComponent from './SignIn';

const mapStatetoProps = state => ({
  auth: state.auth,
  authRedirect: state.authRedirect,
  copy: state.siteOptions.siteCopyStylistToolSignUpPage,
  global_copy: state.siteOptions.siteCopyELearningGlobal,
  snsAuth: state.snsAuth,
});

const mapDispatchToProps = dispatch => ({
  init: () => {
    dispatch(reducer.init());
  },
  signIn: (username, password, code) => {
    dispatch(reducer.signIn(username, password, code));
  },
  signInWithFacebook: locale => dispatch(snsAuthAction.snsLoginRequest('Facebook', locale)),
  signInWithApple: locale => dispatch(snsAuthAction.snsLoginRequest('SignInWithApple', locale)),
  clearCurrentUser: () => dispatch(clearCurrentUser()),
  updateAuthRedirect: path => dispatch(updateAuthRedirect(path)),
});

export default connect(mapStatetoProps, mapDispatchToProps)(SignInComponent);
