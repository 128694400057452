import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import { state } from '../../../redux/sagas/aws-cognito-redux-saga';

import SignInForm from './SignInForm';
import PasswordReset from './PasswordReset';

const mapErrorCodeToCopy = {
  UserNotFoundException: 'email_not_found',
  NotAuthorizedException: 'incorrect_username_or_password',
};

class SignIn extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    signIn: PropTypes.func.isRequired,
    auth: PropTypes.shape({
      isSignedIn: PropTypes.string,
      isConfirmed: PropTypes.string,
    }).isRequired,
    init: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        lang: PropTypes.string,
      }),
    }).isRequired,
  };

  static defaultProps = {
    copy: {
      email_not_found: 'This email address is not registered.',
      incorrect_username_or_password: 'Incorrect username or password.',
    },
  };

  constructor(props) {
    super(props);
    this.passwordReset = this.passwordReset.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.init();
  }

  componentWillUnmount() {
    console.log('componentWillUnmount');
    this.props.updateAuthRedirect(null);
  }

  componentWillReceiveProps(nextProps) {
    const { auth, clearCurrentUser } = nextProps;

    if (this.props.auth.info && auth.info && this.props.auth.info.username && !auth.info.username) {
      clearCurrentUser();
    }
  }

  componentDidUpdate() {
    const { match, auth, history } = this.props;
    const { lang } = match.params;
    if (auth.isSignedIn === state.AUTH_SUCCESS) {
      history.push(`/${lang}/dashboard`);
    }
  }

  signIn = values => {
    if (this.props.auth.isConfirmed === state.AUTH_SUCCESS) {
      this.props.signIn(values.email, values.password);
    } else {
      this.props.signIn(values.email, values.password, values.code);
    }
  };

  passwordReset() {
    const { match } = this.props;
    const { lang } = match.params;
    this.props.history.push(`/${lang}/forcechangepassword`);
  }

  render() {
    const { match, handleSubmit, auth, authRedirect, snsAuth, copy, global_copy } = this.props;
    const { lang } = match.params;

    let error, errorSns;
    if (auth && auth.error && auth.error.code) {
      const copyName = mapErrorCodeToCopy[auth.error.code];
      error = copyName ? copy[copyName] : auth.error.message;
    } else if (snsAuth && snsAuth.error && snsAuth.error.code) {
      const copyName = mapErrorCodeToCopy[snsAuth.error.code];
      errorSns = copyName ? copy[copyName] : snsAuth.error.message;
      if (snsAuth.error.code === 'UnlinkedSnsUser') {
        errorSns = copy.not_linked_with_sns || null;
      }
    }

    if (auth.passwordResetRequired === state.AUTH_SUCCESS) {
      return <PasswordReset handleSubmit={handleSubmit} passwordReset={this.passwordReset} copy={copy} />;
    }
    return (
      <SignInForm
        handleSubmit={handleSubmit}
        error={error}
        errorSns={errorSns}
        signIn={this.signIn}
        signInWithFacebook={this.props.signInWithFacebook}
        signInWithApple={this.props.signInWithApple}
        locale={lang}
        copy={copy}
        global_copy={global_copy}
        authRedirect={authRedirect}
      />
    );
  }
}

// Decorate the form component
export default reduxForm({ form: 'signIn' })(SignIn);
