import { GET_USER, GET_USER_AVATAR, CLEAR_USER, DO_NOTHING } from '../constants';
import { fetchUserService, fetchUserAvatarService } from '../../services/api';

export const fetchUserCreator = () => {
  const api = fetchUserService;
  const actionName = GET_USER;
  const hash = {};
  const timeout = 5000;
  const hasCacheExpired = (username) => (hash[username] && Date.now() -hash[username] > timeout)
  return function fetchUser(username) {
    if(!hash[username] || hasCacheExpired(username)) {
      hash[username] = Date.now();
      return {
        type: actionName,
        async payload() {
          const data = await api(username);
          return data;
        },
      };
    } else {
      console.log('DO_NOTHING User')
      return {
        type: DO_NOTHING
      };
    }
  }
};
export const fetchUser = fetchUserCreator();

export const fetchUserAvatarCreator = () => {
  const api = fetchUserAvatarService;
  const actionName = GET_USER_AVATAR;
  const hash = {};
  const timeout = 5000;
  const hasCacheExpired = (username) => (hash[username] && Date.now() -hash[username] > timeout)
  return function fetchUser(username) {
    if(!hash[username] || hasCacheExpired(username)) {
      hash[username] = Date.now();
      return {
        type: actionName,
        async payload() {
          const data = await api(username);
          return data;
        },
      };
    } else {
      console.log('DO_NOTHING User Avatar')
      return {
        type: DO_NOTHING
      };
    }
  }
};
export const fetchUserAvatar = fetchUserAvatarCreator();

export const clearCurrentUser = () => ({
  type: CLEAR_USER,
});
