import styled from 'styled-components';

import backgroundPattern from '../../../assets/images/hair.svg';

import media from '../../../styles/media.style';

const COLUMN_GAP = 105;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LabelError = styled.p`
  text-transform: uppercase;
  font-size: 11px;
  margin-top: 7px;
  letter-spacing: 0.5px;
  color: red;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;

  a {
    font-weight: 900;
    color: #000;
    > span {
      font-weight: 800;
      color: #000;
    }
    :visited {
      color: #000;
    }
  }
`;

export const DoubleContainer = Container.extend`
  max-width: ${COLUMN_GAP + 640}px;
`;

export const ContainerBackground = styled.div`
  padding: 30px;
  position: relative;
  width: 100%;
  background-image: url(${backgroundPattern});
  background-size: cover;
  background-position: top;
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 30px;
  margin: 0 0 40px;
  font-weight: 400;

  ${media.tabletL`
    font-size: 38px;
    margin: 0 0 60px;
  `};
`;

export const Subtitle = styled.div`
  font-size: 13px;
  line-height: 1.5;
  margin: 5px 0 0;
  font-weight: 400;

  span {
    text-transform: capitalize;
  }

  ${media.tabletL`
    font-size: 18px;
    margin: 0;
  `};
`;

export const Text = styled.p`
  font-size: 15px;
  line-height: 1.5em;

  ${media.tabletL`
    font-size: 18px;
    margin-bottom: 60px;
  `};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  ${media.tabletL`
    flex-direction: row;
  `};
`;

export const Button = styled.button`
  width: 100%;
  height: 60px;
  background-color: ${({ type }) => handleColorType(type)};
  display: block;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 800;
  cursor: pointer;
  letter-spacing: 1px;
  border: none;
  text-align: center;

  &.disabled {
    // background-color: #ccc;
    opacity: 0.3;
    cursor: default;
  }
`;

const handleColorType = type => {
  switch (type) {
    case 'Facebook':
      return '#4267B2';
    default:
      return '#000';
  }
};

export const SignedUpButton = styled.a`
  width: 100%;
  height: 60px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 800;
  cursor: pointer;
  letter-spacing: 1px;
  border: none;
  margin: 0 auto;

  :visited {
    color: #fff;
  }

  ${media.tabletL`
    width: 300px;
  `};
`;

export const LinkButton = styled.div`
  font-size: 12px;
  line-height: 1.5;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  margin-bottom: 1.5em;
`;

export const JobTitleWrapper = styled.div`
  display: flex;
`;

export const FieldsLeft = styled.div`
  width: auto;

  ${media.tabletL`
    width: 320px;
  `};
`;

export const FieldsRight = styled.div`
  padding-bottom: 3em;

  ${media.tabletL`
    width: 320px;
    padding-bottom: 0;
    margin-left: ${COLUMN_GAP}px;
  `};
`;

export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: flex-end;
  margin: 40px 0 0;
`;

export const DropdownsWrapper = styled.div`
  margin-top: 30px;
  display: flex;
`;
export const DropdownWrapper = styled.div`
  width: 100%;
  margin-right: 10px;
`;

export const CountryDropdown = styled.select`
  width: 100%;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #000;
  padding: 0 0 8px 0;
  font-size: 17px;
  background-color: #fff;
`;

export const CityDropdown = styled.select`
  width: 100%;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #000;
  padding: 0 0 8px 0;
  font-size: 17px;
  background-color: #fff;
`;

export const Label = styled.div`
  text-transform: uppercase;
  font-size: 9px;
  margin-top: 7px;
  float: left;
`;

export const SpinnerButton = styled.div`
  padding: 10px 0;
`;

export const RedLabel = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 10px;

  ${media.tabletP`
    font-size: 14px;
  `};
`;

export const TermsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;

export const Spinner = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;

  ::after {
    content: ' ';
    display: block;
    width: 20px;
    height: 20px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #000;
    border-color: #000 transparent #000 transparent;
    animation: spinner 1.2s linear infinite;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
