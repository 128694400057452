import styled from 'styled-components';

import hairBg from '../../assets/images/hair.svg';

import {
  BLACK,
  LIGHT_GREY,
  NAVIGATION_DESKTOP_HEIGHT,
  NAVIGATION_MOBILE_HEIGHT,
  WHITE,
  MOBILE_PADDING,
} from '../../styles/variables.style';
import media from '../../styles/media.style';

export const Wrapper = styled.div`
  height: calc(100vh - ${NAVIGATION_MOBILE_HEIGHT}px);
  background: center / cover no-repeat url(${hairBg}), ${LIGHT_GREY};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${MOBILE_PADDING}px;

  ${media.tabletL`
  height: calc(100vh - ${NAVIGATION_DESKTOP_HEIGHT}px);
  padding: 0;
  `};
`;

export const ContentWrapper = styled.div`
  ${media.tabletL`
    width: 50%;
  `};
`;

export const Title = styled.h1`
  font-size: 26px;
  font-weight: 500;
  line-height: 1.31;
  letter-spacing: 0.3px;
  text-align: center;
  text-transform: uppercase;

  ${media.tabletL`
  font-size: 38px;
  font-weight: 500;
  line-height: 1.26;
  letter-spacing: 0.5px;
  `};
`;

export const Subtitle = styled.h5`
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.1px;
  text-align: center;
  margin-bottom: 10%;

  ${media.tabletL`
  font-size: 18px;
  line-height: 1.39;
  letter-spacing: 0.1px;
  `};
`;

export const Button = styled.div`
  background-color: ${BLACK};
  color: ${WHITE};
  font-size: 12px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  width: fit-content;
  text-align: center;
  margin: 0 auto;

  a {
    display: block;
    padding: 25px 60px;
    color: ${WHITE};
    text-decoration: none;

    ${media.tabletL`
      padding: 25px 80px;
    `};

    :visited {
      color: ${WHITE};
    }
  }

  ${media.tabletL`
    font-size: 14px;
    letter-spacing: 0.7px;
  `};
`;
