import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ModuleCard from '../../../components/ModuleCard';

import { SCREEN_LOCKUP_WIDTH, MOBILE_PADDING } from '../../../styles/variables.style';
import media from '../../../styles/media.style';
import BadgeLine from '../../../assets/images/badge_line.png';

const Wrapper = styled.div`
  width: 100%;
  max-width: ${SCREEN_LOCKUP_WIDTH + MOBILE_PADDING * 2}px;
  margin: 0 auto;
  padding: 3em ${MOBILE_PADDING}px;

  ${media.tabletL`
    padding: 100px ${MOBILE_PADDING};
  `};
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;


  li {
    margin: 4.5px 0;
  }


  ${media.tabletL`
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      width: calc(100% / 3 - 6px);
    }
    li:nth-child(n+4) {
      margin-top: 40px;
    }
  `};

`;

const ModuleList = ({ sections, locale, copy }) => {

  const contents = sections
    ? sections.filter(s => s.modules.length).map(section => {
        return {
          key: section.slug,
          link: `/${locale}/course/${section.slug}`,
          thumbnailUrl: section.thumbnail ? section.thumbnail.file.url : null,
          badgeImage: BadgeLine,
          title: section.sectionTitle,
          infoList: [
            `${section.modules.length} ${section.modules.length === 1 ? copy.module : copy.modules}`,
            ` ${section.modules.reduce((acc, m) => acc + m.moduleChapters.filter(c => c.memberflag !== false).length, 0)} ${copy.chapters} `,
            ` ${section.sectionDuration || section.modules.reduce((acc, m) => (acc + m.moduleDuration), 0)} ${copy.minutes}`,
          ],
          ...section,
        }
      })
    : [];


  return (
    <Wrapper>
      <List>
        {contents.map(content => (
          <li key={content.key}>
            <ModuleCard content={content} locale={locale} copy={copy} />
          </li>
        ))}
      </List>
    </Wrapper>
  );
};

ModuleList.defaultProps = {
  copy: {},
  modules: [{ key: 1 }, { key: 2 }, { key: 3 }],
};

ModuleList.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  locale: PropTypes.string.isRequired,
};

export default ModuleList;
