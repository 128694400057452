import { injectGlobal } from 'styled-components';

import { DEFAULT_FONT_FAMILY } from './variables.style';
import media from './media.style';

/* eslint-disable */
injectGlobal`
  * {
    font-family: ${DEFAULT_FONT_FAMILY};
  }
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 38px;
    line-height: 1.25em;

    ${media.tabletL`
      font-size: 38px;
    `};
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 16px;

    ${media.tabletL`
      font-size: 19px;
    `};
  }
  h6 {
    font-size: 16px;

    ${media.tabletL`
      font-size: 19px;
    `};
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    font-size: 16px;

    ${media.tabletL`
      font-size: 19px;
    `};
  }
  p {
    font-size: 16px;
    line-height: 1.5em;

    ${media.tabletL`
      font-size: 19px;
    `};
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }

  button, input, optgroup, select, textarea {
    font-family: ${DEFAULT_FONT_FAMILY};
  }
`;
/* eslint-enable */
