import React, { createRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

import { findParent, isOwner } from '../../utils';
import media from '../../styles/media.style';
import { screenSizes } from '../../styles/variables.style';
import { BodyNoScroll } from '../../styles/mixins.style';
import { state } from '../../redux/sagas/aws-cognito-redux-saga';
import logoIcon from '../../assets/images/sp-logo-black.svg';
import menuIcon from '../../assets/images/menu.svg';
import closeButtonIcon from '../../assets/images/close-button.svg';
import {
  NAVIGATION_MOBILE_HEIGHT,
  NAVIGATION_DESKTOP_HEIGHT,
  NAVIGATION_DESKTOP_PADDING,
  NAVIGATION_MOBILE_PADDING,
  PAGE_WIDTH
} from '../../styles/variables.style';

const Header = styled.header`
  align-items: center;
  flex-direction: column;
  display: flex;
`;

const HeaderInner = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: calc(100% - ${NAVIGATION_MOBILE_PADDING * 2}px);
  padding: 0;
  width: ${PAGE_WIDTH}px;
  height: ${NAVIGATION_MOBILE_HEIGHT}px;
  position: relative;

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${media.tabletL`
    max-width: calc(100% - ${NAVIGATION_DESKTOP_PADDING * 2}px);
    height: ${NAVIGATION_DESKTOP_HEIGHT}px;
  `};
`;

const Icon = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: ${({ white }) => (white ? '0' : '1')};
  transition: opacity 0.3s;
  object-fit: contain;
  object-position: center;
`;

const NavigationWrapper = styled.div`
  background: #fff;
  position: absolute;
  top: ${NAVIGATION_MOBILE_HEIGHT - 1}px;
  left: -${NAVIGATION_MOBILE_PADDING}px;
  right: -${NAVIGATION_MOBILE_PADDING}px;
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  height: calc(100vh - ${NAVIGATION_MOBILE_HEIGHT + 1}px);
  z-index: 5;
  transform: ${({ active }) => (active ? 'none' : 'translateY(-100vh)')};

  ${media.tabletL`
    position: static;
    flex-direction: row;
    height: auto;
    transform: none;
    z-index: 0;
  `};
`;

const NavLinkListBaseTablet = `
  align-items: center;
  flex-direction: row;
`;

const NavLinkListBase = `
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 0 20px;

  ${media.tabletL`
   ${NavLinkListBaseTablet}
  `};
`;

const NavLinkBase = `
  font-family: FuturaStd;
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.3px;
  text-align: center;
  margin: 16px 0;

  a {
    color: #333333;
    text-decoration: none;
    text-transform: uppercase;
    display: block;
  }

  ${media.tabletL`
    line-height: 2.5;
  `}
`;

const PrimaryLinks = styled.ul`
  ${NavLinkListBase} margin-top: 40px;

  ${media.tabletL`
    ${NavLinkListBaseTablet}
    margin-top: 0;
    flex: 1;
  `};
`;

const PrimaryLink = styled.li`
  ${NavLinkBase} ${media.tabletL`
    line-height: 2.5;
    margin: 0 0 0 60px;
  `};
`;

const SecondaryLinks = styled.ul`
  ${NavLinkListBase};

  ${media.tabletL`
   ${NavLinkListBaseTablet}
  `};
`;

const SecondaryLink = styled.li`
  ${NavLinkBase} margin: 0;
  padding: 16px 0;

  :first-child {
    margin-top: 16px;
  }

  ${media.tabletL`
    padding: 8px 20px;
    :last-child {
      padding-right: 0;
    }
    :first-child {
      margin-top: 0;
    }
    :nth-child(n + 2) {
      border-left: 1px solid #333;
    }
  `};
`;

const MenuWrapper = styled.div`
  display: block;
  width: 19px;
  height: 16px;
  position: relative;

  ${media.tabletL`
    display: none;
  `};
`;

const LogoWrapper = styled.div`
  position: relative;
  width: 55px;
  height: 18px;
  display: inline-block;

  ${media.tabletL`
    width: 110px;
    height: 35px;
  `};
`;

export default class HeaderComponent extends React.Component {
  // signOut = () => {
  //   this.props.signOut();
  // };

  static defaultProps = {
    copy: {
      courses: 'Courses',
      shiseido_professional: 'Shiseido Professional',
      login: 'Login',
      settings: 'Settings',
      logout: 'Logout',
      progress: 'Stylist Progress',
      about: 'About',
      gnav_login: 'Login',
    },
    match: {
      params: {
        lang: 'en'
      }
    }
  };

  state = {
    menuOpen: false
  };

  buttonRef = createRef();

  UNSAFE_componentWillMount() {
    this.closeHandler = ({ target }) =>
      !findParent((this.buttonRef && this.buttonRef.current) || 'header', target) && this.setState({ menuOpen: false });
    this.resizeHandler = () => window.innerWidth > screenSizes.tabletL && this.setState({ menuOpen: false });

    window.addEventListener('click', this.closeHandler);
    window.addEventListener('resize', this.resizeHandler);

    this.checkAndFetchUser();
  }

  componentDidUpdate(props) {
    this.checkAndFetchUser(props);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.closeHandler);
    window.removeEventListener('resize', this.resizeHandler);
  }

  checkAndFetchUser = (props = this.props) => {
    const { user, auth, fetchUser } = props;
    if (auth.info && auth.info.username && user.length === 0) {
      fetchUser(auth.info.username);
    }
  };

  render() {
    const { auth, copy, user, locale } = this.props;
    const { menuOpen } = this.state;

    return (
      <Header>
        {menuOpen && (
          <Helmet>
            <body style={BodyNoScroll} />
          </Helmet>
        )}
        <HeaderInner>
          <Link to={`/${locale}`} href={`/${locale}`}>
            <LogoWrapper>
              <Icon src={logoIcon} />
            </LogoWrapper>
          </Link>
          <NavigationWrapper active={menuOpen}>
            <PrimaryLinks>
              {auth.isSignedIn === state.AUTH_SUCCESS &&
                user &&
                typeof user.role !== 'undefined' &&
                isOwner(user.role) && (
                  <PrimaryLink>
                    <Link to={`/${locale}`} href={`/${locale}`}>
                      {copy.courses}
                    </Link>
                  </PrimaryLink>
                )}

              {auth.isSignedIn === state.AUTH_SUCCESS &&
                user &&
                typeof user.role !== 'undefined' &&
                isOwner(user.role) && (
                  <PrimaryLink>
                    <Link to={`/${locale}/progress`} href={`/${locale}/progress`}>
                      {copy.progress || HeaderComponent.defaultProps.copy.progress || null}
                    </Link>
                  </PrimaryLink>
                )}
            </PrimaryLinks>

            {auth.isSignedIn !== state.AUTH_SUCCESS ? (
              <SecondaryLinks>
                <SecondaryLink>
                  <Link to={`/${locale}/login`} href={`${locale}/login`}>
                    {copy.gnav_login}
                  </Link>
                </SecondaryLink>
                <SecondaryLink>
                  <Link to={`/${locale}/signup`} href={`${locale}/signup`}>
                    {copy.about}
                  </Link>
                </SecondaryLink>
              </SecondaryLinks>
            ) : (
              <SecondaryLinks>
                <SecondaryLink>
                  <Link to={`/${locale}/dashboard/settings`} href={`${locale}/dashboard/settings`}>
                    {copy.settings}
                  </Link>
                </SecondaryLink>
                <SecondaryLink>
                  <Link to={`/${locale}/logout`} href={`/${locale}/logout`}>
                    {copy.logout}
                  </Link>
                </SecondaryLink>
              </SecondaryLinks>
            )}
          </NavigationWrapper>
          <MenuWrapper onClick={() => this.setState({ menuOpen: !menuOpen })} innerRef={this.buttonRef}>
            <Icon src={menuOpen ? closeButtonIcon : menuIcon} />
          </MenuWrapper>
        </HeaderInner>
      </Header>
    );
  }
}
