import { Link } from 'react-router-dom';
import React from 'react';
import Placeholder from '../Placeholder';

import {
  BadgeImage,
  Card,
  CardContent,
  CTA,
  InfoLabel,
  PlaceholderWrapper,
  Thumbnail,
  ThumbnailWrapper,
  TopLabel,
  TitleContainer,
  ModuleSubtitle,
  ModuleTitle,
  Wrapper,
} from './ModuleCard.style';

const ModuleCard = ({ content, copy, onClickDownload }) => {
  let isSection = false;
  if (content.contentType === 'eLearningSection') {
    isSection = true;
  }

  if (!content.title) {
    return (
      <PlaceholderWrapper>
        <Placeholder w="100%" h="350px" />
      </PlaceholderWrapper>
    );
  }

  const starMap = {
    'Basic': '★☆☆',
    'Advanced': '★★☆',
    'X': '★★★',
  };

  return (
    <Wrapper>
      <Card>
        <Link
          to={{
            pathname: content.link,
          }}
        >
          <ThumbnailWrapper>
            <Thumbnail url={content.thumbnailUrl ? content.thumbnailUrl : '/images/shiseido-professional.png'} />
          </ThumbnailWrapper>
          <CardContent>
            <TopLabel>
              <span className="module"><span>{content.category}</span></span>
              {content.level && (
                <React.Fragment>
                  <span className="level">{starMap[content.level]}</span>
                  <span className="sub">{`(${content.level})`}</span>
                </React.Fragment>
              )}
            </TopLabel>
            <BadgeImage src={content.badgeImage} />
            <TitleContainer>
              {content.contents && (
                <ModuleSubtitle>
                  {content.contents}
                </ModuleSubtitle>
              )}
              <ModuleTitle>{content.title}</ModuleTitle>
            </TitleContainer>
            <InfoLabel>
              {content.infoList.map((info, i) => (<React.Fragment>{info}{(i < content.infoList.length - 1) && <span>|</span>}</React.Fragment>))}
            </InfoLabel>
          </CardContent>
        </Link>
      </Card>
      {isSection &&
        <CTA disabled={!content.isCompleted} onClick={() => {if (content.isCompleted) { onClickDownload(content); }}}>{copy.download_certificate}</CTA>}
    </Wrapper>
  );
};

ModuleCard.defaultProps = {
  progress: null,
  percentage: null,
  copy: {
    chapters: 'chapters',
    minutes: 'mins',
    learn: 'learn',
    resume: 'resume',
  },
};

export default ModuleCard;
