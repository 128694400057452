import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import { state } from '../../../redux/sagas/aws-cognito-redux-saga';

// import validator from 'validator';

import ChangePasswordForm from './ChangePasswordForm';
import Confirmation from './Confirmation';

// const required = value => (value ? undefined : 'Required');
// const passwordMatch = (value, values) => values.password !== values.passwordMatch && 'Passwords must match';
// const minLength = value => (value.length >= 8 ? undefined : 'Min Length 8');
// const email = value => (validator.isEmail(value) ? undefined : 'Not Valid Email');

// const style = {
//   paper: { padding: '16px' },
//   layout: {
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//     height: 'calc(100vh - 56px)',
//   },
//   title: {
//     fontSize: '32px',
//     textAlign: 'center',
//   },
//   form: {
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   button: {
//     margin: '16px',
//     width: '80%',
//   },
//   field: {
//     margin: '8px 32px',
//   },
//   error: {
//     width: '80%',
//     color: 'rgb(200,0,0)',
//     margin: '8px',
//     height: '32px',
//   },
// };

class ChangePassword extends Component {
  static propTypes = {
    history: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired,
    auth: PropTypes.object,
    init: PropTypes.func
  };

  UNSAFE_componentWillMount() {
    this.props.init();
  }

  changePassword = values => {
    this.props.changePassword(values.email.toLowerCase(), values.code, values.password);
  };

  signIn = () => {
    const { lang } = this.props.match.params;
    this.props.history.push(`/${lang}/login`);
  };

  render() {
    const { handleSubmit, auth, copy, global_copy } = this.props;
    return auth.hasChangedPassword === state.AUTH_SUCCESS ? (
      <Confirmation handleSubmit={handleSubmit} signIn={this.signIn} copy={copy} global_copy={global_copy} />
    ) : (
      <ChangePasswordForm auth={auth} handleSubmit={handleSubmit} changePassword={this.changePassword} copy={copy} />
    );
  }
}

// Decorate the form component
export default reduxForm({
  form: 'changePassword'
})(ChangePassword);
