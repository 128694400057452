import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { updateIntl } from 'react-intl-redux';
import styled from 'styled-components';

import Header from './components/Header';
import Footer from './components/Footer/Footer';
import PrivateRoute, { OwnerRoute } from './components/User/PrivateRoute';
import { ScrollRoute } from './components/Analytics';
import HomePage from './pages/HomePage';
import QuizPage from './pages/QuizPage';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import QuizResultsPage from './pages/QuizResultsPage';
import Dashboard from './pages/Dashboard/Dashboard';
import UserSettings from './pages/Settings/UserSettings';
import SectionPage from './pages/SectionPage';
import ModulePage from './pages/ModulePage';
import ChapterPage from './pages/ChapterPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import Overlay from './components/Overlay';
import RegistrationModal from './components/RegistrationModal';
import SignUp from './components/User/SignUp';
import SignIn from './components/User/SignIn';
import Logout from './pages/Logout';
import ChangePassword from './components/User/ChangePassword';
import ChangePasswordManual from './pages/Settings/ChangePasswordManual';
import DeleteAccount from './pages/Settings/DeleteAccount';
import ResetPassword from './components/User/ResetPassword';
import Auth from './components/User/Auth';
import TermsPage from './pages/TermsPage/TermsPage';
import FaqPage from './pages/FaqPage/FaqPage';
import ProgressPage from './pages/Progress';

import updateSiteOptions from './redux/actions/siteOptions';
import updateScreenSize from './redux/actions/updateScreenSize';
import { errorLogger, throttle, getScreenSize } from './utils';

import footerData from './__testData__/footerData';

import GlobalScrollRestore from './components/ScrollRestore/GlobalScrollRestore';

import UserAgent from './utils/UserAgent';

import './styles/normalize.css';
import './styles/index.css';
import './styles/font.style';
import './styles/global.style';

const Main = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

class App extends Component {
  UNSAFE_componentWillMount() {
    const { match, updateSiteOptions } = this.props;
    const locale = match.params.lang;

    updateSiteOptions(locale).catch(e => {
      errorLogger(e);
      this.props.history.push(`/${locale}/error`);
    });

    window.addEventListener('resize', this.handleScreenResize);
    this.handleScreenResize();
  }

  handleScreenResize = throttle(() => {
    const { screenSize, updateScreenSize } = this.props;
    const newScreenSize = getScreenSize();
    if (newScreenSize !== screenSize) updateScreenSize(newScreenSize);
  });

  render() {
    const { match } = this.props;
    const localeMatch = match.path;
    const locale = match.params.lang;
    const isApp = UserAgent.isApp();

    return (
      <Router>
        <GlobalScrollRestore>
          <Route path="/" component={ScrollRoute} />
          <div className="app">
            <Helmet>
              <meta property="og:locale" content={window.location.hostname.split('.').slice(-1)[0]} />
            </Helmet>
            <Main data-layout={ isApp ? 'app' : 'web' }>
              <Auth />
              {!isApp ? <Header locale={locale} /> : ''}
              <Switch>
                <Route path={localeMatch} exact component={HomePage} locale={locale} />
                <PrivateRoute path={`${localeMatch}/dashboard`} exact component={Dashboard} locale={locale} />
                <PrivateRoute path={`${localeMatch}/logout`} exact component={Logout} />
                <PrivateRoute
                  path={`${localeMatch}/dashboard/settings`}
                  exact
                  component={UserSettings}
                  locale={locale}
                />
                <PrivateRoute
                  path={`${localeMatch}/dashboard/settings/changepassword`}
                  exact
                  component={ChangePasswordManual}
                  locale={locale}
                />
                <PrivateRoute
                  path={`${localeMatch}/dashboard/settings/delete-account`}
                  exact
                  component={DeleteAccount}
                  locale={locale}
                />
                <Route path={`${localeMatch}/login`} exact component={HomePage} />
                <Route path={`${localeMatch}/signup`} exact component={HomePage} />
                <Route path={`${localeMatch}/resetpassword`} exact component={HomePage} />
                <Route path={`${localeMatch}/changepassword`} component={ChangePassword} locale={locale} />
                <Route exact path={`${localeMatch}/course/:section`} component={SectionPage} locale={locale} />
                <Route exact path={`${localeMatch}/course/:section/:module`} component={ModulePage} locale={locale} />
                <Route path={`${localeMatch}/course/:section/:module/quiz`} exact component={QuizPage} locale={locale} />
                <PrivateRoute
                  path={`${localeMatch}/course/:section/:module/results`}
                  exact
                  component={QuizResultsPage}
                  locale={locale}
                />
                <Route path={`${localeMatch}/course/:section/:module/:chapter`} component={ChapterPage} locale={locale} />
                <Route path={`${localeMatch}/terms-of-use`} component={TermsPage} locale={locale} />
                <Route path={`${localeMatch}/faq`} component={FaqPage} locale={locale} />

                <OwnerRoute path={`${localeMatch}/progress`} component={ProgressPage} locale={locale} />

                <Route exact path="/" render={props => <Redirect to={{ pathname: `/${locale || 'en'}` }} />} />
                <Route path={`${localeMatch}/error`} component={ErrorPage} />
                <Route component={NotFoundPage} />
              </Switch>
              <div style={{ flexGrow: 1 }} />
              {!isApp ? <Footer {...footerData} locale={locale} /> : ''}
            </Main>

            <Route
              path={`${localeMatch}/resetpassword`}
              exact
              component={props => (
                <Overlay>
                  <RegistrationModal {...props}>
                    <ResetPassword {...props} />
                  </RegistrationModal>
                </Overlay>
              )}
            />
            <Route
              path={`${localeMatch}/login`}
              exact
              component={props => (
                <Overlay>
                  <RegistrationModal {...props}>
                    <SignIn {...props} />
                  </RegistrationModal>
                </Overlay>
              )}
            />
            <Route
              path={`${localeMatch}/signup`}
              exact
              component={props => (
                <Overlay>
                  <RegistrationModal {...props}>
                    <SignUp {...props} />
                  </RegistrationModal>
                </Overlay>
              )}
            />
          </div>
        </GlobalScrollRestore>
      </Router>
    );
  }
}

App.propTypes = {
  // auth: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

const mapState = state => ({
  intl: state.intl,
  locale: state.locale,
  screenSize: state.screenSize,
});

const mapDispatch = dispatch => ({
  updateIntl: intl => dispatch(updateIntl(intl)),
  updateSiteOptions: locale => dispatch(updateSiteOptions(locale)),
  updateScreenSize: screenSize => dispatch(updateScreenSize(screenSize)),
  updateLocale: locale => dispatch({ type: 'UPDATE_LOCALE', locale }),
});

export default connect(mapState, mapDispatch)(App);
