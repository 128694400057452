import UserActionTypes from './types';

export const snsAuthInitialize = () => ({
  type: UserActionTypes.SNS_AUTH_INITIALIZE,
});

export const snsSignUpPrepare = () => ({
  type: UserActionTypes.SNS_SIGN_UP_PREPARE,
});

export const snsLoginRequest = (provider, locale) => ({
  type: UserActionTypes.SNS_LOGIN_REQUEST,
  payload: { provider, locale },
});

export const snsLoginStarted = ({ provider, locale }) => ({
  type: UserActionTypes.SNS_LOGIN_STARTED,
  payload: { provider, locale },
});
export const snsLoginUnlinkLogoutRequest = () => ({
  type: UserActionTypes.SNS_LOGIN_UNLINK_LOGOUT_REQUEST,
});

export const snsLoginUnlinkLogoutStarted = () => ({
  type: UserActionTypes.SNS_LOGIN_UNLINK_LOGOUT_STARTED,
});

export const snsLoginSuccess = () => ({
  type: UserActionTypes.SNS_LOGIN_SUCCESS,
});
export const snsLoginSuccessReload = () => ({
  type: UserActionTypes.SNS_LOGIN_SUCCESS_RELOAD,
});

export const snsLoginSuccessReloading = () => ({
  type: UserActionTypes.SNS_LOGIN_SUCCESS_RELOADING,
});

export const snsLoginFail = error => ({
  type: UserActionTypes.SNS_LOGIN_FAIL,
  payload: error,
});

export const snsLogoutRequest = locale => ({
  type: UserActionTypes.SNS_LOGOUT_REQUEST,
  payload: locale,
});

export const snsLogoutStarted = provider => ({
  type: UserActionTypes.SNS_LOGOUT_STARTED,
  payload: provider,
});

export const snsLogoutSuccess = () => ({
  type: UserActionTypes.SNS_LOGOUT_SUCCESS,
});

export const snsLogoutFail = error => ({
  type: UserActionTypes.SNS_LOGOUT_FAIL,
  payload: error,
});

// Settings SNS連携
export const snsSignUpRequest = data => ({
  type: UserActionTypes.SNS_SIGN_UP_REQUEST,
  payload: data,
});

export const snsSignUpStarted = data => ({
  type: UserActionTypes.SNS_SIGN_UP_STARTED,
  payload: data,
});

export const snsSignUpTempSignInFail = error => ({
  type: UserActionTypes.SNS_SIGN_UP_TEMP_SIGN_IN_FAIL,
  payload: error,
});

export const snsSignUpTempSignedIn = snsUser => ({
  type: UserActionTypes.SNS_SIGN_UP_TEMP_SIGNED_IN,
  payload: snsUser,
});

export const snsSignUpTempSignOutRequest = () => ({
  type: UserActionTypes.SNS_SIGN_UP_TEMP_SIGN_OUT_REQUEST,
});

export const snsSignUpTempSignOutStarted = () => ({
  type: UserActionTypes.SNS_SIGN_UP_TEMP_SIGN_OUT_STARTED,
});

export const snsSignUpTempSignedOut = () => ({
  type: UserActionTypes.SNS_SIGN_UP_TEMP_SIGNED_OUT,
});

export const snsSignUpMasterRequest = data => ({
  type: UserActionTypes.SNS_SIGN_UP_MASTER_REQUEST,
  payload: data,
});

export const snsSignUpMasterStarted = () => ({
  type: UserActionTypes.SNS_SIGN_UP_MASTER_STARTED,
});

export const snsSignUpSuccess = () => ({
  type: UserActionTypes.SNS_SIGN_UP_SUCCESS,
});

export const snsSignUpFail = error => ({
  type: UserActionTypes.SNS_SIGN_UP_FAIL,
  payload: error,
});

export const snsLinkRequest = providerAndMasterUser => ({
  type: UserActionTypes.SNS_LINK_REQUEST,
  payload: providerAndMasterUser,
});

export const snsLinkDisableStarted = () => ({
  type: UserActionTypes.SNS_LINK_DISABLE_STARTED,
});

export const snsLinkDisabled = () => ({
  type: UserActionTypes.SNS_LINK_DISABLED,
});

export const snsLinkDisableFail = error => ({
  type: UserActionTypes.SNS_LINK_DISABLE_FAIL,
  payload: error,
});

export const snsLinkTempSignInStarted = providerAndMasterUser => ({
  type: UserActionTypes.SNS_LINK_TEMP_SIGN_IN_STARTED,
  payload: providerAndMasterUser,
});

export const snsLinkTempSignedIn = tempSnsUser => ({
  type: UserActionTypes.SNS_LINK_TEMP_SIGNED_IN,
  payload: tempSnsUser,
});

export const snsLinkTempSignInFail = error => ({
  type: UserActionTypes.SNS_LINK_TEMP_SIGN_IN_FAIL,
  payload: error,
});

export const snsLinkTempSignOutRequest = () => ({
  type: UserActionTypes.SNS_LINK_TEMP_SIGN_OUT_REQUEST,
});

export const snsLinkTempSignOutStarted = () => ({
  type: UserActionTypes.SNS_LINK_TEMP_SIGN_OUT_STARTED,
});
export const snsLinkTempSignedOut = () => ({
  type: UserActionTypes.SNS_LINK_TEMP_SIGNED_OUT,
});

export const snsLinkTempSignOutFail = error => ({
  type: UserActionTypes.SNS_LINK_TEMP_SIGN_OUT_FAIL,
  payload: error,
});

export const snsLinkCreateRequest = data => ({
  type: UserActionTypes.SNS_LINK_CREATE_REQUEST,
  payload: data,
});

export const snsLinkCreateStarted = () => ({
  type: UserActionTypes.SNS_LINK_CREATE_STARTED,
});

export const snsLinkMasterSignedIn = () => ({
  type: UserActionTypes.SNS_LINK_MASTER_SIGNED_IN,
});

export const snsLinkMasterSignInFail = error => ({
  type: UserActionTypes.SNS_LINK_MASTER_SIGN_IN_FAIL,
  payload: error,
});

export const snsLinkSuccess = () => ({
  type: UserActionTypes.SNS_LINK_SUCCESS,
});

export const snsLinkCreateFail = error => ({
  type: UserActionTypes.SNS_LINK_CREATE_FAIL,
  payload: error,
});

export const snsLinkOffRequest = provider => ({
  type: UserActionTypes.SNS_LINK_OFF_REQUEST,
  payload: provider,
});

export const snsLinkOffStarted = () => ({
  type: UserActionTypes.SNS_LINK_OFF_STARTED,
});

export const snsLinkOffSuccess = () => ({
  type: UserActionTypes.SNS_LINK_OFF_SUCCESS,
});

export const snsLinkOffFail = error => ({
  type: UserActionTypes.SNS_LINK_OFF_FAIL,
  payload: error,
});
