import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import Markdown from '../../components/Markdown';
import fetchTerms from '../../redux/actions/terms';
import { errorLogger } from '../../utils';
import { SCREEN_LOCKUP_WIDTH, MOBILE_PADDING } from '../../styles/variables.style';
import media from '../../styles/media.style';

const Wrapper = styled.div`
  margin: 75px auto;
  max-width: ${SCREEN_LOCKUP_WIDTH + MOBILE_PADDING * 2}px;
  padding: 0 ${MOBILE_PADDING}px;
`;

const MarkdownStyle = styled.div`
  word-break: break-all;

  p {
    font-size: 14px;
    margin: 0 0 20px;

    ${media.tabletL`
      font-size: 19px;
      margin: 0 0 40px;
    `};
  }

  h1 {
    font-size: 36px;
    font-weight: normal;
    margin: 0 0 40px;

    ${media.tabletL`
      font-size: 42px;
      margin: 0 0 60px;
    `};
  }

  h2 {
    font-size: 24px;
    font-weight: normal;
    margin: 0 0 40px;

    ${media.tabletL`
      font-size: 28px;
      margin: 0 0 60px;
    `};
  }

  h3 {
    font-size: 20px;
    font-weight: normal;
    line-height: 1.5;
    margin: 0 0 40px;

    ${media.tabletL`
      font-size: 24px;
      margin: 0 0 60px;
    `};
  }

  h4 {
    font-size: 14px;
    font-weight: bold;
    margin: 60px 0 20px;

    ${media.tabletL`
      font-size: 19px;
      margin: 80px 0 40px;
    `};
  }

  h5 {
    font-size: 16px;
    margin: 0 0 20px;
    ${media.tabletL`
      margin: 0 0 40px;
    `};
  }

  h6 {
    font-size: 14px;
    margin: 0 0 20px;
    ${media.tabletL`
      margin: 0 0 40px;
    `};
  }

  ol,
  ul {
    padding-left: 1em;
    margin: 0 0 60px;
    ${media.tabletL`
      margin: 0 0 80px;
    `};
  }

  li {
    font-size: 14px;
    line-height: 1.5;
    margin: 0 0 20px;

    ${media.tabletL`
      font-size: 19px;
      margin: 0 0 40px;
    `};
  }

  hr {
    margin: 60px 0;
    ${media.tabletL`
      margin: 0 0 80px;
    `};
  }

  a {
    text-decoration: underline;
  }

  .center {
    text-align: left;
    ${media.tabletL`
      text-align: center;
    `};
  }
`;

const Date = styled.p`
  color: #ccc;
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: 0.1px;
  margin: 80px 0 0 0;
  text-align: right;
`;

class TermsPage extends React.Component {
  static defaultProps = {
    terms: {},
  };

  static propTypes = {
    terms: PropTypes.shape(),
    locale: PropTypes.string.isRequired,
    fetchTerms: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
  };

  componentDidMount() {
    const {
      terms,
      fetchTerms,
      location: { pathname },
    } = this.props;
    let { locale } = this.props;
    if (!locale) {
      locale = pathname.split('/')[1];
    }
    if (!terms.content) {
      fetchTerms(locale).catch(e => {
        errorLogger(e);
        this.props.history.push(`/${locale}/error`);
      });
    }
  }

  render() {
    const { terms } = this.props;

    return (
      <Wrapper>
        {terms.content ? <Markdown Component={MarkdownStyle} src={terms.content} /> : <p>Loading...</p>}
        {terms.date && <Date>{`${terms.copy.date}: ${terms.date}`}</Date>}
      </Wrapper>
    );
  }
}

const mapState = state => ({
  terms: state.terms,
});

const mapDispatch = dispatch => ({
  fetchTerms: locale => dispatch(fetchTerms(locale)),
});

export default connect(mapState, mapDispatch)(TermsPage);
