import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { matchPath } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import leftArrow from '../../assets/images/left-arrow.svg';
import rightArrow from '../../assets/images/right-arrow.svg';

import YoutubeVideoPlayer from '../../components/Video/YoutubeVideo';

import { fetchUser } from '../../redux/actions/user';
import fetchModules from '../../redux/actions/modules';
import { fetchUserProgress, updateUserProgress } from '../../redux/actions/userProgress';
import { updateAuthRedirect } from '../../redux/actions/authRedirect';
import { reducer } from '../../redux/sagas/aws-cognito-redux-saga';
import { state } from '../../redux/sagas/aws-cognito-redux-saga';
import Loading from '../../components/Loading';
import { MOBILE_PADDING, SCREEN_LOCKUP_WIDTH } from '../../styles/variables.style';
import media from '../../styles/media.style';

const Wrapper = styled.div`
  background-color: #fff;
`;

const HeroVideo = styled.div`
  height: 180px;
  background: #000;

  ${media.tabletL`
    height: 596px;
  `};
`;

const ChapterStatus = styled.h2`
  font-size: 16px;
  font-weight: 400;
  padding: 0 ${MOBILE_PADDING}px;
  margin: 0;

  ${media.tabletL`
    padding: 0;
    font-size: 38px;
  `};
`;

const VideoWrapper = styled.div`
  width: 100%;
  max-width: ${SCREEN_LOCKUP_WIDTH}px;
  height: 180px;
  overflow: hidden;
  margin: 0 auto;

  ${media.tabletL`
    height: 596px;
  `};
`;

const ChapterContent = styled.div`
  width: 100%;
  max-width: ${SCREEN_LOCKUP_WIDTH}px;
  margin: 0px auto 40px auto;
`;

const ChapterTitle = styled.h1`
  font-size: 16px;
  font-weight: bold;
  padding: 0 ${MOBILE_PADDING}px;
  margin: 0;
  text-transform: uppercase;
  ${props => props.positionEmpty && 'padding-top: 31px;'};
  ${media.tabletL`
    padding: 0;
    font-size: 38px;
    ${props => props.positionEmpty && 'padding-top: 47px;'};
  `};
`;

const ChapterDescription = styled.div`
  margin-top: 40px;
  padding: 0 ${MOBILE_PADDING}px;
  p {
    font-size: 14px;
    line-height: 1.39;
    letter-spacing: 0.1px;

    ${media.tabletL`
      font-size: 18px;
    `};
  }

  ${media.tabletL`
    padding: 0;
  `};
`;

const ChapterNavigation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  background: #000;
  height: 35px;
  margin-bottom: 60px;

  ${media.tabletL`
    height: 60px;
  `};
`;

const ChapterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    display: block;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-decoration: none;

    ${media.tabletL`
      height: 60px;
      width: 198px;
    `};
  }

  span {
    display: block;
    width: 8px;
    height: 13px;
  }
  :first-child {
    border-right: 1px solid white;
  }
  :last-child {
    border-left: 1px solid white;
  }
`;

const ChaptersCopyText = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  color: #fff;
`;

const Checkmark = styled.span`
  display: inline-block;
  position: relative;
  width: 25px;
  height: 25px;
  border: 1px solid #000;
  margin-right: 12px;
  border-radius: 50%;
  background: #000;

  ::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%) rotate(-45deg);
    border-bottom: 1px solid #fff;
    border-left: 1px solid #fff;
    width: 8px;
    height: 4px;
  }
`;

class ChapterPage extends Component {
  static defaultProps = {
    modules: [],
    currentModule: [],
    copy: {
      lesson: 'Lesson',
    },
  };

  constructor() {
    super();
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  UNSAFE_componentWillMount = () => {
    const { modules, fetchModules, match } = this.props;
    console.log('match1', match);
    if (modules.length === 0) {
      fetchModules({ limit: 15, order: 'fields.moduleTitle' }, match.params.lang);
    }
  };

  componentDidMount() {
    const { auth, user, fetchUser, fetchUserProgress } = this.props;
    if (auth.info && auth.info.username && !user.id) {
      fetchUser(auth.info.username);
      fetchUserProgress(auth.info.username);
    }

    this.checkIfHaveAccessRight();
    this.updatePlayerEvents();
  }

  componentDidUpdate() {
    this.checkIfHaveAccessRight();
    this.updatePlayerEvents();
  }

  checkIfHaveAccessRight = () => {
    const { auth, getUser, modules, history, updateAuthRedirect } = this.props;
    const match = matchPath(history.location.pathname, {
      path: '/:locale/course/:section/:module/:chapter',
      exact: false,
      strict: false,
    });
    const { locale } = match.params;

    if (!modules.length) return;
    const chapter = this.getChapter();
    if (auth.isSignedIn === state.AUTH_UNKNOWN) {
      getUser();
    } else if (auth.isSignedIn === state.AUTH_FAIL && chapter.memberflag !== false) {
      updateAuthRedirect(history.location.pathname);
      history.push(`/${locale}/login`);
    }
  };

  componentWillReceiveProps(nextProps) {
    const { auth, user, fetchUser, fetchUserProgress } = nextProps;

    if (auth.info && auth.info.username && !user.id) {
      fetchUser(auth.info.username);
      fetchUserProgress(auth.info.username);
    }
  }

  getChapter = () => {
    const { modules } = this.props;
    const match = matchPath(this.props.history.location.pathname, {
      path: '/:locale/course/:section/:module/:chapter',
      exact: false,
      strict: false,
    });

    const currentModule = modules.find(item => item.moduleSlug === match.params.module);

    return currentModule.moduleChapters.find(c => c.chapterNumber === parseInt(match.params.chapter, 10));
  };

  handleUpdate = event => {
    // event.stopPropagation();
    const { userProgress, match, updateUserProgress, auth } = this.props;
    const { module, chapter } = match.params;

    if (auth.isSignedIn === state.AUTH_UNKNOWN || auth.isSignedIn === state.AUTH_FAIL) {
      return;
    }

    const currentChapter = this.getChapter();
    if (currentChapter.memberflag === false) return;

    const chapterNumber = parseInt(chapter, 10);
    let userProgressData = userProgress.data;
    let obj = JSON.parse(userProgressData);
    let progressArray = obj.chapters.find(i => i.name === module);

    if (!progressArray) {
      obj.chapters.push({ name: module, completed: [chapterNumber] });
    } else if (progressArray.completed.indexOf(chapterNumber) === -1) {
      progressArray.completed.push(chapterNumber);
    }
    progressArray = obj.chapters.find(i => i.name === module);

    userProgressData = JSON.stringify(obj);
    updateUserProgress(auth.info.username, userProgressData);
  };

  updatePlayerEvents = () => {
    let el = document.getElementsByTagName('video')[0];

    if (el) {
      el.removeEventListener('ended', this.handleUpdate);
      el.addEventListener('ended', this.handleUpdate);
    }
  };

  render() {
    const { modules, copy, userProgress } = this.props;

    const match = matchPath(this.props.history.location.pathname, {
      path: '/:locale/course/:section/:module/:chapter',
      exact: false,
      strict: false,
    });

    const currentModule = modules.find(item => item.moduleSlug === match.params.module);

    if (!currentModule) {
      return <Loading />;
    }

    const chapters = currentModule.moduleChapters;
    const location = this.props.location.pathname;
    const urlPath = location.substring(0, location.lastIndexOf('/'));

    const chapter = chapters.find(c => c.chapterNumber === parseInt(match.params.chapter, 10));
    const currentChapterIndex = chapters.findIndex(c => c.chapterNumber === chapter.chapterNumber);
    const totalChapters = chapters.length;
    const totalMemberOnlyChapters = chapters.filter(c => c.memberflag !== false).length;

    const isFirstChapter = currentChapterIndex === 0;
    const previousButton = (
      <ChapterButton>
        <Link to={urlPath + (isFirstChapter ? '' : '/' + chapters[currentChapterIndex - 1].chapterNumber)}>
          <span style={{ background: `url(${leftArrow})` }} />
        </Link>
      </ChapterButton>
    );

    const isLastChapter = currentChapterIndex === totalChapters - 1;
    const nextButton = (
      <ChapterButton>
        <Link to={urlPath + (isLastChapter ? '/quiz' : '/' + chapters[currentChapterIndex + 1].chapterNumber)}>
          {isLastChapter ? copy.start_quiz : <span style={{ background: `url(${rightArrow})` }} />}
        </Link>
      </ChapterButton>
    );

    let userProgressData = userProgress.data;
    let obj = userProgressData ? JSON.parse(userProgressData) : { chapters: [] };

    const currentModuleProgress = obj.chapters.find(item => item.name === currentModule.moduleSlug);
    let completedModules = [];
    if (currentModuleProgress) {
      completedModules = currentModuleProgress.completed;
    }

    let isCompleted = false;
    if (completedModules.includes(chapter.chapterNumber)) {
      isCompleted = true;
    }

    return chapter ? (
      <Wrapper>
        <HeroVideo>
          <VideoWrapper>
            {chapter.memberflag !== false ? (
              <YoutubeVideoPlayer videoId={chapter.youtubeVideoId} />
            ) : (
              <YoutubeVideoPlayer videoId={chapter.youtubeVideo} onEnded={this.handleUpdate} />
            )}
          </VideoWrapper>
        </HeroVideo>

        <ChapterContent>
          <ChapterNavigation>
            {previousButton}
            <ChaptersCopyText />
            {nextButton}
          </ChapterNavigation>
          {chapter.memberflag !== false && (
            <ChapterStatus>
              {copy.lesson.toUpperCase()} {chapter.chapterNumber}/{totalMemberOnlyChapters} {isCompleted ? <Checkmark /> : null}
            </ChapterStatus>
          )}
          <ChapterTitle {...chapter.memberflag === false && { positionEmpty: true }}>
            {chapter.chapterTitle}
          </ChapterTitle>
          <ChapterDescription>
            <p>{chapter.chapterDescription}</p>
          </ChapterDescription>
        </ChapterContent>
      </Wrapper>
    ) : (
      <Loading />
    );
  }
}

const mapState = ({ modules, userProgress, user, auth, siteOptions }) => ({
  modules,
  userProgress,
  user,
  auth,
  copy: siteOptions.siteCopyELearningCoursePage,
});
const mapDispatch = dispatch => ({
  getUser: () => dispatch(reducer.getUser()),
  fetchModules: (options, locale) => dispatch(fetchModules(options, locale)),
  fetchUser: username => dispatch(fetchUser(username)),
  fetchUserProgress: username => dispatch(fetchUserProgress(username)),
  updateAuthRedirect: path => dispatch(updateAuthRedirect(path)),
  updateUserProgress: (userId, userProgress) => dispatch(updateUserProgress(userId, userProgress)),
});

export default withRouter(connect(mapState, mapDispatch)(ChapterPage));
