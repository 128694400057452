import React from 'react';
import PropTypes from 'prop-types';

import { Container, Title, Button } from './Confirmation.style';

const Confirmation = ({ handleSubmit, signIn, copy, global_copy }) => (
  <Container>
    <Title>{copy.password_changed_message}</Title>
    <Button onClick={handleSubmit(signIn)}>{global_copy.login}</Button>
  </Container>
);

Confirmation.defaultProps = {
  copy: {
    password_changed_message: 'Password changed successfully!',
  },
  global_copy: {
    login: 'Login',
  },
};

Confirmation.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
};

export default Confirmation;
