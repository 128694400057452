import { connect } from 'react-redux';

import { reducer } from '../../redux/sagas/aws-cognito-redux-saga';

import fetchSections from '../../redux/actions/sections';
import { updateAuthRedirect } from '../../redux/actions/authRedirect';

import HomePage from './HomePage';

const mapStatetoProps = ({ auth, authRedirect, sections, siteOptions }) => ({
  auth,
  authRedirect,
  sections,
  siteOptions,
});

const mapDispatchToProps = dispatch => ({
  signedIn: () => {
    dispatch(reducer.signedIn());
  },
  signOut: () => {
    dispatch(reducer.signOut());
  },
  signIn: (username, password) => {
    dispatch(reducer.signIn(username, password));
  },
  fetchSections: (options, locale) => dispatch(fetchSections(options, locale)),
  updateAuthRedirect: path => dispatch(updateAuthRedirect(path)),
});

export default connect(mapStatetoProps, mapDispatchToProps)(HomePage);
