import { GET_USER_PROGRESS, UPDATE_USER_PROGRESS, DO_NOTHING } from '../constants';
import { fetchUserProgressService, updateUserProgressService } from '../../services/api';

export const fetchUserProgressCreator = () => {
  const api = fetchUserProgressService;
  const actionName = GET_USER_PROGRESS;
  const hash = {};
  const timeout = 5000;
  const hasCacheExpired = (username) => (hash[username] && Date.now() -hash[username] > timeout)
  return function fetchUserProgress(username) {
    if(!hash[username] || hasCacheExpired(username)) {
      hash[username] = Date.now();
      return {
        type: actionName,
        async payload() {
          const data = await api(username);
          return data;
        },
      };
    } else {
      console.log('DO_NOTHING UserProgress')
      return {
        type: DO_NOTHING
      };
    }
  }
};
export const fetchUserProgress = fetchUserProgressCreator();

export const updateUserProgress = (userId, userProgress) => ({
  type: UPDATE_USER_PROGRESS,
  async payload() {
    const data = await updateUserProgressService(userId, userProgress);
    return data;
  },
});
