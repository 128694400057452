import styled from 'styled-components';

import media from '../../styles/media.style';
import { PAGE_WIDTH } from '../../styles/variables.style';

export const Container = styled.footer`
  height: 400px;
`;

export const Wrapper = styled.div`
  color: white;
  background-color: black;
  bottom: 0;
  height: 400px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  width: 100%;
`;

export const Inner = styled.div`
  margin: auto;
  max-width: ${PAGE_WIDTH}px;
  padding: 30px 25px;

  ${media.tabletL`
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    padding: 50px 40px;
  `};

  ${media.desktop`
    padding: 90px 80px;
  `};
`;

export const Logo = styled.img`
  cursor: pointer;
  display: none;

  ${media.tabletL`
    display: block;
  `};
`;

export const PrimaryLinks = styled.div``;

export const PrimaryLink = styled.div`
  font-family: FuturaStd;
  font-size: 10px;
  letter-spacing: 0.4px;
  line-height: 1.5;
  margin-top: 15px;
  text-decoration: underline;
  text-transform: uppercase;

  ${media.tabletL`
    line-height: 2.5;
  `};

  :first-child {
    margin-top: 0;
  }
`;

export const Extras = styled.div`
  margin-top: 40px;

  ${media.tabletL`
    margin-top: 0;
    width: 50%;
  `};
`;

export const IconLinks = styled.div`
  align-items: center;
  border-bottom: 1px solid #4b4b4b;
  display: flex;
  padding-bottom: 15px;

  ${media.tabletL`
    padding-bottom: 35px;
  `};
`;

export const SocialIcons = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;

  a {
    align-items: center;
    display: flex;
  }

  a,
  span {
    margin-right: 10px;

    ${media.tabletL`
      margin-right: 15px;
    `};

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Misc = styled.div`
  margin-top: 12px;

  ${media.desktop`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
  `};
`;

export const SecondaryLinks = styled.div`
  display: flex;
`;

export const SecondaryLink = styled.div`
  font-family: FuturaStd;
  font-size: 10px;
  letter-spacing: 0.4px;
  line-height: 2;
  margin-left: 20px;
  text-decoration: underline;
  text-transform: uppercase;

  :first-child {
    margin-left: 0;
  }
`;

export const Copyright = styled.p`
  color: #cccccc;
  font-size: 9px;
  line-height: 1;
  letter-spacing: 0.3px;

  ${media.tabletL`
    font-size: 11px;
    line-height: 2.27;
    letter-spacing: 0.4px;
    margin: 15px 0 0;
  `};

  ${media.desktop`
    margin: 0;
  `};
`;
