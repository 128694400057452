import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import fetchSections from '../../redux/actions/sections';
import { fetchUser, fetchUserAvatar } from '../../redux/actions/user';
import { fetchUserProgress, updateUserProgress } from '../../redux/actions/userProgress';
import { MODULE_BG_COLOR } from '../../styles/variables.style';
import { createCertificateService, getCertificateService } from '../../services/api';
import { checkIfSameCertificate, makeCertificateData } from '../../utils';
import UserAgent from '../../utils/UserAgent';

import { snsLoginSuccessReloading, snsLoginSuccess } from '../../redux/sagas/sns-auth-redux-saga/actions';
import { state } from '../../redux/sagas/aws-cognito-redux-saga';
import { SpinnerOverlay, SpinnerContainer } from '../../components/with-spinner/with-spinner.styles'; // eslint-disable-line

import ModuleList from './ModuleList';
import Hero from './Hero';

const Wrapper = styled.div`
  background-color: ${MODULE_BG_COLOR};
`;

class HomePage extends Component {
  static defaultProps = {
    sections: [],
  };

  state = {
    searchText: '',
    courseFilters: ['All'],
    categoryFilters: ['All'],
    levelFilters: ['All'],
    isFilterMenuOpen: false,
  };

  UNSAFE_componentWillMount() {
    const {
      fetchSections,
      match,
      user,
      auth,
      fetchUser,
      fetchUserProgress,
      fetchUserAvatar,
      userAvatar,
      sections,
    } = this.props;

    if (!sections.length) {
      fetchSections({ order: '-fields.date', moduleOrder: 'fields.moduleTitle' }, match.params.lang);
    }

    if (auth.info && auth.info.username && !user.userId) {
      fetchUser(auth.info.username);
      fetchUserProgress(auth.info.username);
    }

    if (user.avatar && !userAvatar.data) {
      fetchUserAvatar(user.avatar);
    }
  }

  componentWillReceiveProps(prevProps) {
    const { user, auth, fetchUser, fetchUserProgress, fetchUserAvatar, userAvatar } = prevProps;

    if (auth.info && auth.info.username && !user.userId) {
      fetchUser(auth.info.username);
      fetchUserProgress(auth.info.username);
    }

    if (user.avatar && !userAvatar.data) {
      fetchUserAvatar(user.avatar);
    }
  }

  componentDidMount = () => {
    const { sections, match, user, auth, fetchSections, fetchUser, fetchUserProgress, fetchUserAvatar } = this.props;

    if (!sections.length) {
      fetchSections({ order: '-fields.date', moduleOrder: 'fields.moduleTitle' }, match.params.lang);
    }

    if (auth.info && auth.info.username && user.length === 0) {
      fetchUser(auth.info.username);
      fetchUserProgress(auth.info.username);
    }

    if (user.length) {
      fetchUserAvatar(user.avatar);
    }

    if (this.props.snsAuth.authState === 'SNS_LOGIN_SUCCESS_RELOAD') {
      this.props.snsLoginSuccessReloading();
      setTimeout(() => {
        window.location.reload();
      }, 200);
    }

    if (this.props.snsAuth.authState === 'SNS_LOGIN_SUCCESS_RELOADING') {
      this.props.snsLoginSuccess();
    }
  };

  handleFilter = (type, filter) => () => {
    if (type === 'course') {
      this.setState(({ courseFilters }) => ({
        courseFilters: filter === 'All' ? ['All'] : [...new Set(courseFilters.concat(filter))].filter(f => f !== 'All'),
      }));
    } else if (type === 'category') {
      this.setState(({ categoryFilters }) => ({
        categoryFilters: filter === 'All' ? ['All'] : [...new Set(categoryFilters.concat(filter))].filter(f => f !== 'All'),
      }));
    } else if (type === 'level') {
      this.setState(({ levelFilters }) => ({
        levelFilters: filter === 'All' ? ['All'] : [...new Set(levelFilters.concat(filter))].filter(f => f !== 'All'),
      }));
    }
  };

  handleRemoveFilter = (type, filter) => () => {
    if (type === 'course') {
      this.setState(({ courseFilters }) => {
        const newFilters = courseFilters.filter(f => f !== filter);
        return {
          courseFilters: newFilters.length > 0 ? newFilters : ['All'],
        };
      });
    } else if (type === 'category') {
      this.setState(({ categoryFilters }) => {
        const newFilters = categoryFilters.filter(f => f !== filter);
        return {
          categoryFilters: newFilters.length > 0 ? newFilters : ['All'],
        };
      });
    } else if (type === 'level') {
      this.setState(({ levelFilters }) => {
        const newFilters = levelFilters.filter(f => f !== filter);
        return {
          levelFilters: newFilters.length > 0 ? newFilters : ['All'],
        };
      });
    }
  };

  handleToggleFilterOverlay = () => {
    this.setState(({ isFilterMenuOpen }) => ({ isFilterMenuOpen: !isFilterMenuOpen }));
  };

  handleClearFilters = type => {
    this.setState(({ courseFilters, categoryFilters, levelFilters }) => ({
      courseFilters: type === 'all' || type === 'course' ? ['All'] : courseFilters,
      categoryFilters: type === 'all' || type === 'category' ? ['All'] : categoryFilters,
      levelFilters: type === 'all' || type === 'level' ? ['All'] : levelFilters,
      isFilterMenuOpen: false,
    }));
  };

  handleChange = e => {
    const searchText = e.target.value;
    this.setState({ searchText });
  };

  handleKeyUp = e => {
    const { searchText } = this.state;
    if (e.keyCode === 13 && searchText.length > 0) {}
  };

  handleClick = e => {}


  openPDF = url => {
    const isApp = UserAgent.isApp();

    if (isApp && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(url);
      return;
    }

    const link = document.createElement('a');
    link.target = '_blank';
    link.href = url;
    link.click();
  }

  onClickDownload = async section => {
    const { auth, match, user, userProgress, updateUserProgress } = this.props;
    const locale = match.params.lang;

    if (auth.isSignedIn === state.AUTH_UNKNOWN || auth.isSignedIn === state.AUTH_FAIL) {
      return;
    }

    let userProgressData = userProgress.data;
    let progressObj = JSON.parse(userProgressData);
    if (!progressObj.certificates) {
      progressObj.certificates = [];
    }

    const savedCertificate = progressObj.certificates.find(checkIfSameCertificate({ locale, user, section }));
    const certificate = savedCertificate ? await getCertificateService(savedCertificate.key) : await createCertificateService(locale, user, section);

    if (!certificate.success) {
      return;
    }

    if (!savedCertificate) {
      progressObj.certificates.push({ ...makeCertificateData({ locale, user, section }), key: certificate.key });
      userProgressData = JSON.stringify(progressObj);
      updateUserProgress(auth.info.username, userProgressData);
    }

    this.openPDF(certificate.url);
  }

  filteredSections = sections => {
    const { courseFilters, categoryFilters, levelFilters } = this.state;
    return sections.filter(
      section => courseFilters.indexOf('All') >= 0 || courseFilters.indexOf(section.sectionTitle) >= 0
    ).filter(
      section => categoryFilters.indexOf('All') >= 0 || categoryFilters.indexOf(section.category) >= 0
    ).filter(
      section => levelFilters.indexOf('All') >= 0 || levelFilters.indexOf(section.level) >= 0
    );
  }

  searchedSections = sections => {

    const zenkakuToHankaku = str => str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });

    const { searchText } = this.state;
    return sections.filter(section => {
      if (searchText.length < 2) {
        return true;
      }
      section.tags = section.tags || [];
      let searchedTitle = zenkakuToHankaku(section.sectionTitle).toLowerCase();
      let searchedTags = section.tags.map(t => zenkakuToHankaku(t).toLowerCase());

      const keyword = zenkakuToHankaku(searchText).toLowerCase();

      for (let i = 0; i < keyword.length; i++) {
        let isIncludedTitle;
        let isIncludedTags;

        if (searchedTitle.includes(keyword.charAt(i))) {
          searchedTitle = searchedTitle.replace(keyword.charAt(i), '');
          isIncludedTitle = true;
        }

        searchedTags = searchedTags.map(tag => {
          if (tag.includes(keyword.charAt(i))) {
            tag = tag.replace(keyword.charAt(i), '');
            isIncludedTags = true;
          } else {
            tag = '';
          }
          return tag;
        });

        if (!isIncludedTitle && !isIncludedTags) {
          return false;
        }
      }
      return true;
    });
  }


  render() {
    const { authState: snsAuthState } = this.props.snsAuth;
    if (snsAuthState === 'SNS_LOGIN_SUCCESS_RELOAD' || snsAuthState === 'SNS_LOGIN_SUCCESS_RELOADING') {
      return (
        <SpinnerOverlay>
          <SpinnerContainer />
        </SpinnerOverlay>
      );
    }

    const { sections, match, user, userProgress, siteOptions, userAvatar } = this.props;
    const { searchText, courseFilters, categoryFilters, levelFilters, isFilterMenuOpen } = this.state;
    const locale = match.params.lang;

    const availableFilters = {
      course: [...new Set(sections.map(s => s.sectionTitle))],
      category: [...new Set(sections.map(s => s.category))],
      level: [...new Set(sections.map(s => s.level))],
    };

    const filteredSections = this.filteredSections(sections);
    const searchedSections = this.searchedSections(filteredSections);

    let avatar;
    if (userAvatar && userAvatar.data && userAvatar.data.signedUrl) {
      avatar = userAvatar.data.signedUrl;
    }

    return (
      <Wrapper>
        <Hero
          availableFilters={availableFilters}
          categoryFilters={categoryFilters}
          courseFilters={courseFilters}
          lang={locale}
          levelFilters={levelFilters}
          isFilterMenuOpen={isFilterMenuOpen}
          searchText={searchText}
          user={user}
          userAvatar={avatar}
          onToggleFilterOverlay={this.handleToggleFilterOverlay}
          onClearFilters={this.handleClearFilters}
          onFilter={this.handleFilter}
          onRemoveFilter={this.handleRemoveFilter}
          handleChange={this.handleChange}
          handleKeyUp={this.handleKeyUp}
          handleClick={this.handleClick}
          copy={siteOptions.siteCopyELearningDashboardPage}
        />
        <ModuleList
          sections={searchedSections}
          locale={locale}
          progress={userProgress.data}
          copy={siteOptions.siteCopyELearningDashboardPage}
          onClickDownload={this.onClickDownload}
        />
      </Wrapper>
    );
  }
}

const mapState = ({ auth, user, userProgress, sections, siteOptions, userAvatar, snsAuth }) => ({
  auth,
  user,
  userProgress,
  sections,
  siteOptions,
  userAvatar,
  snsAuth,
});
const mapDispatch = dispatch => ({
  fetchSections: (options, locale) => dispatch(fetchSections(options, locale)),
  fetchUser: username => dispatch(fetchUser(username)),
  fetchUserProgress: username => dispatch(fetchUserProgress(username)),
  updateUserProgress: (userId, userProgress) => dispatch(updateUserProgress(userId, userProgress)),
  fetchUserAvatar: key => dispatch(fetchUserAvatar(key)),
  snsLoginSuccessReloading: () => dispatch(snsLoginSuccessReloading()),
  snsLoginSuccess: () => dispatch(snsLoginSuccess()),
});

export default connect(mapState, mapDispatch)(HomePage);
