/* eslint-disable no-extend-native, no-bitwise, prefer-rest-params */

const polyfills = [];

(() => {
  /**
   * Array.from()
   * Production steps of ECMA-262, Edition 6, 22.1.2.1
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/from#Polyfill
   */
  if (!Array.from) {
    polyfills.push('Array.from');
    Array.from = (() => {
      const toStr = Object.prototype.toString;
      const isCallable = fn => typeof fn === 'function' || toStr.call(fn) === '[object Function]';
      const toInteger = value => {
        const number = Number(value);
        if (isNaN(number)) {                                                    // eslint-disable-line
          return 0;
        }
        if (number === 0 || !isFinite(number)) {                                // eslint-disable-line
          return number;
        }
        return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
      };
      const maxSafeInteger = Math.pow(2, 53) - 1;                               // eslint-disable-line
      const toLength = value => {
        const len = toInteger(value);
        return Math.min(Math.max(len, 0), maxSafeInteger);
      };

      // The length property of the from method is 1.
      return function from(arrayLike /* , mapFn, thisArg */) {
        // 1. Let C be the this value.
        const C = this;

        // 2. Let items be ToObject(arrayLike).
        const items = Object(arrayLike);

        // 3. ReturnIfAbrupt(items).
        if (arrayLike == null) {
          throw new TypeError('Array.from requires an array-like object - not null or undefined');
        }

        // 4. If mapfn is undefined, then let mapping be false.
        const mapFn = arguments.length > 1 ? arguments[1] : void undefined;     // eslint-disable-line
        let T;
        if (typeof mapFn !== 'undefined') {
          // 5. else
          // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
          if (!isCallable(mapFn)) {
            throw new TypeError('Array.from: when provided, the second argument must be a function');
          }

          // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
          if (arguments.length > 2) {
            T = arguments[2];                                                   // eslint-disable-line
          }
        }

        // 10. Let lenValue be Get(items, "length").
        // 11. Let len be ToLength(lenValue).
        const len = toLength(items.length);

        // 13. If IsConstructor(C) is true, then
        // 13. a. Let A be the result of calling the [[Construct]] internal method
        // of C with an argument list containing the single item len.
        // 14. a. Else, Let A be ArrayCreate(len).
        const A = isCallable(C) ? Object(new C(len)) : new Array(len);

        // 16. Let k be 0.
        let k = 0;
        // 17. Repeat, while k < len… (also steps a - h)
        let kValue;
        while (k < len) {
          kValue = items[k];
          if (mapFn) {
            A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
          } else {
            A[k] = kValue;
          }
          k += 1;
        }
        // 18. Let putStatus be Put(A, "length", len, true).
        A.length = len;
        // 20. Return A.
        return A;
      };
    })();
  }

  /**
   * Array.prototype.fill()
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/fill#Polyfill
   */
  if (!Array.prototype.fill) {
    polyfills.push('Array.prototype.fill');
    Object.defineProperty(Array.prototype, 'fill', {
      value(value) {
        if (this == null) {
          throw new TypeError('this is null or not defined');
        }
        const O = Object(this);
        const len = O.length >>> 0;
        const start = arguments[1];
        const relativeStart = start >> 0;
        let k = relativeStart < 0 ? Math.max(len + relativeStart, 0) : Math.min(relativeStart, len);
        const end = arguments[2];
        const relativeEnd = end === undefined ? len : end >> 0;
        const final = relativeEnd < 0 ? Math.max(len + relativeEnd, 0) : Math.min(relativeEnd, len);
        while (k < final) {
          O[k] = value;
          k += 1;
        }
        return O;
      },
    });
  }

  /*
   * Array.prototype.find()
   * https://tc39.github.io/ecma262/#sec-array.prototype.find
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/find#Polyfill
   */
  if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
      value(predicate) {
        // 1. Let O be ? ToObject(this value).
        if (this == null) {
          throw new TypeError('"this" is null or not defined');
        }

        const o = Object(this);

        // 2. Let len be ? ToLength(? Get(O, "length")).
        const len = o.length >>> 0;

        // 3. If IsCallable(predicate) is false, throw a TypeError exception.
        if (typeof predicate !== 'function') {
          throw new TypeError('predicate must be a function');
        }

        // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
        const thisArg = arguments[1];

        // 5. Let k be 0.
        let k = 0;

        // 6. Repeat, while k < len
        while (k < len) {
          // a. Let Pk be ! ToString(k).
          // b. Let kValue be ? Get(O, Pk).
          // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
          // d. If testResult is true, return kValue.
          const kValue = o[k];
          if (predicate.call(thisArg, kValue, k, o)) {
            return kValue;
          }
          // e. Increase k by 1.
          k += 1;
        }

        // 7. Return undefined.
        return undefined;
      },
      configurable: true,
      writable: true,
    });
  }

  /**
   * Array.prototype.findIndex()
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/findIndex#Polyfill
   */
  if (!Array.prototype.findIndex) {
    polyfills.push('Array.prototype.findIndex');
    Object.defineProperty(Array.prototype, 'findIndex', {
      value(predicate) {
        if (this == null) {
          throw new TypeError('"this" is null or not defined');
        }
        const o = Object(this);
        const len = o.length >>> 0;
        if (typeof predicate !== 'function') {
          throw new TypeError('predicate must be a function');
        }
        const thisArg = arguments[1];
        let k = 0;
        while (k < len) {
          const kValue = o[k];
          if (predicate.call(thisArg, kValue, k, o)) {
            return k;
          }
          k += 1;
        }
        return -1;
      },
      configurable: true,
      writable: true,
    });
  }

  /*
   * Array.prototype.includes()
   * https://tc39.github.io/ecma262/#sec-array.prototype.includes
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/includes#Polyfill
   */
  if (!Array.prototype.includes) {
    polyfills.push('Array.prototype.includes');
    Object.defineProperty(Array.prototype, 'includes', {
      value(searchElement, fromIndex) {
        if (this == null) {
          throw new TypeError('"this" is null or not defined');
        }

        // 1. Let O be ? ToObject(this value).
        const o = Object(this);

        // 2. Let len be ? ToLength(? Get(O, "length")).
        const len = o.length >>> 0;

        // 3. If len is 0, return false.
        if (len === 0) {
          return false;
        }

        // 4. Let n be ? ToInteger(fromIndex).
        //    (If fromIndex is undefined, this step produces the value 0.)
        const n = fromIndex | 0;

        // 5. If n ≥ 0, then
        //  a. Let k be n.
        // 6. Else n < 0,
        //  a. Let k be len + n.
        //  b. If k < 0, let k be 0.
        let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

        function sameValueZero(x, y) {
          return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
        }

        // 7. Repeat, while k < len
        while (k < len) {
          // a. Let elementK be the result of ? Get(O, ! ToString(k)).
          // b. If SameValueZero(searchElement, elementK) is true, return true.
          if (sameValueZero(o[k], searchElement)) {
            return true;
          }
          // c. Increase k by 1.
          k += 1;
        }

        // 8. Return false
        return false;
      },
    });
  }

  /**
   * Object.values()
   * https://stackoverflow.com/questions/42830257/alternative-version-for-object-values
   */
  if (!Object.values) {
    polyfills.push('Object.values');
    Object.defineProperty(Object, 'values', {
      value: obj => Object.keys(obj).map(key => obj[key]),
      configurable: true,
      writable: true,
    });
  }

  /**
   * String.prototype.includes()
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/includes#Polyfill
   */
  if (!String.prototype.includes) {
    polyfills.push('String.prototype.includes');
    Object.defineProperty(String.prototype, 'includes', {
      value(search, inputStart) {
        const start = typeof inputStart !== 'number' ? inputStart : 0;
        if (search === undefined || start + search.length > this.length) {
          return false;
        }
        return this.indexOf(search, start) !== -1;
      },
    });
  }
})();

export default polyfills;
