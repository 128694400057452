const SnsAuthTypes = {
  SNS_AUTH_INITIALIZE: 'SNS_AUTH_INITIALIZE',
  SNS_AUTH_NA: 'SNS_AUTH_NA',
  // SNS連携
  SNS_SIGN_UP_PREPARE: 'SNS_SIGN_UP_PREPARE',
  SNS_SIGN_UP_REQUEST: 'SNS_SIGN_UP_REQUEST',
  SNS_SIGN_UP_STARTED: 'SNS_SIGN_UP_STARTED',

  SNS_SIGN_UP_TEMP_SIGN_IN_FAIL: 'SNS_SIGN_UP_TEMP_SIGN_IN_FAIL',
  SNS_SIGN_UP_TEMP_SIGNED_IN: 'SNS_SIGN_UP_TEMP_SIGNED_IN',
  SNS_SIGN_UP_TEMP_SIGN_OUT_REQUEST: 'SNS_SIGN_UP_TEMP_SIGN_OUT_REQUEST',
  SNS_SIGN_UP_TEMP_SIGN_OUT_STARTED: 'SNS_SIGN_UP_TEMP_SIGN_OUT_STARTED',
  SNS_SIGN_UP_TEMP_SIGNED_OUT: 'SNS_SIGN_UP_TEMP_SIGNED_OUT',
  SNS_SIGN_UP_TEMP_SIGN_OUT_FAIL: 'SNS_SIGN_UP_TEMP_SIGN_OUT_FAIL',
  SNS_SIGN_UP_MASTER_REQUEST: 'SNS_SIGN_UP_MASTER_REQUEST',
  SNS_SIGN_UP_MASTER_STARTED: 'SNS_SIGN_UP_MASTER_STARTED',
  SNS_SIGN_UP_SUCCESS: 'SNS_SIGN_UP_SUCCESS',
  SNS_SIGN_UP_FAIL: 'SNS_SIGN_UP_FAIL',

  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SNS_LOGIN_REQUEST: 'SNS_LOGIN_REQUEST',
  SNS_LOGIN_STARTED: 'SNS_LOGIN_STARTED',
  SNS_LOGIN_SUCCESS: 'SNS_LOGIN_SUCCESS',
  SNS_LOGIN_SUCCESS_RELOAD: 'SNS_LOGIN_SUCCESS_RELOAD',
  SNS_LOGIN_SUCCESS_RELOADING: 'SNS_LOGIN_SUCCESS_RELOADING',
  SNS_LOGIN_UNLINK_LOGOUT_REQUEST: 'SNS_LOGIN_UNLINK_LOGOUT_REQUEST',
  SNS_LOGIN_UNLINK_LOGOUT_STARTED: 'SNS_LOGIN_UNLINK_LOGOUT_STARTED',
  SNS_LOGIN_FAIL: 'SNS_LOGIN_FAIL',
  SNS_LOGOUT_REQUEST: 'SNS_LOGOUT_REQUEST',
  SNS_LOGOUT_STARTED: 'SNS_LOGOUT_STARTED',
  SNS_LOGOUT_SUCCESS: 'SNS_LOGOUT_SUCCESS',
  SNS_LOGOUT_FAIL: 'SNS_LOGOUT_FAIL',

  SNS_LINK_REQUEST: 'SNS_LINK_REQUEST',
  SNS_LINK_DISABLE_STARTED: 'SNS_LINK_DISABLE_STARTED',
  SNS_LINK_DISABLED: 'SNS_LINK_DISABLED',
  SNS_LINK_DISABLE_FAIL: 'SNS_LINK_DISABLE_FAIL',

  SNS_LINK_TEMP_SIGN_IN_STARTED: 'SNS_LINK_TEMP_SIGN_IN_STARTED',
  SNS_LINK_TEMP_SIGNED_IN: 'SNS_LINK_TEMP_SIGNED_IN',
  SNS_LINK_TEMP_SIGN_IN_FAIL: 'SNS_LINK_TEMP_SIGN_IN_FAIL',

  SNS_LINK_TEMP_SIGN_OUT_REQUEST: 'SNS_LINK_TEMP_SIGN_OUT_REQUEST',
  SNS_LINK_TEMP_SIGN_OUT_STARTED: 'SNS_LINK_TEMP_SIGN_OUT_STARTED',
  SNS_LINK_TEMP_SIGNED_OUT: 'SNS_LINK_TEMP_SIGNED_OUT',
  SNS_LINK_TEMP_SIGN_OUT_FAIL: 'SNS_LINK_TEMP_SIGN_OUT_FAIL',

  SNS_LINK_CREATE_REQUEST: 'SNS_LINK_CREATE_REQUEST',
  SNS_LINK_CREATE_STARTED: 'SNS_LINK_CREATE_STARTED',
  SNS_LINK_MASTER_SIGNED_IN: 'SNS_LINK_MASTER_SIGNED_IN',
  SNS_LINK_MASTER_SIGN_IN_FAIL: 'SNS_LINK_MASTER_SIGN_IN_FAIL',
  SNS_LINK_CREATE_FAIL: 'SNS_LINK_CREATE_FAIL',
  SNS_LINK_SUCCESS: 'SNS_LINK_SUCCESS',

  SNS_LINK_OFF_REQUEST: 'SNS_LINK_OFF_REQUEST',
  SNS_LINK_OFF_STARTED: 'SNS_LINK_OFF_STARTED',
  SNS_LINK_OFF_SUCCESS: 'SNS_LINK_OFF_SUCCESS',
  SNS_LINK_OFF_FAIL: 'SNS_LINK_OFF_FAIL',
};

export default SnsAuthTypes;
