import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { state } from '../../../redux/sagas/aws-cognito-redux-saga';
import SignUpFormEmail from './SignUpFormEmail';
import SignedUp from './SignedUp';
import { Wrapper, Spinner } from './SignUp.style';
import SignUpIndex from './SignUpIndex';
import config from '../../../config';

const mapErrorCodeToCopy = {
  UsernameExistsException: 'an_account_with_the_email',
};

class SignUp extends Component {
  static defaultProps = {
    copy: {
      siteCopyStylistToolSignUpPage: {
        sign_up_1: 'Sign up 1/2',
        salon_code: 'Fill out your salon code.',
      },
    },
  };
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        lang: PropTypes.string,
      }),
    }).isRequired,
    auth: PropTypes.shape({
      isSignedIn: PropTypes.string,
      isConfirmed: PropTypes.string,
    }).isRequired,
    init: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    signUp: PropTypes.func.isRequired,

    // Facebook Sign Up
    snsAuth: PropTypes.shape({
      provider: PropTypes.string,
      authState: PropTypes.string,
      snsUser: PropTypes.object,
      masterUser: PropTypes.object,
    }).isRequired,
    snsSignUpRequest: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};

    this.checkCode = this.checkCode.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.registEmail = this.registEmail.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.init();
  }

  componentDidMount() {
    if (this.props.snsAuth.authState === 'SNS_SIGN_UP_TEMP_SIGNED_OUT' && !this.state.user) {
      console.log('SignUp redirect componentDidMount()');

      const { snsUser } = this.props.snsAuth;

      this.props.loadFormDefaults({
        'custom:country': snsUser.salonInfo ? snsUser.salonInfo['custom:country'] : null,
        'custom:city': snsUser.salonInfo ? snsUser.salonInfo['custom:city'] : null,
        'custom:salon': snsUser.salonInfo ? snsUser.salonInfo['custom:salon'] : null,
        'custom:given_name': snsUser.given_name,
        'custom:family_name': snsUser.family_name,
        email: snsUser.email,
      });

      this.setState({
        user: {
          country: snsUser.salonInfo ? snsUser.salonInfo['custom:country'] : null,
          city: snsUser.salonInfo ? snsUser.salonInfo['custom:city'] : null,
          salon: snsUser.salonInfo ? snsUser.salonInfo['custom:salon'] : null,
        },
      });
    }
  }

  signUp = values => {
    const customCode =
      this.props.snsAuth.authState === 'SNS_SIGN_UP_TEMP_SIGNED_OUT'
        ? this.props.snsAuth.signUpCode
        : this.state.isCodeValid.code;

    const UserAttributes = [
      {
        Name: 'custom:family_name',
        Value: values['custom:family_name'],
      },
      {
        Name: 'custom:given_name',
        Value: values['custom:given_name'],
      },
      {
        Name: 'custom:country',
        Value: values['custom:country'],
      },
      {
        Name: 'custom:city',
        Value: values['custom:city'],
      },
      {
        Name: 'custom:job_title',
        Value: values['custom:job_title'],
      },
      {
        Name: 'custom:years_experience',
        Value: values['custom:years_experience'],
      },
      {
        Name: 'custom:salon',
        Value: values['custom:salon'],
      },
      {
        Name: 'custom:code',
        Value: customCode,
      },
    ];

    if (this.props.snsAuth.authState === 'SNS_SIGN_UP_TEMP_SIGNED_OUT') {
      const snsUserAttributes = UserAttributes.reduce((result, attribute) => {
        result[attribute.Name] = attribute.Value;
        return result;
      }, {});

      if (this.props.snsAuth.provider === 'Facebook') {
        snsUserAttributes['custom:facebook_id'] = this.props.snsAuth.snsUser.id;
      } else if (this.props.snsAuth.provider === 'SignInWithApple') {
        snsUserAttributes['custom:apple_id'] = this.props.snsAuth.snsUser.id;
      }

      this.props.snsSignUpMasterRequest({
        email: values.email.toLowerCase(),
        password: values.password,
        customAttributes: snsUserAttributes,
      });
    } else {
      this.props.signUp(values.email.toLowerCase(), values.password, UserAttributes);
    }
  };

  checkCode(values, isSnsSignUp, provider) {
    return fetch(`${config.EXPRESS_API}/api/code/verify`, {
      method: 'post',
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(res => {
        if (res.hasOwnProperty('errors')) {
        } else {
          if (res.user && res.user[0]) {
            if (isSnsSignUp) {
              this.props.snsSignUpPrepare();
            }
            const user = res.user[0];
            this.setState(state => ({
              user,
              ...state,
              isCodeValid: values,
            }));

            const salonInfo = {
              'custom:country': user.country,
              'custom:city': user.city,
              'custom:salon': user.salon,
            };
            if (isSnsSignUp) {
              this.props.snsSignUpRequest({
                salonInfo,
                signUpCode: values.code,
                provider: provider,
                locale: this.props.match.params.lang,
              });
            } else {
              this.props.loadFormDefaults(salonInfo);
            }
          } else {
            this.setState(state => ({
              ...state,
              isCodeValid: false,
            }));
          }
        }
      });
  }

  registEmail() {
    this.setState({ registType: 'email' });
  }

  handleChange(e) {
    if (e.target.name === 'code') {
      this.setState({ isCodeValid: undefined });
    }
  }

  render() {
    const { match, handleSubmit, auth, copy } = this.props;
    const { siteCopyStylistToolSignUpPage, signUpLinks } = copy;
    const { isCodeValid, user } = this.state;
    const { lang } = match.params;

    let error;
    if (auth && auth.error) {
      const copyName = mapErrorCodeToCopy[auth.error.code];
      error = copyName ? siteCopyStylistToolSignUpPage[copyName] : auth.error.message;
    }

    return (
      <Wrapper>
        {auth.hasSignedUp === state.AUTH_UNKNOWN && this.props.snsAuth.authState !== 'SNS_SIGN_UP_SUCCESS' ? (
          <Fragment>
            {this.state.registType === 'email' || this.props.snsAuth.authState === 'SNS_SIGN_UP_TEMP_SIGNED_OUT' ? (
              <SignUpFormEmail
                handleSubmit={handleSubmit}
                error={error}
                auth={auth}
                signUp={this.signUp}
                userData={user}
                locale={lang}
                copy={siteCopyStylistToolSignUpPage}
                snsAuth={this.props.snsAuth}
                // snsSignUp={this.snsSignUp}
              />
            ) : (
              <Fragment>
                {this.props.snsAuth.authState === 'SNS_SIGN_UP_MASTER_STARTED' ? (
                  <Spinner />
                ) : (
                  <SignUpIndex
                    signUpLinks={signUpLinks}
                    error={error}
                    handleSubmit={this.registEmail}
                    locale={lang}
                    isCodeValid={isCodeValid}
                    copy={siteCopyStylistToolSignUpPage}
                    handleChange={this.handleChange}
                    checkCode={this.checkCode}
                    snsAuthInitialize={this.props.snsAuthInitialize}
                    snsSignUpRequest={this.props.snsSignUpRequest}
                    snsAuth={this.props.snsAuth}
                  />
                )}
              </Fragment>
            )}
          </Fragment>
        ) : (
          <SignedUp
            copy={siteCopyStylistToolSignUpPage}
            locale={lang}
            snsAuthInitialize={this.props.snsAuthInitialize}
          />
        )}
      </Wrapper>
    );
  }
}

export default reduxForm({ form: 'signUp', enableReinitialize: true })(SignUp);
