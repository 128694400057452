import { LOAD_SIGN_UP2_FORM } from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case LOAD_SIGN_UP2_FORM:
      return {
        data: action.data,
      };
    default:
      return state;
  }
};
