import React, { Component } from 'react';
import styled from 'styled-components';
import DefaultProfilePic from '../../assets/images/profile-default.png';
import Loading from '../../components/Loading';
import media from '../../styles/media.style';

const convert = require('heic-convert');

const Wrapper = styled.div`
  flex: 1;
  max-width: 170px;
  position: relative;
  margin-bottom: 30px;
  img {
    display: block;
    height: 120px;
    width: auto;
  }
  ${media.tabletL`
    margin-bottom: 0;
    margin-right: 30px;
  `};
`;

const LoadingWrapper = styled.span`
  position: absolute;
  display: block;
  bottom: 0;
  right: 0;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #999999;
  width: 28px;
  height: 28px;
  padding: 0;
`;

const ProfileEditBtn = styled.span`
  position: absolute;
  display: block;
  bottom: 0;
  right: 0;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #999999;
  cursor: pointer;
  width: 28px;
  height: 28px;
  padding: 0;

  svg {
    width: 28px;
    height: 28px;
  }

  ${media.tabletL`
    width: 56px;
    height: 56px;
    padding: 0 0 0 2px;

    svg {
      width: 56px;
      height: 56px;
    }
  `};
`;

const UserAvatar = styled.div`
  width: 170px;
  height: 170px;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
`;

export default class AvatarWrapper extends Component {
  state = {
    localAvatar: '',
  };

  static handleClickUpload() {
    const input = document.querySelector('#imageInput');
    input.click();
  }

  fileReaderWithPromise = (file, method) =>
    new Promise(async (resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('load', async function (event) {
        if (file.type === 'image/heic' && method === 'readAsArrayBuffer') {
          const outputBuffer = await convert({
            buffer: new Uint8Array(event.target.result),
            format: 'JPEG',
            quality: 1
          });

          return resolve(outputBuffer);
        }

        return resolve(event.target.result);
      });
      reader.addEventListener('error', err => reject(err));
      reader[method](file);
    });

  handleUpload = event => {
    const { updateImage } = this.props;
    const file = event.target.files[0];
    if (file) {
      Promise.all([
        this.fileReaderWithPromise(file, 'readAsDataURL'),
        this.fileReaderWithPromise(file, 'readAsArrayBuffer'),
      ]).then(values => {
        updateImage({ dataUri: values[0], arrayBuffer: values[1] });
        this.setState({
          localAvatar: values[0],
        });
      });
    }
  };

  render() {
    const { userAvatar, isUploading } = this.props;
    const { localAvatar } = this.state;
    let imgUrl;

    if (localAvatar) {
      imgUrl = localAvatar;
    } else {
      imgUrl = userAvatar && userAvatar.data ? userAvatar.data.signedUrl : DefaultProfilePic;
    }

    return (
      <Wrapper>
        <UserAvatar style={{ backgroundImage: `url(${imgUrl})` }} />
        {isUploading ? (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        ) : (
          <ProfileEditBtn onClick={this.constructor.handleClickUpload}>
            <svg width="56px" height="56px" viewBox="0 0 56 56">
              <g>
                <path
                  d="M33.304977,22.3623016 L34.4012393,21.2651869 C35.5324525,20.1348262 35.240059,18.5952852 34.1096984,17.4649246 C32.9793377,16.3345639 31.4389443,16.0421705 30.3085836,17.1725311 L29.2114689,18.2687934 L33.304977,22.3623016 Z"
                  stroke="#000000"
                  fill="#fff"
                />
                <polygon
                  stroke="#000000"
                  fill="#fff"
                  points="21.8298557 33.8365705 31.5137902 24.1526361 27.4211344 20.0591279 17.7372 29.7430623 16.4764131 35.0973574"
                />
                <polygon
                  stroke="#000000"
                  fill="#fff"
                  points="31.4981079 24.1431885 33.2938538 22.3693542 29.206665 18.2774658 27.4247131 20.053833"
                />
                <path d="M17.7373705,29.7434885 L21.8300262,33.8369967" stroke="#000000" />
                <path d="M19.760341,31.7068721 L29.4084721,22.058741" stroke="#000000" />
              </g>
            </svg>
          </ProfileEditBtn>
        )}

        <input
          style={{ display: 'none' }}
          id="imageInput"
          type="file"
          name="file"
          accept="image/*,image/heic"
          // multiple="false"
          onChange={this.handleUpload}
        />
      </Wrapper>
    );
  }
}
