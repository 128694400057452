import { GET_USER, CLEAR_USER } from '../constants';

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_USER}_PENDING`:
      return state;
    case `${GET_USER}_FULFILLED`: {
      const data = action.payload;
      return {
        ...state,
        ...data.user,
      };
    }
    case `${GET_USER}_REJECTED`:
      return state;
    case `${CLEAR_USER}`: {
      return {};
    }
    default:
      return state;
  }
};
