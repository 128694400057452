import React, { Fragment } from 'react';

import { Link } from 'react-router-dom';


import Loading from '../../../components/Loading';
import Dropdown from '../../../components/Dropdown/Dropdown';
import Modal from '../../../components/Modal/Modal';
import Accordion from '../../../components/Accordion/Accordion';
import CloseIcon from '../../../components/CloseIcon/CloseIcon';
import DefaultProfilePic from '../../../assets/images/profile-default.png';
import SearchIcon from '../../../assets/images/icon_search.svg';

import {
  Section,
  SectionInner,
  HeroSignedInWrapper,
  HeroSignedInTitle,
  ProfileInfo,
  ProfileImageWrapper,
  ProfileImage,
  ProfileEditBtn,
  ProfileName,
  ProfileTitle,
  DesktopDropdownWrapper,
  MobileDropdownWrapper,
  FilterBtn,
  FilterMenuWrapper,
  CloseBtnWrapper,
  CloseBtn,
  FilterTypeList,
  FiltersWrapper,
  FilterItem,
  FilterButtonWrapper,
  ClearBtn,
  ApplyBtn,
  Checkmark,
  CTA,
  SearchWrapper,
  SearchInput,
  SearchButton,
} from './Hero.style';

const Hero = ({
  lang,
  user,
  onFilter,
  availableFilters,
  courseFilters,
  categoryFilters,
  levelFilters,
  isFilterMenuOpen,
  onRemoveFilter,
  onToggleFilterOverlay,
  onClearFilters,
  searchText,
  copy,
  userAvatar,
  handleChange,
  handleKeyUp,
  handleClick,
}) => (
  <Section>
    <SectionInner>
      <ProfileInfo>
        <ProfileImageWrapper>
          <ProfileImage url={userAvatar ? userAvatar : DefaultProfilePic} />

          <Link
            to={{
              pathname: `/${lang}/dashboard/settings`,
            }}
          >
            <ProfileEditBtn>
              <svg width="56px" height="56px" viewBox="0 0 56 56">
                <g>
                  <path
                    d="M33.304977,22.3623016 L34.4012393,21.2651869 C35.5324525,20.1348262 35.240059,18.5952852 34.1096984,17.4649246 C32.9793377,16.3345639 31.4389443,16.0421705 30.3085836,17.1725311 L29.2114689,18.2687934 L33.304977,22.3623016 Z"
                    stroke="#000000"
                    fill="#fff"
                  />
                  <polygon
                    stroke="#000000"
                    fill="#fff"
                    points="21.8298557 33.8365705 31.5137902 24.1526361 27.4211344 20.0591279 17.7372 29.7430623 16.4764131 35.0973574"
                  />
                  <polygon
                    stroke="#000000"
                    fill="#fff"
                    points="31.4981079 24.1431885 33.2938538 22.3693542 29.206665 18.2774658 27.4247131 20.053833"
                  />
                  <path d="M17.7373705,29.7434885 L21.8300262,33.8369967" stroke="#000000" />
                  <path d="M19.760341,31.7068721 L29.4084721,22.058741" stroke="#000000" />
                </g>
              </svg>
            </ProfileEditBtn>
          </Link>
        </ProfileImageWrapper>
        {user ? (
          <div>
            <ProfileName>
              {user.given_name} {user.family_name}
            </ProfileName>
            <ProfileTitle>{user.job_title}</ProfileTitle>
          </div>
        ) : (
          <Loading />
        )}
      </ProfileInfo>

      <HeroSignedInWrapper>
        <HeroSignedInTitle>{copy.welcome_back}</HeroSignedInTitle>
        <p>{copy.welcome_back_subtext}</p>

        <SearchWrapper>
          <SearchInput placeholder={copy.search_placeholder} value={searchText} onChange={handleChange} onKeyUp={handleKeyUp} />
          <SearchButton onClick={handleClick} src={SearchIcon} />
        </SearchWrapper>
      </HeroSignedInWrapper>
    </SectionInner>


    <CTA>
      <DesktopDropdownWrapper>
        <Dropdown
          name="category"
          activeOptions={categoryFilters}
          label={copy.by_category}
          options={availableFilters.category}
          onSelect={onFilter}
          onRemoveFilter={onRemoveFilter}
        />
        <Dropdown
          name="course"
          activeOptions={courseFilters}
          label={copy.by_course}
          options={availableFilters.course}
          onSelect={onFilter}
          onRemoveFilter={onRemoveFilter}
        />
        <Dropdown
          name="level"
          activeOptions={levelFilters}
          label={copy.by_level}
          options={availableFilters.level}
          onSelect={onFilter}
          onRemoveFilter={onRemoveFilter}
        />
      </DesktopDropdownWrapper>
      <MobileDropdownWrapper>
        <Fragment>
          <FilterBtn onClick={onToggleFilterOverlay}>{copy.narrow_down_sp}</FilterBtn>
          {isFilterMenuOpen && (
            <Modal>
              <FilterMenuWrapper>
                <CloseBtnWrapper>
                  <CloseBtn onClick={onToggleFilterOverlay}>
                    <CloseIcon />
                  </CloseBtn>
                </CloseBtnWrapper>
                <FilterTypeList>
                  <Accordion
                    title={'Category'}
                    contentHeight={
                      (availableFilters.category.length + 1) * 45 + 30 /* FilterWrapper padding + FilterItem height */
                    }
                  >
                    <FiltersWrapper>
                      <FilterItem
                        onClick={onFilter('category', 'All')}
                        selected={!!categoryFilters.find(f => f === 'All')}
                      >
                        <Checkmark />
                        <span>All</span>
                      </FilterItem>
                      {availableFilters.category.map(f => (
                        <FilterItem
                          onClick={() => {
                            categoryFilters.find(filter => f === filter) ? onRemoveFilter('category', f)() : onFilter('category', f)()
                          }}
                          key={f}
                          selected={!!categoryFilters.find(filter => f === filter)}
                        >
                          <Checkmark />
                          <span>{f}</span>
                        </FilterItem>
                      ))}
                    </FiltersWrapper>
                  </Accordion>
                  <Accordion
                    title={copy.courses || 'Courses'}
                    contentHeight={
                      (availableFilters.course.length + 1) * 45 + 30 /* FilterWrapper padding + FilterItem height */
                    }
                  >
                    <FiltersWrapper>
                      <FilterItem
                        onClick={onFilter('course', 'All')}
                        selected={!!courseFilters.find(f => f === 'All')}
                      >
                        <Checkmark />
                        <span>All</span>
                      </FilterItem>
                      {availableFilters.course.map(f => (
                        <FilterItem
                          onClick={() => {
                            courseFilters.find(filter => f === filter) ? onRemoveFilter('course', f)() : onFilter('course', f)()
                          }}
                          key={f}
                          selected={!!courseFilters.find(filter => f === filter)}
                        >
                          <Checkmark />
                          <span>{f}</span>
                        </FilterItem>
                      ))}
                    </FiltersWrapper>
                  </Accordion>
                  <Accordion
                    title={'Level'}
                    contentHeight={
                      (availableFilters.course.length + 1) * 45 + 30 /* FilterWrapper padding + FilterItem height */
                    }
                  >
                    <FiltersWrapper>
                      <FilterItem
                        onClick={onFilter('level', 'All')}
                        selected={!!levelFilters.find(f => f === 'All')}
                      >
                        <Checkmark />
                        <span>All</span>
                      </FilterItem>
                      {availableFilters.level.map(f => (
                        <FilterItem
                          onClick={() => {
                            levelFilters.find(filter => f === filter) ? onRemoveFilter('level', f)() : onFilter('level', f)()
                          }}
                          key={f}
                          selected={!!levelFilters.find(filter => f === filter)}
                        >
                          <Checkmark />
                          <span>{f}</span>
                        </FilterItem>
                      ))}
                    </FiltersWrapper>
                  </Accordion>
                </FilterTypeList>
                <FilterButtonWrapper>
                  <ClearBtn onClick={() => { onClearFilters('all'); }}>{copy.clear_all}</ClearBtn>
                  <ApplyBtn onClick={onToggleFilterOverlay}>{copy.apply}</ApplyBtn>
                </FilterButtonWrapper>
              </FilterMenuWrapper>
            </Modal>
          )}
        </Fragment>
      </MobileDropdownWrapper>
    </CTA>
  </Section>
);

Hero.defaultProps = {
  user: null,
  isFilterMenuOpen: false,
  copy: {
    welcome_back: 'Welcome back!',
    welcome_back_subtext:
      'Lorem iudico accusam deuisset eos, utn ovum intellegebat and loremvis. and Together with beauty creators.',
    narrow_down_sp: 'Narrow down the course',
    by_category: 'By Category',
    by_course: 'By Course',
    by_level: 'By Level',
    download_certificate: 'Download Certificate',
    clear_all: 'Clear All',
    apply: 'Apply',
  },
};

export default Hero;

