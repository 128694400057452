import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { ContentWrapper, Wrapper, Title, Subtitle, Button } from './ErrorPage.style';

const ErrorPage = ({ match, copy }) => (
  <Wrapper>
    <ContentWrapper>
      <Title>{copy.error_page_title}</Title>
      <Subtitle>{copy.error_page_subtitle}</Subtitle>
      <Button>
        <Link to={`/${match.params.lang}/`} href={`/${match.params.lang}/`}>
          {copy.error_page_button}
        </Link>
      </Button>
    </ContentWrapper>
  </Wrapper>
);

ErrorPage.defaultProps = {
  copy: {
    error_page_title: 'Apologies, an error has occured.',
    error_page_button: 'Back To Homepage',
    error_page_subtitle: 'You might want to return home and try again.',
  },
};

ErrorPage.propTypes = {
  copy: PropTypes.shape({
    error_page_title: PropTypes.string,
    error_page_subtitle: PropTypes.string,
    error_page_button: PropTypes.string,
  }).isRequired,
};

export default connect(({ siteOptions }) => ({ siteOptions: siteOptions.siteCopyELearningErrorPage }))(ErrorPage);
