import { connect } from 'react-redux';

import { reducer } from '../../../redux/sagas/aws-cognito-redux-saga';

import ChangePasswordComponent from './ChangePassword';

const mapStatetoProps = state => ({
  auth: state.auth,
  copy: state.siteOptions.siteCopyStylistToolSignUpPage,
});

const mapDispatchToProps = dispatch => ({
  changePassword: (username, code, password) => {
    dispatch(reducer.changePassword(username, code, password));
  },
});

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(ChangePasswordComponent);
