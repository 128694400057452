import { call, all, takeLatest, put } from 'redux-saga/effects';

import { signIn, signOut, snsLogin, snsLogout, signUp, refreshCurrentUserProperties } from './amplify.bridge';

import { disableProviderForUser, linkProviderForUser } from '../../../services/api';
import UserActionTypes from './types';
import {
  snsLoginStarted,
  snsLoginUnlinkLogoutStarted,
  snsLoginFail,
  snsLogoutStarted,
  snsLogoutFail,
  snsSignUpStarted,
  snsSignUpTempSignOutStarted,
  snsSignUpMasterStarted,
  snsSignUpFail,
  snsLinkDisableStarted,
  snsLinkDisabled,
  snsLinkDisableFail,
  snsLinkTempSignInStarted,
  snsLinkTempSignInFail,
  snsLinkTempSignOutStarted,
  snsLinkTempSignOutFail,
  snsLinkCreateStarted,
  snsLinkMasterSignedIn,
  snsLinkMasterSignInFail,
  snsLinkCreateFail,
  snsLinkSuccess,
  snsSignUpSuccess,
} from './actions';

import * as actions from '../aws-cognito-redux-saga/actions';
import * as states from '../aws-cognito-redux-saga/states';

const defaultState = {
  info: {},
  error: {},
  isSignedIn: states.AUTH_UNKNOWN,
  isConfirmed: states.AUTH_UNKNOWN,
  hasSignedUp: states.AUTH_UNKNOWN,
  hasSentCode: states.AUTH_UNKNOWN,
  hasChangedPassword: states.AUTH_UNKNOWN,
  passwordResetRequired: states.AUTH_UNKNOWN,
};
/* handlers */

function* handleSnsSignUpRequest(data) {
  try {
    yield put(snsSignUpStarted(data.payload));
    yield call(snsLogin, data.payload.provider);
  } catch (error) {
    yield put(snsLoginFail(error));
  }
}

function* handleSnsSignUpTempSignOutRequest() {
  try {
    yield put(snsSignUpTempSignOutStarted());
    yield call(signOut);
  } catch (error) {
    yield put(snsSignUpFail(error));
  }
}

function* handleSnsSignUpMasterRequest({ payload }) {
  try {
    yield put(snsSignUpMasterStarted());
    yield call(signUp, payload);
    yield put(snsSignUpSuccess());
    yield put({
      type: actions.AUTH_SET_STATE,
      payload: {
        ...defaultState,
        hasSignedUp: states.AUTH_SUCCESS,
      },
    });
  } catch (error) {
    yield put(snsSignUpFail(error));
    yield put({
      type: actions.AUTH_SET_STATE,
      payload: {
        ...defaultState,
        error,
      },
    });
  }
}

function* handleSnsLoginRequest({ payload }) {
  try {
    yield put(snsLoginStarted(payload));
    yield call(snsLogin, payload.provider);
  } catch (error) {
    yield put(snsLoginFail(error));
  }
}

function* handleSnsLoginUnlinkLogoutRequest() {
  try {
    yield put(snsLoginUnlinkLogoutStarted());
    yield call(snsLogout);
  } catch (error) {
    yield put(snsLoginFail(error));
  }
}

function* handleSnsLogoutRequest({ payload }) {
  try {
    yield put(snsLogoutStarted(payload));
    yield call(snsLogout);
    yield put({
      type: actions.AUTH_SET_STATE,
      payload: {
        ...defaultState,
        isSignedIn: states.AUTH_FAIL,
      },
    });
  } catch (error) {
    yield put(snsLogoutFail(error));
  }
}

function* handleSnsLinkTempSignOutRequest() {
  try {
    yield put(snsLinkTempSignOutStarted());
    yield call(signOut);
  } catch (error) {
    yield put(snsLinkTempSignOutFail(error));
  }
}

function* handleSnsLinkCreateRequest({ payload: { masterUser, snsUser } }) {
  try {
    yield put(snsLinkCreateStarted());
    yield call(signIn, masterUser.email, masterUser.password);
    yield put(snsLinkMasterSignedIn());
  } catch (error) {
    yield put(snsLinkMasterSignInFail(error));
  }

  try {
    yield call(linkProviderForUser, snsUser.provider, snsUser.id, masterUser.email);
    const user = yield call(refreshCurrentUserProperties);
    yield put({
      type: actions.AUTH_SET_STATE,
      payload: {
        ...defaultState,
        isSignedIn: states.AUTH_SUCCESS,
        isConfirmed: states.AUTH_SUCCESS,
        info: { username: user.username, session: { ...user.signInUserSession } },
      },
    });

    yield put(snsLinkSuccess());
  } catch (error) {
    yield put(snsLinkCreateFail(error));
  }
}

function* handleSnsLinkRequest({ payload }) {
  try {
    yield put(snsLinkTempSignInStarted(payload));
    yield call(snsLogin, payload.provider);
  } catch (error) {
    yield put(snsLinkTempSignInFail(error));
  }
}

function* handleSnsLinkOffRequest({ payload: { provider } }) {
  try {
    yield put(snsLinkDisableStarted());
    yield call(disableProviderForUser, provider);
    const user = yield call(refreshCurrentUserProperties);
    yield put({
      type: actions.AUTH_SET_STATE,
      payload: {
        ...defaultState,
        isSignedIn: states.AUTH_SUCCESS,
        isConfirmed: states.AUTH_SUCCESS,
        info: { username: user.username, session: { ...user.signInUserSession } },
      },
    });
    yield put(snsLinkDisabled());
  } catch (error) {
    yield put(snsLinkDisableFail(error));
  }
}

/* watchers */

/* SNS Login */
function* watchSnsLoginRequest() {
  yield takeLatest(UserActionTypes.SNS_LOGIN_REQUEST, handleSnsLoginRequest);
}

function* watchSnsLoginUnlinkLogoutRequest() {
  yield takeLatest(UserActionTypes.SNS_LOGIN_UNLINK_LOGOUT_REQUEST, handleSnsLoginUnlinkLogoutRequest);
}

/* SNS Logout */
function* watchSnsLogoutRequest() {
  yield takeLatest(UserActionTypes.SNS_LOGOUT_REQUEST, handleSnsLogoutRequest);
}

/* SNS SignUp */
function* watchSnsSignUpRequest() {
  yield takeLatest(UserActionTypes.SNS_SIGN_UP_REQUEST, handleSnsSignUpRequest);
}

function* watchSnsSignUpTempSignOutRequest() {
  yield takeLatest(UserActionTypes.SNS_SIGN_UP_TEMP_SIGN_OUT_REQUEST, handleSnsSignUpTempSignOutRequest);
}

function* watchSnsSignUpMasterRequest() {
  yield takeLatest(UserActionTypes.SNS_SIGN_UP_MASTER_REQUEST, handleSnsSignUpMasterRequest);
}

/* SNS Link */
function* watchSnsLinkTempSignOutRequest() {
  yield takeLatest(UserActionTypes.SNS_LINK_TEMP_SIGN_OUT_REQUEST, handleSnsLinkTempSignOutRequest);
}

function* watchSnsLinkCreateRequest() {
  yield takeLatest(UserActionTypes.SNS_LINK_CREATE_REQUEST, handleSnsLinkCreateRequest);
}

function* watchSnsLinkOffRequest() {
  yield takeLatest(UserActionTypes.SNS_LINK_OFF_REQUEST, handleSnsLinkOffRequest);
}

function* watchSnsLinkRequest() {
  yield takeLatest(UserActionTypes.SNS_LINK_REQUEST, handleSnsLinkRequest);
}

export function* snsAuthSagas() {
  yield all([
    call(watchSnsSignUpRequest),
    call(watchSnsSignUpTempSignOutRequest),
    call(watchSnsSignUpMasterRequest),
    call(watchSnsLoginRequest),
    call(watchSnsLoginUnlinkLogoutRequest),
    call(watchSnsLogoutRequest),
    call(watchSnsLinkRequest),
    call(watchSnsLinkTempSignOutRequest),
    call(watchSnsLinkCreateRequest),
    call(watchSnsLinkOffRequest),
  ]);
}
