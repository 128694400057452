import styled from 'styled-components';

import media from '../../styles/media.style';


export const Card = styled.div`
  background: #fff;
  width: 100%;
  height: 352px;

  ${media.tabletL`
    height: 405px;
  `};
`;

export const CardContent = styled.div`
  position: relative;
  height: 165px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  ${media.tabletL`
    height: 205px;
  `};
`;

export const ThumbnailWrapper = styled.div`
  width: 100%;
`;

export const Thumbnail = styled.div`
  width: 100%;
  height: 190px;
  background-image: ${({ url }) => `url(${url}?fm=jpg&fl=progressive)`};
  background-size: cover;
  background-position: center;
  ${media.tabletL`
    height: 200px;
  `};
`;

export const TitleContainer = styled.div`
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0;
`;

export const ModuleSubtitle = styled.p`
  font-size: 12px;
  margin: 0;
  color: #000;

  ${media.tabletL`
    font-size: 14px;
  `};
`;

export const ModuleTitle = styled.p`
  font-size: 16px;
  font-weight: 800;
  line-height: 18px;
  max-height: 36px;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-all;

  text-transform: uppercase;
  margin: 0;
  color: #000;

  ${media.tabletL`
    font-size: 18px;
    line-height: 22px;
    max-height: 44px;
  `};
`;


export const TopLabel = styled.div`
  display: flex;
  width: 100%;
  padding-right: 45px;
  align-items: center;
  span {
    display: inline-block;
  }
  > .module {
    span {
      white-space: pre-wrap;
      word-break: break-all;
      max-height: 26px;
      overflow: hidden;
      line-height: 13px;
      font-size: 13px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 120px;
    color: #fff;
    background-color: #666666;
    padding: 7px 13px;
  }
  > .level {
    margin-top: 5px;
    margin-left: 17px;
    font-size: 10px;
    letter-spacing: 3px;
  }
  > .sub {
    margin-top: 5px;
    margin-left: 10px;
    font-size: 10px;
  }
  ${media.tabletL`
    > .module {
      span {
        max-height: 32px;
        font-size: 16px;
        line-height: 16px;
      }
      padding: 7px 13px;
    }
    > .level {
      margin-left: 17px;
      font-size: 14px;
      letter-spacing: 3px;
    }
    > .sub {
      margin-left: 10px;
      font-size: 14px;
    }
  `};
`;

export const CTA = styled.div`
  width: 100%;
  height: 48px;
  text-align: center;
  font-size: 14px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 48px;
  color: #fff;
  background-color: ${({ disabled }) => disabled ? '#bbbbbb' : '#000'};
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  ${media.tabletL`
    font-size: 15px;
  `};
`;

export const InfoLabel = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 100%;
  font-size: 10px;
  margin-top: 10px;
  text-align: right;

  span {
    color: #E0E0E0;
  }
`;

export const BadgeImage = styled.img`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 45px;
  height: 60px;
`;

export const PlaceholderWrapper = styled.div`
  flex: 1;
`;

export const Wrapper = styled.div`
  width: 100%;
`;
