import styled from 'styled-components';

import media from '../../../styles/media.style';
import { DEFAULT_FONT_FAMILY } from '../../../styles/variables.style';

export const Annotation = styled.p`
  font-size: 11px;
  ${media.tabletL`
    margin-top: 23px;
  `};
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  a {
    font-weight: 900;
    color: #000;
    > span {
      font-weight: 800;
      color: #000;
    }
    :visited {
      color: #000;
    }
  }

  ${media.tabletL`
    width: 320px;
  `};
`;

export const Title = styled.h1`
  font-size: 28px;
  margin: 0;
  font-weight: 400;

  ${media.tabletL`
    font-size: 28px;
  `};
`;

export const SubTitle = styled.h2`
  font-size: 24px;
  margin: 0;
  font-weight: 400;

  ${media.tabletL`
    font-size: 24px;
  `};
`;

export const Form = styled.form``;

export const Button = styled.button`
  cursor: pointer;
  width: 100%;
  height: 60px;
  background-color: ${({ type }) => handleColorType(type)};
  display: block;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  border: none;
  text-align: center;
`;

const handleColorType = type => {
  switch (type) {
    case 'Facebook':
      return '#4267B2';
    default:
      return '#000';
  }
};

export const LinkButton = styled.div`
  font-size: 12px;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  margin-top: 3em;
`;

export const LinkButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 1em;
  font-family: ${DEFAULT_FONT_FAMILY};

  > div {
    display: inline;
  }
`;

export const FieldsWrapper = styled.div`
  margin-top: 0;
`;

export const ButtonsWrapper = styled.div`
  margin: 40px 0 0;
`;

export const LabelError = styled.div`
  font-size: 14px;
  width: 100%;
  margin: 10px 0 20px;
  color: rgb(200, 0, 0);
  ${media.tabletL`
    font-size: 16px;
  `};
`;
