import styled from 'styled-components';
import media from '../../../styles/media.style';

export const Container = styled.div`
  padding: 36px;
  position: relative;
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
`;

export const Form = styled.form``;

export const Title = styled.h2`
  font-weight: normal;
`;

export const Subtitle = styled.h4`
  font-size: 13px;
  line-height: 1.5;
  margin: -2em 0 0;
  font-weight: 400;

  span {
    text-transform: capitalize;
  }

  ${media.tabletL`
    font-size: 18px;
    margin: 0;
  `};
`;

export const Button = styled.button`
  width: 100%;
  height: 60px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 20%;
`;
