import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const Icon = styled.div`
  background-image: linear-gradient(blue, red);
  border-radius: 10px;
  height: 20px;
  width: 20px;
  animation: rotate 1s linear infinite;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const LoadingIcon = () => (
  <Wrapper>
    <Icon />
  </Wrapper>
);

export default LoadingIcon;
