import React from 'react';
import PropTypes from 'prop-types';

import { Container, TextInput, Label, LabelError, LabelWarning, Annotation } from './TextField.style';

const TextField = ({
  placeholder,
  label,
  input,
  type,
  errorMessage,
  max,
  meta: { touched, error, warning },
  styles,
  required,
  optional,
  optionalText,
  disabled,
}) => (
  <Container style={styles}>
    <TextInput {...input} placeholder={placeholder} type={type} maxLength={max} disabled={disabled ? 'disabled' : ''} />
    {!touched && (
      <Label>
        {label}
        {required && <Annotation color="red">＊</Annotation>}
        {optional && <Annotation>{optionalText}</Annotation>}
      </Label>
    )}
    {touched &&
      !error &&
      !errorMessage &&
      !warning && (
        <Label>
          {label}
          {required && <Annotation color="red">＊</Annotation>}
          {optional && <Annotation>{optionalText}</Annotation>}
        </Label>
      )}
    {touched &&
      (((error || errorMessage) && <LabelError>{error || errorMessage}</LabelError>) ||
        (warning && <LabelWarning>{warning}</LabelWarning>))}
  </Container>
);

TextField.defaultProps = {
  styles: {},
  required: false,
  optional: false,
  max: 300, // 共通で最大文字数を300に設定
  disabled: false,
};

TextField.propTypes = {
  placeholder: PropTypes.string.isRequired,
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  max: PropTypes.number,
  meta: PropTypes.shape({}).isRequired,
  styles: PropTypes.shape({}),
  required: PropTypes.bool,
  optional: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default TextField;
