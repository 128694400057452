import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { CognitoUserPool } from 'amazon-cognito-identity-js';

import Confirmation from '../../../components/User/ChangePassword/Confirmation';
import config from '../../../config';

import ChangePasswordForm from './ChangePasswordForm';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasChangedPassword: false,
      error: '',
      isLoading: false,
    };

    this.changePassword = this.changePassword.bind(this);
  }

  static propTypes = {
    history: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired,
    auth: PropTypes.object,
    init: PropTypes.func,
  };

  changePassword = values => {
    this.setState(state => ({
      ...state,
      isLoading: true,
    }));
    var data = {
      UserPoolId: config.COGNITO_USERPOOL,
      ClientId: config.COGNITO_CLIENTID,
    };
    var userPool = new CognitoUserPool(data);
    var cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          alert(err);
          return;
        }

        cognitoUser.changePassword(values.password, values.passwordMatch, (err, result) => {
          if (err) {
            console.log('error', err);
            this.setState(state => ({
              ...state,
              error: err.message,
              isLoading: false,
            }));
            return;
          }

          this.setState(state => ({
            ...state,
            hasChangedPassword: true,
            isLoading: false,
          }));
        });
      });
    }
  };

  signIn = () => {
    const { lang } = this.props.match.params;
    this.props.history.push(`/${lang}/login`);
  };

  render() {
    const { handleSubmit, auth, copy, global_copy } = this.props;
    const { hasChangedPassword, error, isLoading } = this.state;

    return hasChangedPassword ? (
      <Confirmation handleSubmit={handleSubmit} signIn={this.signIn} copy={copy} global_copy={global_copy} />
    ) : (
      <ChangePasswordForm
        auth={auth}
        handleSubmit={handleSubmit}
        changePassword={this.changePassword}
        copy={copy}
        hasChangedPassword={hasChangedPassword}
        error={error}
        isLoading={isLoading}
      />
    );
  }
}

// Decorate the form component
export default reduxForm({
  form: 'changePassword',
})(ChangePassword);
