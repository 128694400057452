import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

const Box = styled.span`
  animation: pulse 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite alternate;
  background-color: ${({ c }) => c};
  border-radius: ${({ b }) => b};
  display: inline-block;
  height: ${({ h }) => h};
  margin-bottom: ${({ mb }) => mb};
  margin-right: ${({ mr }) => mr};
  margin-top: ${({ mt }) => mt};
  padding-bottom: ${({ pb }) => pb};
  vertical-align: top;
  width: ${({ w }) => w};

  @keyframes pulse {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.5;
    }
  }
`;

/**
 * Renders a pulsing placehodler.
 *
 * @prop {string} b border radius value if you need rounded corners
 * @prop {string} c background color, defaults to lightgrey
 * @prop {string} h height is required
 * @prop {string} w width is required
 */
const Placeholder = ({ b, c, h, mb, mr, mt, pb, w, screenSize }) => (
  <Box
    b={Array.isArray(b) ? b[screenSize] || b[b.length - 1] : b}
    c={Array.isArray(c) ? c[screenSize] || c[c.length - 1] : c}
    h={Array.isArray(h) ? h[screenSize] || h[h.length - 1] : h}
    mb={Array.isArray(mb) ? mb[screenSize] || mb[mb.length - 1] : mb}
    mr={Array.isArray(mr) ? mr[screenSize] || mr[mr.length - 1] : mr}
    mt={Array.isArray(mt) ? mt[screenSize] || mt[mt.length - 1] : mt}
    pb={Array.isArray(pb) ? pb[screenSize] || pb[pb.length - 1] : pb}
    w={Array.isArray(w) ? w[screenSize] || w[w.length - 1] : w}
  />
);

Placeholder.defaultProps = {
  b: '0px',
  c: 'lightgrey',
  h: 'unset',
  w: 'unset',
  mb: '0',
  mr: '0',
  mt: '0',
  pb: '0',
};

Placeholder.propTypes = {
  b: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  c: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  h: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  mb: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  mr: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  mt: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  pb: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  w: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  screenSize: PropTypes.number.isRequired,
};

const mapState = state => ({
  screenSize: state.screenSize,
});

export default connect(mapState)(Placeholder);
