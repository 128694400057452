import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ModuleCard from '../../../components/ModuleCard';
import { SCREEN_LOCKUP_WIDTH, MOBILE_PADDING, BLACK } from '../../../styles/variables.style';
import media from '../../../styles/media.style';
import Badge from '../../../assets/images/badge.png';
import BadgeLine from '../../../assets/images/badge_line.png';


const Wrapper = styled.div`
  width: 100%;
  max-width: ${SCREEN_LOCKUP_WIDTH + MOBILE_PADDING}px;
  margin: 0 auto;
  padding: 3em ${MOBILE_PADDING}px;

  ${media.tabletL`
    padding: 100px ${MOBILE_PADDING}px;
  `};
`;

export const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;

  li {
    width: 100%;
    margin-bottom: 20px;
  }

  ${media.tabletL`
    padding: 0;
    flex-direction: row;
    flex-wrap: wrap;
    li {
      width: 32%;
      margin: 1.3% 1.3% 0 0;
    }
    li:nth-child(n+4) {
      margin-top: 40px;
    }
  `};
`;

export const Title = styled.h5`
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 10px;

  ${media.tabletL`
    font-size: 13px;
  `};
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${BLACK};
  margin: ${({ small, margin }) => (margin && margin) || (small && 30) || 70}px 0;
`;

const ModuleList = ({ sections, locale, progress, copy, onClickDownload }) => {
  let contents = [{ key: 1 }, { key: 2 }, { key: 3 }];
  if (progress) {
    let userProgress;
    if (progress) {
      userProgress = JSON.parse(progress);
    }

    contents =
      sections && userProgress
        && sections.filter(s => s.modules.length).map(section => {
            const isCompleted = section.modules.every(m => userProgress.completedQuizzes.includes(m.moduleSlug));
            return {
              key: section.slug,
              link: section.modules.length === 1 ?
                `/${locale}/course/${section.slug}/${section.modules[0].moduleSlug}` :
                `/${locale}/course/${section.slug}`,
              thumbnailUrl: section.thumbnail ? section.thumbnail.file.url : null,
              category: section.category,
              level: section.level,
              badgeImage: isCompleted ? Badge : BadgeLine,
              contents: section.contents,
              title: section.sectionTitle,
              infoList: [
                `${section.modules.length} ${section.modules.length === 1 ? copy.module : copy.modules}`,
                ` ${section.modules.reduce((acc, m) => acc + m.moduleChapters.filter(c => c.memberflag !== false).length, 0)} ${copy.chapters} `,
                ` ${section.sectionDuration} ${copy.minutes}`,
              ],
              isCompleted,
              ...section,
            }
          });
  }

  return (
    <Wrapper>
      <List>
        {contents.map(content => (
          <li key={content.key}>
            <ModuleCard content={content} locale={locale} progress={progress} copy={copy} onClickDownload={onClickDownload} />
          </li>
        ))}
      </List>
    </Wrapper>
  );
};

ModuleList.defaultProps = {
  copy: {},
};

ModuleList.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  locale: PropTypes.string.isRequired,
};

export default ModuleList;
