import React, { Component } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { Field } from 'redux-form';

import { Container, Title, Subtitle, Form, Button } from './ChangePasswordForm.style';

import TextField from '../../TextField/TextField';
import Placeholder from '../../Placeholder';
import { initialValidatorObject, updateValidatorObject } from '../../../utils';

const _minLength = 6;
const required = copy => value => (value ? undefined : copy.required || 'Required');
const email = copy => value =>
  validator.isEmail(value) ? undefined : copy.enter_a_vaild_email_address || 'Not Valid Email';
const passwordMatch = copy => (value, values) =>
  values.password !== values.passwordMatch && (copy.passwords_must_match || 'Passwords must match');
const minLength = copy => min => value =>
  value && value.length >= min ? undefined : `${copy.minlength6 || `Min Length ${min}`}`;
const containsSmallCase = copy => value =>
  value.match(/[a-z]+/) ? undefined : copy.must_contain_at_least_small || 'Must contain at least one small case letter';
const containsUpperCase = copy => value =>
  value.match(/[A-Z]+/) ? undefined : copy.must_contain_at_least_upper || 'Must contain at least one upper case letter';

const v_translate = {
  required: required,
  enter_a_vaild_email_address: email,
  passwords_must_match: passwordMatch,
  minlength6: minLength,
  must_contain_at_least_small: containsSmallCase,
  must_contain_at_least_upper: containsUpperCase,
};

const style = {
  error: {
    margin: '8px',
    color: 'rgb(200,0,0)',
  },
};

class ChangePasswordForm extends Component {
  static defaultProps = {
    copy: {},
  };

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    auth: PropTypes.shape().isRequired,
    changePassword: PropTypes.func.isRequired,
  };

  state = {};

  componentDidMount() {
    this.setState(initialValidatorObject(v_translate, this.props), this.updatePasswordValidators);
  }

  componentWillReceiveProps(props) {
    const update = updateValidatorObject(v_translate, this.props.copy, props.copy);
    if (update) {
      this.setState(update, this.updatePasswordValidators);
    }
  }

  updatePasswordValidators = () => {
    this.setState({
      passwordValidators: [
        this.state.minlength6(_minLength),
        this.state.must_contain_at_least_upper,
        this.state.must_contain_at_least_small,
      ],
    });
  };

  render() {
    const { handleSubmit, auth, changePassword, copy } = this.props;
    const { required, enter_a_vaild_email_address, passwords_must_match, passwordValidators } = this.state;
    return (
      <Container>
        {copy.change_password ? (
          <Title>{copy.change_password}</Title>
        ) : (
          <Placeholder w="250px" h="50px" mb="10px" mt="30px" />
        )}
        {copy.password_rule ? (
          <Subtitle>{copy.password_rule}</Subtitle>
        ) : (
          <Placeholder w={['300px', '600px']} h={['40px', '30px']} />
        )}
        <Form>
          <Field
            name="email"
            validate={[required, enter_a_vaild_email_address].filter(it => it)}
            component={TextField}
            type="email"
            placeholder="name@email.com"
            label={copy.emailField || 'email'}
          />

          <Field
            name="code"
            validate={[required].filter(it => it)}
            component={TextField}
            type="string"
            placeholder=""
            label="code"
          />

          <Field
            name="password"
            validate={passwordValidators ? passwordValidators : null}
            component={TextField}
            type="password"
            placeholder="••••••••••"
            label={copy.passwordField || 'password'}
          />

          <Field
            name="passwordMatch"
            validate={[]
              .concat(passwordValidators)
              .concat([passwords_must_match])
              .filter(it => it)}
            component={TextField}
            type="password"
            placeholder="••••••••••"
            label={copy.confirmPasswordField || 'confirm password'}
          />
          <div style={style.error}>{auth.error.message}</div>

          <Button disabled={!copy.change_password} onClick={handleSubmit(changePassword)}>
            {copy.change_password}
          </Button>
        </Form>
      </Container>
    );
  }
}

export default ChangePasswordForm;
