const config = {
  API: process.env.REACT_APP_API || 'https://dr0ntlinpl.execute-api.ap-northeast-1.amazonaws.com/Prod/api/code',
  EXPRESS_API: process.env.REACT_APP_EXPRESS_API || 'http://localhost:3004',
  COGNITO_REGION: process.env.REACT_APP_COGNITO_REGION || 'ap-northeast-1',
  COGNITO_USERPOOL: process.env.REACT_APP_COGNITO_USERPOOL || 'ap-northeast-1_ldmhOiaHQ',
  COGNITO_CLIENTID: process.env.REACT_APP_COGNITO_CLIENTID || '6viiigapi1589k8jae3ahfd3na',
  BRIGHTCOVE_ACCOUNT_ID: process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID || '5772660118001',
  BRIGHTCOVE_PLAYER_ID: process.env.REACT_APP_BRIGHTCOVE_PLAYER_ID || 'Byg2VBZfQ',
  WEBSITE_URL: process.env.REACT_APP_BRIGHTCOVE_WEBSITE_URL || 'http://website-local.sp.rgadev.com',
  SNS_API: process.env.REACT_APP_SNS_API || 'https://d3kvnb1mgo011j.cloudfront.net',
  SNS_LINK_PASSWD_CHECK_MAX_RETRIES: process.env.REACT_APP_SNS_LINK_PASSWD_CHECK_MAX_RETRIES || 4,
  SNS_LINK_PASSWD_CHECK_INITIAL_DELAY: process.env.REACT_APP_SNS_LINK_PASSWD_CHECK_INITIAL_DELAY || 5000,
};

// Can't set .env.local to be an empyt string
// https://github.com/facebook/create-react-app/issues/4002
if (process.env.REACT_APP_EXPRESS_API === 'undefined') {
  config.EXPRESS_API = '';
}

if (process.env.REACT_APP_API === 'undefined') {
  config.EXPRESS_API = '/api/code';
}

if (process.env.REACT_APP_SNS_API === 'undefined') {
  config.SNS_API = '';
}

export default config;
