import styled from 'styled-components';
import { WHITE, BLACK } from '../../styles/variables.style';

export const Wrapper = styled.div`
  position: relative;
  flex: 1;
`;

export const Button = styled.button`
  cursor: pointer;
  position: relative;
  background-color: ${WHITE};
  border: none;
  font-size: 16px;
  font-weight: 400;
  height: 44px;
  padding: 0 20px;
  line-height: 44px;
  text-transform: uppercase;
  color: ${BLACK};
  width: 100%;
  text-align: left;
  letter-spacing: 1px;

  span {
    margin-right: 50px;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 1px;
  }

  ::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%) ${({ open }) => (open ? 'rotate(0)' : 'rotate(-90deg)')};
    width: 11px;
    height: 1px;
    background-color: ${BLACK};
    transition: transform 0.2s ease-in-out;
  }

  ::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 11px;
    height: 1px;
    background-color: ${BLACK};
  }
`;

export const ChildrenWrapper = styled.div`
  background-color: ${WHITE};
  max-height: ${({ open, height }) => (open ? `${height}px` : '0')};
  transition: max-height 0.3s ease-in-out;
  list-style: none;
  width: 100%;
  overflow: auto;
`;
