import React, { Component } from 'react';
import TagManager from 'react-gtm-module';

const documentProp = prop => document.documentElement[prop] || document.body[prop];
const scrollTop = () => documentProp('scrollTop');
const scrollHeight = () => documentProp('scrollHeight');
const clientHeight = () => documentProp('clientHeight');
const takenValues = [90, 75, 50, 25];

export default class ScrollAnalyticsRoute extends Component {
  state = {
    scroll: -1
  };
  UNSAFE_componentWillMount() {
    document.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }
  componentWillUpdate(props, nextState) {
    const { scroll } = this.state;
    const threshold = takenValues.find(val => nextState.scroll >= val);
    if (threshold && scroll < threshold) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'pagescroll',
          scroll_rt: `${threshold}%`
        }
      });
    }
  }

  handleScroll = () => {
    const scroll = parseInt((scrollTop() / (scrollHeight() - clientHeight())) * 100, 10);
    this.setState({ scroll });
  };

  render() {
    return <span id="scroll-analytics" />;
  }
}
