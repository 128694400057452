import React from 'react';
import PropTypes from 'prop-types';

import { config as CognitoConfig } from '../../../redux/sagas/aws-cognito-redux-saga';
import config from '../../../config';

class Auth extends React.Component {
  static propTypes = {
    getUser: PropTypes.func
  };

  UNSAFE_componentWillMount() {
    CognitoConfig.config.set({
      region: config.COGNITO_REGION,
      UserPoolId: config.COGNITO_USERPOOL,
      ClientId: config.COGNITO_CLIENTID
    });

    this.props.getUser();
  }

  render() {
    return null;
  }
}

export default Auth;
