import styled from 'styled-components';

import hairBg from '../../assets/images/hair.svg';

import media from '../../styles/media.style';
import { MOBILE_PADDING, WHITE, BLACK, DARK_GREY, SCREEN_LOCKUP_WIDTH, PAGE_WIDTH } from '../../styles/variables.style';

export const QuizHero = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 34px ${MOBILE_PADDING}px;
  min-height: 200px;
  background-image: url('https://images.ctfassets.net/hxo6ijjmlki5/3IxTBS7aSbPfBaQXo6rPZT/ccb59ff390b1807d69ebd65bbf7dc002/e-learning_HERO_VISUAL2021_2x.jpg?w=1440&q=100');

  ${media.tabletL`
    padding: 6em 0 8em;
  `};

  // ${media.x2`
  //   background-image: url('https://images.ctfassets.net/hxo6ijjmlki5/3IxTBS7aSbPfBaQXo6rPZT/ccb59ff390b1807d69ebd65bbf7dc002/e-learning_HERO_VISUAL2021_2x.jpg?w=2880&q=100');
  // `};

  // ${media.x3`
  //   background-image: url('https://images.ctfassets.net/hxo6ijjmlki5/3IxTBS7aSbPfBaQXo6rPZT/ccb59ff390b1807d69ebd65bbf7dc002/e-learning_HERO_VISUAL2021_2x.jpg?w=4320&q=100');
  // `};

  p {
    font-size: 14px;
    color: ${WHITE};
    font-weight: 400;
    max-width: 220px;
    margin-bottom: 0;

    ${media.tabletL`
      font-size: 18px;
      max-width: 440px;
      text-align: center;
    `};
  }
`;

export const QuizHeroTitle = styled.h1`
  font-size: 21px;
  font-weight: 800;
  color: ${WHITE};
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  max-width: 220px;

  ${media.tabletL`
    font-size: 33px;
    max-width: none;
  `};
`;

export const ContentWrapper = styled.section`
  background: center / cover no-repeat url(${hairBg}), ${WHITE};
  max-width: ${PAGE_WIDTH}px;
  margin: 0 auto;
  width: 100%;
`;

export const ContentInnerWrapper = styled.section`
  margin: 0 auto;
  padding-bottom: 50px;
  max-width: ${SCREEN_LOCKUP_WIDTH}px;
  width: 100%;
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonText = styled.p`
  display: none;
  text-transform: uppercase;
  font-size: 14px;

  ${media.tabletL`
    display: block;
  `};

  span {
    font-weight: 400;
    color: ${WHITE};
    letter-spacing: 1px;
    vertical-align: text-top;
  }
`;

export const PreviousBtn = styled.button`
  cursor: pointer;
  width: 50px;
  height: 50px;
  border: none;
  border-right: 1px solid ${WHITE};
  background-color: ${BLACK};
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${({ url }) => url});
  transition: background-color 0.3s;

  :disabled {
    background-color: ${DARK_GREY};
  }

  ${media.tabletL`
    min-width: 295px;
    text-align: left;
    padding-left: 64px;
    background-position: 45px center;
    height: 60px;
  `};
`;

export const NextBtn = PreviousBtn.extend`
  border-right: none;
  background-image: url(${({ url }) => url});

  ${media.tabletL`
    text-align: right;
    padding-right: 64px;
    background-position: 250px center;
  `};
`;

export const ContentTitle = styled.h1`
  font-size: 21px;
  text-transform: uppercase;
  padding: 0 ${MOBILE_PADDING}px;
  margin-bottom: 0;

  span {
    font-weight: bold;
  }

  ${media.tabletL`
    font-size: 38px;
    padding: 0;
  `};
`;

export const QuestionNumber = styled.span`
  h1 & {
    font-weight: 600;
  }
`;

export const ProgressWrapper = styled.div`
  overflow: hidden;
  height: 36px;
  margin: 0 ${MOBILE_PADDING}px;
  padding-top: 24px;

  ${media.tabletL`
    margin: 0;
  `};
`;

export const ProgressOverflow = styled.div`
  border-top: 3px solid ${BLACK};
`;

export const Progress = styled.div`
  background-color: ${BLACK};
  height: 12px;
  width: 100%;
  transition: transform 0.3s;

  span {
    display: block;
    margin-top: -18px;
    margin-right: 0;
    float: right;
    font-size: 12px;
    font-weight: 500;
  }
`;

export const Question = styled.p`
  padding: 0 ${MOBILE_PADDING}px;

  ${media.tabletL`
    padding: 0;
    font-size: 20px;
    line-height: 27px;
  `};
`;

export const Answer = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: block;
  text-align: left;
  padding: 0 ${MOBILE_PADDING}px;
  margin-bottom: 1.5em;

  div {
    display: inline-block;
  }

  div:last-child {
    display: inline-block;
    font-size: 12px;
    width: 80%;
    max-width: 400px;
    vertical-align: middle;
  }

  p {
    font-size: 18px;
  }

  span {
    font-size: 18px;
  }

  ${media.tabletL`
    padding: 0;
    display: flex;
    align-items: center;
  `};
`;

export const Checkmark = styled.span`
  display: inline-block;
  position: relative;
  width: 25px;
  height: 25px;
  border: 1px solid ${BLACK};
  margin-right: 12px;
  border-radius: 50%;
  background-color: ${({ selected }) => (selected ? BLACK : WHITE)};
  transition: background-color 0.3s;

  ::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%) rotate(-45deg);
    border-bottom: 1px solid ${WHITE};
    border-left: 1px solid ${WHITE};
    width: 8px;
    height: 4px;
  }

  ${media.tabletL`
    width: 45px;
    height: 45px;

    ::after {
      width: 15px;
      height: 8px;
      border-bottom: 2px solid ${WHITE};
      border-left: 2px solid ${WHITE};
    }
  `};
`;

export const ResultsBtn = styled.button`
  cursor: pointer;
  padding: 0 60px;
  margin: 40px auto 0;
  background-color: ${BLACK};
  height: 45px;
  line-height: 45px;
  color: ${WHITE};
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  display: block;

  :disabled {
    background-color: ${DARK_GREY};
  }

  span {
    font-size: 12px;
    font-weight: bold;
    vertical-align: middle;
  }
`;

export const PlaceholderWrapper = styled.div`
  margin: 0 ${MOBILE_PADDING}px 30px;

  ${media.tabletL`
    margin: 0 0 30px;
  `};
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.tabletL`
    flex-direction: row;
  `};
`;

export const QuestionWrapper = styled.div`
  margin-top: 1em;
  margin-right: 40px;
  flex: 1;
`;

export const AnswersWrapper = styled.div`
  margin-top: 2em;

  ${media.tabletL`
    min-width: 590px;
  `};
`;
