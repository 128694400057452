import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import config from '../../config';
import AvatarWrapper from './AvatarWrapper';
import AWS from 'aws-sdk/global';
import S3 from 'aws-sdk/clients/s3';
import { uuidv4, checkIfSameCertificate, makeCertificateData } from '../../utils';
import UserAgent from '../../utils/UserAgent';

import media from '../../styles/media.style';
import Badge from '../../assets/images/badge.png';
import BadgeLine from '../../assets/images/badge_line.png';
import { createCertificateService, getCertificateService } from '../../services/api';

import { config as cognito, state } from '../../redux/sagas/aws-cognito-redux-saga';
import Placeholder from '../../components/Placeholder';

import { checkPassword } from '../../redux/sagas/sns-auth-redux-saga/amplify.bridge';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.tabletL`
    flex-direction: row;
  `};
`;

const ProfileForm = styled.form`
  background: #fff;
  max-width: 648px;
  margin: 0 auto;
`;

const UserProfile = styled.div`
  flex: 1;
`;

const UserProfileName = styled.h3`
  font-size: 22px;
  font-weight: normal;
  margin-bottom: none;
  padding-right: 40px;
`;

const Divider = styled.hr`
  height: 1px;
  background: #d8d8d8;
  border: none;
  margin: 20px 0;
`;

const Label = styled.label`
  color: #4a4a4a;
  font-size: 11px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 4px;
`;

const Input = styled.input`
  &:disabled {
    border: none;
    border-bottom: solid 1px #fff;
    background: transparent;
    cursor: select;
  }

  border: none;
  border-bottom: solid 1px #000;
  display: inline;
  color: #4a4a4a;
  font-size: 18px;
`;

const FieldWrapper = styled.div`
  margin-bottom: 20px;
  position: relative;

  p {
    color: #4a4a4a;
    font-size: 18px;
    margin: 0;
  }

  a {
    color: #4a4a4a;
    font-size: 14px;
    text-decoration: underline;
  }

  .remarks {
    color: #4a4a4a;
    font-size: 14px;
    margin-top: 10px;
  }
`;

const EditButton = styled.button`
  -webkit-appearance: none;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 11px;
  cursor: pointer;
  border: none;
  border-bottom: solid 1px #000;
  background: transparent;
  float: ${({ float }) => (float ? (['left', 'right'].includes(float) ? float : 'right') : 'none')};
`;

const ChangePasswordLink = styled(Link)`
  text-decoration: none;
  border-bottom: solid 1px #000;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 11px;

  &:visited {
    color: #000;
  }
`;

const CancelButton = styled.button`
  cursor: pointer;
  width: 140px;
  height: 60px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  border: none;
  display: inline-block;
`;

const SubmitButton = styled.button`
  cursor: pointer;
  width: 140px;
  height: 60px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
`;

const CertWrappper = styled.div`
  display: flex;
  margin-bottom: 50px;
  width: 216px;

  :last-child {
    margin-bottom: 0;
  }
  ${media.tabletL`
    :nth-last-child(2),
    :nth-last-child(3) {
      margin-bottom: 0;
    }
  `};
`;

const CertTextWrappper = styled.div``;

const CompletedWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: flex-start;
  flex-wrap: wrap;

  p {
    font-size: 14px;
    margin-bottom: 0;
  }
`;

const SocialItemWrapper = styled.div`
  display:flex;
  margin-right:20px;
`

const BadgeImage = styled.img`
  width: 40px;
  height: 52px;
  display: inline-block;
  float: left;
  margin-right: 18px;
`;

const SectionTypeName = styled.div`
  font-size: 14px;
  margin-top: 29px;
  margin-bottom: 13px;
  :first-child {
    margin-top: 10px;
  }
`;

const DownloadLink = styled.a`
  display: block;
  background-color: black;
  color: white;
  cursor: pointer;
  font-size: 11px;
  text-align: center;
  height: 35px;
  width: 130px;
  line-height: 35px;
  &.disabled {
    cursor: default;
    opacity: 0.25;
  }
`;

const Dl = styled.dl`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
const Dt = styled.dt`
  width: 100px;
  margin-bottom: 10px;
`;
const Dd = styled.dd`
  width: calc(100% - 100px);
  margin-left: 0;
  margin-bottom: 10px;
`;
const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
`;
const SwitchCheckbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: #000;
  }

  &:focus + span {
    box-shadow: 0 0 1px #000;
  }

  &:checked + span:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`;
const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.8);
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const Modal = styled.div`
  background-color: #fff;
  position: fixed;
  padding: 80px 20px 20px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  ${media.tabletL`
    position: relative;
    padding: 6em 120px;
    width: auto;
    height: auto;
    top: auto;
    left: auto;
  `};
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  appearance: none;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 34px;
`;

const InputPassword = styled.input`
  border: 0;
  outline: none;
  border-bottom: 1px solid #000;
  padding: 8px 0;
  font-size: 17px;
  display: block;
  width: 100%;
`;

const InputLabel = styled.p`
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.5px;
  margin: 5px 0 40px;
`;

const SubmitPassword = styled.button`
  background-color: #000;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  appearance: none;
  width: 100%;
  display: block;
  color: #fff;
  padding: 20px;
`;

const ErrorMessage = styled.p`
  color: #f00;
  font-size: 14px;
`;

const Spinner = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;

  ::after {
    content: ' ';
    display: block;
    width: 10px;
    height: 10px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: spinner 1.2s linear infinite;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const DeleteAccountButton = styled(Link)`
  display: block;
  width: 100%;
  padding: 1rem;
  background-color: black;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
`;

class UserSettingsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditingName: false,
      isEditingJob: false,
      userAvatar: [],
      isUploading: false,
      password: '',
      passwordRetries: 0,
      passwordLoading: false,
      isSnsLinkedFacebook: this.isSnsLinked('Facebook'),
      isShowOverlay: false,
      isAppleCheckBox: false,
      errorMessage: '',
    };
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
  }

  UNSAFE_componentWillMount() {
    const {
      formData: { userId, job_title, family_name, given_name, avatar },
    } = this.props;
    this.setState({
      family_name,
      given_name,
      job_title,
      userId,
      avatarImage: [],
      initialAvatar: avatar,
    });
  }

  makeToggle = field => e => {
    e.preventDefault();
    this.setState({ [field]: !this.state[field] });
  };

  toggleEditName = this.makeToggle('isEditingName');
  toggleEditJob = this.makeToggle('isEditingJob');

  handleCancel = () => {
    this.setState({ isEditingJob: false, isEditingName: false });
  };

  handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  uploadImage = cb => {
    const { avatarImage } = this.state;
    const { auth } = this.props;

    if (avatarImage.length === 0) return;

    const loginKey = `cognito-idp.${process.env.REACT_APP_BUCKET_REGION}.amazonaws.com/${config.COGNITO_USERPOOL}`;

    AWS.config.region = process.env.REACT_APP_BUCKET_REGION;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      Logins: {
        [loginKey]: auth.info.idToken.jwtToken,
      },
    });

    // needs to be refreshed before using new credentials
    AWS.config.credentials.refresh(error => {
      if (error) {
        console.error(error);
      } else {
        const s3 = new S3({
          apiVersion: '2006-03-01',
        });

        let promises = [];
        if (avatarImage.length > 0) {
          promises = promises.concat(
            Promise.all(
              avatarImage.map(image => {
                let imageExtension = image.dataUri.split(';')[0].split('/');
                imageExtension = imageExtension[imageExtension.length - 1];
                return s3
                  .upload({
                    Key: `assets/${uuidv4()}.${imageExtension}`,
                    Body: image.arrayBuffer,
                    ACL: 'authenticated-read',
                    Bucket: process.env.REACT_APP_DYNAMIC_ASSETS_BUCKET,
                  })
                  .promise();
              })
            )
          );
        }

        if (promises.length > 0) {
          Promise.all(promises)
            .then(values => {
              const avatarImage = values[0];
              this.setState({
                isUploading: false,
              });
              cb(avatarImage);
              window.location.reload();
            })
            .catch(err => console.log('err: ', err));
        }
      }
    });
  };

  handleImage = dataUri => {
    this.setState(
      ({ avatarImage }) => ({
        avatarImage: avatarImage.concat(dataUri),
        userAvatar: dataUri.dataUri,
      }),
      () => {
        this.handleSaveImage();
      }
    );
  };

  handleSaveImage = () => {
    this.setState({
      isUploading: true,
    });
    this.uploadImage(userAvatar => {
      const imageKey = userAvatar[0].key;
      this.setState({ avatar: imageKey }, () => {
        this.updateUserData();
      });
    });
  };

  updateUserData = async () => {
    this.setState({ isEditingJob: false, isEditingName: false });
    const { userId, job_title, given_name, family_name, avatar } = this.state;
    const data = { userId, job_title, given_name, family_name, avatar };
    const token = await cognito.getSession();
    fetch(`${config.EXPRESS_API}/api/user`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: token.idToken.jwtToken,
      },
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(res => {
        if (res.hasOwnProperty('errors')) {
          console.log(res.errors);
        }
      });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.updateUserData();
  };

  openPDF = url => {
    const isApp = UserAgent.isApp();

    if (isApp && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(url);
      return;
    }

    const link = document.createElement('a');
    link.target = '_blank';
    link.href = url;
    link.click();
  }

  onClickDownload = async section => {
    const { auth, locale, user, userProgress, updateUserProgress } = this.props;

    if (auth.isSignedIn === state.AUTH_UNKNOWN || auth.isSignedIn === state.AUTH_FAIL) {
      return;
    }

    let userProgressData = userProgress.data;
    let progressObj = JSON.parse(userProgressData);
    if (!progressObj.certificates) {
      progressObj.certificates = [];
    }

    const savedCertificate = progressObj.certificates.find(checkIfSameCertificate({ locale, user, section }));
    const certificate = savedCertificate ? await getCertificateService(savedCertificate.key) : await createCertificateService(locale, user, section);

    if (!certificate.success) {
      return;
    }

    if (!savedCertificate) {
      progressObj.certificates.push({ ...makeCertificateData({ locale, user, section }), key: certificate.key });
      userProgressData = JSON.stringify(progressObj);
      updateUserProgress(auth.info.username, userProgressData);
    }

    this.openPDF(certificate.url);
  }

  isSnsLinked = providerName => {
    const { auth } = this.props;
    if (auth.info && auth.info.idToken && auth.info.idToken.payload && auth.info.idToken.payload.identities) {
      const identities = auth.info.idToken.payload.identities;
      if (identities.filter(i => i.providerName === providerName).length > 0) return true;
    }
    return false;
  };

  handleSnsLink = providerName => {
    const { snsAuth, locale, snsLinkRequest, snsLinkOffRequest, copy} = this.props;

    this.setState({
      passwordLoading: true,
    });

    if (this.isSnsLinked(providerName)) {
      snsLinkOffRequest({
        provider: providerName,
      });
      if (snsAuth.authState === 'SNS_LINK_DISABLED') {
        // alert('SNS LINK DISALBED.');
      }
      this.setState({
        passwordLoading: false,
      });
    } else {
      const { password } = this.state;
      if (!password || password.length === 0) {
        // alert('Please enter login password');
        this.setState({
          passwordLoading: false,
          errorMessage: copy.enter_login_password || 'Please enter login password',
        });
        return;
      }

      if (this.state.passwordRetries > config.SNS_LINK_PASSWD_CHECK_MAX_RETRIES) {
        // this.setState({ passwordRetries: 0 });
        // alert('Retry limit exceeded. Wait for a while before you try again');
        this.setState({
          passwordRetries: 0,
          passwordLoading: false,
          errorMessage: copy.retry_limit_exceeded || 'Retry limit exceeded. Wait for a while before you try again',
        });
        return;
      }

      setTimeout(() => {
        checkPassword(password).then(result => {
          if (result.success) {
            this.setState({
              passwordRetries: 0,
              passwordLoading: false,
            });

            snsLinkRequest({
              locale,
              provider: providerName,
              masterUser: { email: this.props.formData.email, password: this.state.password },
            });

          } else {
            this.setState({ passwordRetries: this.state.passwordRetries + 1 });
            if (result.error.code && result.error.code === 'LimitExceededException') {
              // alert('Retry limit exceeded. Wait for a while before you try again');
              this.setState({
                passwordLoading: false,
                errorMessage:
                  copy.retry_limit_exceeded || 'Retry limit exceeded. Wait for a while before you try again',
              });
            } else {
              // alert('Password incorrect. Please try again');
              this.setState({
                passwordLoading: false,
                errorMessage: copy.password_incorrect || 'Password incorrect. Please try again',
              });
            }
          }
        });
      }, this.state.passwordRetries === 0 ? 0 : config.SNS_LINK_PASSWD_CHECK_INITIAL_DELAY * 2 ** (this.state.passwordRetries - 1));
    }
  };

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value, errorMessage: '' });
  };

  handleChangeCheckbox = e => {
    if (this.isSnsLinked('Facebook')) {
      this.handleSnsLink('Facebook');
    } else {
      this.setState({
        isShowOverlay: true,
      });
    }
  };

  handleChangeCheckboxApple = e => {
    if (this.isSnsLinked('SignInWithApple')) {
      this.handleSnsLink('SignInWithApple');
    } else {
      this.setState({
        isShowOverlay: true,
        isAppleCheckBox: true,
      });
    }
  };

  closeModal = e => {
    this.setState({
      isShowOverlay: false,
      isAppleCheckBox: false,
    });
  };

  render() {
    const { formData, certificates, copy, locale, userAvatar, snsAuth } = this.props;
    const { country, createdAt, city, salon } = formData;
    const { isEditingName, isEditingJob, given_name, family_name, job_title, isUploading } = this.state;

    const date = new Date(createdAt).toDateString();
    console.log(copy);
    return (
      <React.Fragment>
        <ProfileForm onSubmit={this.updateUserData}>
          <Header>
            <AvatarWrapper
              userAvatar={userAvatar}
              updateImage={this.handleImage}
              saveImage={this.handleSaveImage}
              isUploading={isUploading}
            />

            <UserProfile>
              <FieldWrapper>
                {isEditingName ? (
                  <Fragment>
                    <div>
                      <Input name="given_name" type="text" value={given_name} onChange={this.handleChange} />
                      <br />
                      <Input name="family_name" type="text" value={family_name} onChange={this.handleChange} />
                    </div>
                    <EditButton onClick={this.toggleEditName}>{copy.cancel}</EditButton>
                  </Fragment>
                ) : (
                  <UserProfileName>
                    {given_name} {family_name} <EditButton onClick={this.toggleEditName}>{copy.edit}</EditButton>
                  </UserProfileName>
                )}
              </FieldWrapper>
              <small>
                {copy.member_since} {date}{' '}
              </small>
            </UserProfile>
          </Header>

          <Divider />
          <FieldWrapper>
            <Label>{copy.label_job_title}</Label>
            <Input
              name="job_title"
              type="text"
              value={job_title}
              disabled={!isEditingJob}
              onChange={this.handleChange}
            />
            {!isEditingJob && (
              <EditButton float onClick={this.toggleEditJob}>
                {copy.edit}
              </EditButton>
            )}
          </FieldWrapper>
          <FieldWrapper>
            <Label>{copy.label_salon}</Label>
            <p>{salon}</p>
          </FieldWrapper>

          <FieldWrapper>
            <Label>{copy.label_location}</Label>
            <p>
              {city}, {country}
            </p>
          </FieldWrapper>
          {/* メールアドレスログインしている場合はパスワードエリアを表示する */}
          {!snsAuth.snsLogin && (
            <FieldWrapper>
              <Label>{copy.label_password}</Label>
              <p>*********</p>
              <ChangePasswordLink to={`/${locale}/dashboard/settings/changepassword`}>
                {copy.change_password}
              </ChangePasswordLink>
            </FieldWrapper>
          )}

          {(isEditingJob || isEditingName) && (
            <FieldWrapper>
              <SubmitButton type="submit">{copy.submit}</SubmitButton>
              <CancelButton type="button" onClick={this.handleCancel}>
                {copy.cancel}
              </CancelButton>
            </FieldWrapper>
          )}

          <Divider />

          {/* If you are logged in with an email address, display the social collaboration area */}
          {/* メールアドレスログインしている場合はソーシャル連携エリアを表示する */}
          {!snsAuth.snsLogin && (
            <React.Fragment>
              <FieldWrapper class="display:flex">
                <Label>{copy.social_connections || <Placeholder w="100px" h="20px" />}</Label>
                <CompletedWrapper>
                <SocialItemWrapper>
                <Dl>
                  <Dt>{copy.facebook || <Placeholder w="80px" h="20px" />}</Dt>
                  <Dd>
                    <Switch>
                      <SwitchCheckbox
                        type="checkbox"
                        checked={this.isSnsLinked('Facebook')}
                        onChange={this.handleChangeCheckbox}
                      />
                      <SwitchSlider />
                    </Switch>
                  </Dd>
                </Dl>
                </SocialItemWrapper>
                <SocialItemWrapper>
                <Dl>
                  <Dt>{copy.apple || <Placeholder w="80px" h="20px" />}</Dt>
                  <Dd>
                    <Switch>
                      <SwitchCheckbox
                        type="checkbox"
                        checked={this.isSnsLinked('SignInWithApple')}
                        onChange={this.handleChangeCheckboxApple}
                      />
                      <SwitchSlider />
                    </Switch>
                  </Dd>
                </Dl>
                </SocialItemWrapper>
              </CompletedWrapper>
              </FieldWrapper>
              <Divider />
            </React.Fragment>
          )}

          <CompletedWrapper>
            {certificates.length ? (
              <React.Fragment>
                {certificates.map(c => (
                  <CertWrappper key={c.sectionTitle}>
                    {c.isCompleted ? <BadgeImage src={Badge} /> : <BadgeImage src={BadgeLine} />}
                    <CertTextWrappper>
                      <SectionTypeName>{c.sectionTitle}</SectionTypeName>
                      <DownloadLink
                        className={!c.isCompleted && 'disabled'}
                        onClick={e => {
                          e.preventDefault();
                          if (!c.isCompleted) return;
                          this.onClickDownload(c);
                        }}
                      >
                        {c.isCompleted ? copy.download_certificate : copy.download_notcompleted}
                      </DownloadLink>
                    </CertTextWrappper>
                  </CertWrappper>
                ))}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {[...new Array(2).keys()].map(i => (
                  <CertWrappper key={i}>
                    <Placeholder w="40px" h="52px" mr="18px" />
                    <CertTextWrappper>
                      <Placeholder w="55px" h="18px" mb="13px" mt="10px" />
                      <DownloadLink>
                        <Placeholder w="100%" h="100%" />
                      </DownloadLink>
                    </CertTextWrappper>
                  </CertWrappper>
                ))}
              </React.Fragment>
            )}
          </CompletedWrapper>

          {/* Display a link to the FAQ if you are logged in socially */}
          {/* ソーシャルログインしている場合はFAQへ誘導するリンクを表示する */}
          {snsAuth.snsLogin && (
            <React.Fragment>
              <Divider />
              <FieldWrapper>
                <a href={`/${locale}/faq`} target="_blank" rel="noopener noreferrer">
                  {copy.sns_disconnecting_general || <Placeholder w="100px" h="20px" />}
                </a>
              </FieldWrapper>
            </React.Fragment>
          )}

          <Divider />

          <DeleteAccountButton to={`/${locale}/dashboard/settings/delete-account`}>
            {copy.delete_account}
          </DeleteAccountButton>
        </ProfileForm>
        <Overlay visible={this.state.isShowOverlay}>
          <Modal>
            <CloseButton onClick={this.closeModal} type="button">
              ×
            </CloseButton>
            <p>{copy.password_reconfirmation || <Placeholder w="200px" h="30px" />}</p>
            <InputPassword
              name="password"
              type="password"
              onChange={this.handleChange}
              value={this.state.password}
              label="Master ID Password"
            />
            <InputLabel>password</InputLabel>
            {this.state.errorMessage && <ErrorMessage>{this.state.errorMessage}</ErrorMessage>}
            <SubmitPassword type="button" onClick={() => {
              if (this.state.isAppleCheckBox) {
                this.handleSnsLink('SignInWithApple');
              } else {
                this.handleSnsLink('Facebook');
              }
            }}>
              {this.state.passwordLoading ? <Spinner /> : copy.sns_cooperation || <Placeholder w="100px" h="20px" />}
            </SubmitPassword>
          </Modal>
        </Overlay>
      </React.Fragment>
    );
  }
}

UserSettingsForm.defaultProps = {
  copy: {
    member_since: 'Member since',
    change_password: 'Change password',
    edit: 'Edit',
    cancel: 'Cancel',
    submit: 'Submit',
    label_password: 'Password',
    label_location: 'Location',
    label_salon: 'Salon',
    label_job_title: 'Job Title',
    download_certificate: 'Download certificate',
    download_notcompleted: 'Not Completed',
  },
};

export default UserSettingsForm;
