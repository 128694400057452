import { GET_USER_PROGRESS, UPDATE_USER_PROGRESS, CLEAR_USER } from '../constants';

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_USER_PROGRESS}_PENDING`:
      return state;
    case `${GET_USER_PROGRESS}_FULFILLED`: {
      const data = action.payload;
      return {
        ...state,
        data,
      };
    }
    case `${GET_USER_PROGRESS}_REJECTED`:
      return state;
    case `${UPDATE_USER_PROGRESS}_PENDING`:
      return state;
    case `${UPDATE_USER_PROGRESS}_FULFILLED`: {
      const data = action.payload;
      return {
        ...state,
        data,
      };
    }
    case `${UPDATE_USER_PROGRESS}_REJECTED`:
      return state;
    case `${CLEAR_USER}`: {
      return {};
    }
    default:
      return state;
  }
};
