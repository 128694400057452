import styled from 'styled-components';
import { DARK_GREY, WHITE, BLACK, LIGHT_GREY } from '../../styles/variables.style';
import media from '../../styles/media.style';

export const Wrapper = styled.div`
  position: relative;
  box-shadow: ${({ open }) => (open ? '1px 0 5px 0 rgba(0, 0, 0, 0.2)' : 'none')};
  width: 32%;
  :not(:last-child) {
    margin-right: 1.3%;
  }
`;

export const Title = styled.p`
  font-size: 18px;
  margin-bottom: 0.8em;
  letter-spacing: 1px;
`;

export const Button = styled.button`
  cursor: pointer;
  position: relative;
  background-color: ${({ open }) => (open ? WHITE : BLACK)};
  border: none;
  font-size: 16px;
  font-weight: 800;
  height: 57px;
  padding: 0 20px;
  line-height: 57px;
  text-transform: uppercase;
  color: ${({ open }) => (open ? BLACK : WHITE)};
  width: 100%;
  text-align: left;
  letter-spacing: 1px;
  transition: background-color 0.3s, color 0.3s;
  z-index: 99999;
  white-space: nowrap;

  ${media.tabletL`
    height: 85px;
    line-height: 85px;
    padding: 0 167px 0 40px;
  `};

  span {
    margin-right: 50px;
    font-weight: 800;
    font-size: 12px;
    letter-spacing: 1px;

    ${media.tabletL`
      font-size: 16px;
    `};
  }

  ::before {
    content: '';
    position: absolute;
    top: 47%;
    right: 20px;
    transform: translateY(-50%) rotate(${({ open }) => (open ? '-45deg' : '135deg')});
    transform-origin: 10px 6px;
    width: 12px;
    height: 12px;
    border-top: 2px solid ${({ open }) => (open ? BLACK : WHITE)};
    border-right: 2px solid ${({ open }) => (open ? BLACK : WHITE)};
    transition: transform 0.3s;

    ${media.tabletL`
      right: 40px;
    `};
  }
`;

export const List = styled.ul`
  ${'/* first seminar card */'};
  position: absolute;
  background-color: ${LIGHT_GREY};
  left: 0;
  max-height: ${({ open }) => (open ? '383px' : '0')};
  transition: max-height 0.5s;
  list-style: none;
  width: 100%;
  overflow: auto;
  box-shadow: ${({ open }) => (open ? '1px 0 5px 0 rgba(0, 0, 0, 0.2)' : 'none')};
  z-index: 99998;

  li {
    :first-child button {
      padding-top: 2em;
    }
    :last-child button {
      padding-bottom: 2em;
    }
  }
`;

export const ListItem = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${BLACK};
  width: 100%;
  text-align: left;
  border: none;
  padding: 1em 30px;
  font-size: 14px;
  text-transform: uppercase;
  background-color: ${LIGHT_GREY};


  > span:last-child {
    white-space: pre-wrap;
    word-break: break-all;
  }

  > span:first-child {
    background-color: ${({ selected }) => (selected ? BLACK : WHITE)};
  }

  :hover {
    > span:first-child {
      background-color: ${({ selected }) => (selected ? BLACK : DARK_GREY)};
    }
  }
`;

export const Checkmark = styled.span`
  display: inline-block;
  position: relative;
  width: 25px;
  height: 25px;
  border: 1px solid ${BLACK};
  margin-right: 12px;
  border-radius: 50%;
  flex-shrink: 0;

  ::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%) rotate(-45deg);
    border-bottom: 1px solid ${WHITE};
    border-left: 1px solid ${WHITE};
    width: 8px;
    height: 4px;
  }
`;

export const SelectedOptionsList = styled.ul`
  position: absolute;
  top: 95px;
  left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
  overflow: hidden;

  li {
    margin-bottom: 5px;
  }
`;

export const SelectedOption = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  max-height: 22px;
  overflow: hidden;
  display: flex;

  svg {
    flex-shrink: 0;
    margin-right: 3px;
  }

  span {
    display: inline-block;
    line-height: 11px;
    white-space: pre-wrap;
    vertical-align: text-bottom;
    word-break: break-all;
  }
`;
