import { connect } from 'react-redux';

import { reducer } from '../../../redux/sagas/aws-cognito-redux-saga';

import ResetPasswordComponent from './ResetPassword';

const mapStatetoProps = state => ({
  auth: state.auth,
  copy: state.siteOptions.siteCopyStylistToolSignUpPage,
  global_copy: state.siteOptions.siteCopyELearningGlobal,
  locale: state.locale,
});

const mapDispatchToProps = dispatch => ({
  init: () => {
    dispatch(reducer.init());
  },
  forgotPassword: username => {
    dispatch(reducer.forgotPassword(username));
  },
});

export default connect(mapStatetoProps, mapDispatchToProps)(ResetPasswordComponent);
