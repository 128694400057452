import React, { Component } from 'react';
import styled from 'styled-components';
import { uniqueId } from 'lodash';

const VIDEOTAG_ID = 'yt-api';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

class YoutubeVideoPlayer extends Component {
  constructor(props) {
    super(props);

    this.elementId = uniqueId('ytplayer-');
  }

  componentDidMount() {
    if (document.getElementById(VIDEOTAG_ID)) {
      this.initYoutube(this.props.videoId);
    } else {
      this.loadYoutubeAPI(this.props.videoId);
    }
  }

  componentWillReceiveProps(props) {
    if (props.videoId === this.props.videoId) return;
    if (this.player) {
      this.player.destroy();
      this.initYoutube(props.videoId);
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.destroy();
    }
  }

  onPlayerStateChange = e => {
    const { YT } = window;
    const { onEnded } = this.props;

    switch (e.data) {
      case YT.PlayerState.ENDED:
        if (typeof onEnded === 'function') {
          onEnded();
        }
        break;
      case YT.PlayerState.PAUSED:
        // this.props.onPause();
        break;
      case YT.PlayerState.PLAYING:
        // this.props.onPlay();
        break;
      default:
        break;
    }
  };

  onError = e => {
    if (process.env.NODE_ENV === 'development') {
      console.log('[Youtube Error]', e);
    }
  };

  initYoutube = videoId => {
    this.player = new window.YT.Player(this.elementId, {
      height: '100%',
      width: '100%',
      videoId: videoId,
      playerVars: {
        rel: 0
      },
      origin: window.location.origin,
      events: {
        onStateChange: this.onPlayerStateChange,
        onError: this.onError,
      },
    });
  };

  loadYoutubeAPI = videoId => {
    let tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    tag.id = VIDEOTAG_ID;
    let firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    const onYouTubePlayerAPIReady = () => {
      this.initYoutube(videoId);
    };
    window.onYouTubePlayerAPIReady = onYouTubePlayerAPIReady;
  };

  render() {
    return (
      <Container>
        <div id={this.elementId} />
      </Container>
    );
  }
}

export default YoutubeVideoPlayer;
