import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import TextField from '../../../components/TextField/TextField';
import { initialValidatorObject, updateValidatorObject } from '../../../utils';

import { Container, Title, Form, Button } from './ChangePassword.style';

const required = copy => value => (value ? undefined : copy.requiredField || 'Required');
const minLength = copy => value => (value.length >= 6 ? undefined : `${copy.minLength} || Min Length} 6`);
const v_translate = {
  requiredField: required,
  minLength,
};

class ChangePasswordForm extends Component {
  static defaultProps = {
    copy: {
      change_password: 'Change Password',
      back: 'Back',
    },
  };

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    auth: PropTypes.shape().isRequired,
    changePassword: PropTypes.func.isRequired,
  };

  state = {};

  componentDidMount() {
    this.setState(initialValidatorObject(v_translate, this.props));
  }

  componentWillReceiveProps(props) {
    const update = updateValidatorObject(v_translate, this.props, props);
    if (update) {
      this.setState(update);
    }
  }

  render() {
    const { handleSubmit, changePassword, copy } = this.props;
    const { requiredField, minLength } = this.state;
    return (
      <Container>
        <Title>{copy.change_password}</Title>
        <Form>
          <Field
            name="password"
            validate={[requiredField, minLength].filter(it => it)}
            component={TextField}
            type="password"
            placeholder="••••••••••"
            label={copy.oldPasswordField || 'Old Password'}
          />

          <Field
            name="passwordMatch"
            validate={[requiredField, minLength].filter(it => it)}
            component={TextField}
            type="password"
            placeholder="••••••••••"
            label={copy.newPasswordField || 'New Password'}
          />

          <Button onClick={handleSubmit(changePassword)}>{copy.change_password}</Button>
        </Form>
      </Container>
    );
  }
}

export default ChangePasswordForm;
