import * as action from './actions';
import * as state from './states';

export const init = () => ({
  type: action.AUTH_INIT,
  payload: {},
});

export const getUser = () => ({
  type: action.AUTH_GET_USER,
});

export const signUp = (username, password, attributes) => ({
  type: action.AUTH_SIGN_UP,
  payload: {
    username,
    password,
    attributes,
  },
});

export const signIn = (username, password, code) => ({
  type: action.AUTH_SIGN_IN,
  payload: {
    username,
    password,
    code,
  },
});

export const signOut = () => ({
  type: action.AUTH_SIGN_OUT,
});

export const forgotPassword = username => ({
  type: action.AUTH_FORGOT_PASSWORD,
  payload: {
    username,
  },
});

export const changePassword = (username, code, password) => ({
  type: action.AUTH_CHANGE_PASSWORD,
  payload: {
    username,
    code,
    password,
  },
});

export const completeNewPassword = (username, password) => ({
  type: action.AUTH_COMPLETE_NEW_PASSWORD,
  payload: {
    username,
    password,
  },
});

const ACTION_HANDLERS = {
  [action.AUTH_SET_STATE]: (state, action) => ({ ...state, ...action.payload }),
  [action.AUTH_INIT]: (state, action) => ({ ...state, ...action.payload }),
};

const defaultState = {
  isSignedIn: state.AUTH_UNKNOWN,
};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
