import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Section, SectionInner, HeroTitle, Subtitles, HeroSubtitle, Text, CTA, DownloadButtons, GooglePlayButton, GooglePlayLogo, AppStoreButton, AppStoreLogo } from './Hero.style';
import UserAgent from '../../../utils/UserAgent';

const Hero = ({ lang, copy, i18n }) => (
  <Section>
    <SectionInner>
      <HeroTitle className={UserAgent.isWeb() ? 'has-download-buttons' : ''}>
        <span
          dangerouslySetInnerHTML={{
            __html: (copy.homepageTitle || 'Welcome to\nShiseido Professional\nE-Learning').replace(
              /(\\n|\n)/g,
              '<br />'
            ),
          }}
        />
      </HeroTitle>
      {UserAgent.isWeb() ?
        <DownloadButtons>
          <div>
            <GooglePlayButton href={i18n[lang]._google_play_app_url}>
              <GooglePlayLogo src={i18n[lang]._google_play_badge_url} alt="Get it on Google Play" />
            </GooglePlayButton>
          </div>
          <div>
            <AppStoreButton href={i18n[lang]._app_store_app_url}>
              <AppStoreLogo src={i18n[lang]._app_store_badge_url} alt="Download on the App Store" />
            </AppStoreButton>
          </div>
        </DownloadButtons>
      : ''}
      <Subtitles>
        <HeroSubtitle>
          <Text>
            <h2>{copy.step_by_step_videos}</h2>
            <p>{copy.step_by_step_description}</p>
          </Text>
        </HeroSubtitle>
        <HeroSubtitle>
          <Text>
            <h2>{copy.learn_at_your_pace}</h2>
            <p>{copy.learn_at_your_pace_description}</p>
          </Text>
        </HeroSubtitle>
        <HeroSubtitle>
          <Text>
            <h2>{copy.discover_your_expertise}</h2>
            <p>{copy.discover_your_expertise_description}</p>
          </Text>
        </HeroSubtitle>
      </Subtitles>
    </SectionInner>
    <CTA>
      <Link to={`/${lang}/login`} href={`/${lang}/login`}>
        {copy.login}
      </Link>
    </CTA>
  </Section>
);

Hero.defaultProps = {
  copy: {
    welcome_header: 'Welcome to Shiseido Professional E-Learning',
    step_by_step_videos: 'Step by step videos',
    step_by_step_description: 'Step by step description',
    learn_at_your_pace: 'Learn at your own pace',
    learn_at_your_pace_description: 'Learn at your own pace description',
    discover_your_expertise: 'Discover your expertise',
    discover_your_expertise_description: 'Discover your expertise description',
    login: 'Login',
  },
  i18n: {
    en: {
      _app_store_app_url: 'https://apps.apple.com/sg/app/steps-by-shiseido-pro/id1578465832?itsct=apps_box_badge&amp;itscg=30200',
      _app_store_badge_url: 'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1647216000&h=7f69e7c9f2d75e81eb25c3391e98f814',
      _google_play_app_url: 'https://play.google.com/store/apps/details?id=com.jp.co.shiseido.steps&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
      _google_play_badge_url: 'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
    },
    en_hk: {
      _app_store_app_url: 'https://apps.apple.com/hk/app/steps-by-shiseido-pro/id1578465832?itsct=apps_box_badge&amp;itscg=30200',
      _app_store_badge_url: 'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1647216000&h=7f69e7c9f2d75e81eb25c3391e98f814',
      _google_play_app_url: 'https://play.google.com/store/apps/details?id=com.jp.co.shiseido.steps&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
      _google_play_badge_url: 'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
    },
    zh_hk: {
      _app_store_app_url: 'https://apps.apple.com/hk/app/steps-by-shiseido-pro/id1578465832?itsct=apps_box_badge&amp;itscg=30200',
      _app_store_badge_url: 'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/zh-hk?size=250x83&amp;releaseDate=1647216000&h=5c785649da861f11b46550df7372aded',
      _google_play_app_url: 'https://play.google.com/store/apps/details?id=com.jp.co.shiseido.steps&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
      _google_play_badge_url: 'https://play.google.com/intl/en_us/badges/static/images/badges/zh-hk_badge_web_generic.png'
    },
    ja_jp: {
      _app_store_app_url: 'https://apps.apple.com/jp/app/steps-by-shiseido-pro/id1578465832?itsct=apps_box_badge&amp;itscg=30200',
      _app_store_badge_url: 'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/ja-jp?size=250x83&amp;releaseDate=1647216000&h=ac579264cb12164dfe798d3651c3a018',
      _google_play_app_url: 'https://play.google.com/store/apps/details?id=com.jp.co.shiseido.steps&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
      _google_play_badge_url: 'https://play.google.com/intl/en_us/badges/static/images/badges/ja_badge_web_generic.png'
    },
    ko_kr: {
      _app_store_app_url: 'https://apps.apple.com/kr/app/steps-by-shiseido-pro/id1578465832?itsct=apps_box_badge&amp;itscg=30200',
      _app_store_badge_url: 'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/ko-kr?size=250x83&amp;releaseDate=1647216000&h=f9ca45d494098db9b2efe118c4f011d6',
      _google_play_app_url: 'https://play.google.com/store/apps/details?id=com.jp.co.shiseido.steps&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
      _google_play_badge_url: 'https://play.google.com/intl/en_us/badges/static/images/badges/ko_badge_web_generic.png'
    },
    zh_my: {
      _app_store_app_url: 'https://apps.apple.com/my/app/steps-by-shiseido-pro/id1578465832?itsct=apps_box_badge&amp;itscg=30200',
      _app_store_badge_url: 'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/zh-cn?size=250x83&amp;releaseDate=1647216000&h=6a18fc06d699d535437d7e388690bfbe',
      _google_play_app_url: 'https://play.google.com/store/apps/details?id=com.jp.co.shiseido.steps&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
      _google_play_badge_url: 'https://play.google.com/intl/en_us/badges/static/images/badges/zh-cn_badge_web_generic.png'
    },
    en_sg: {
      _app_store_app_url: 'https://apps.apple.com/sg/app/steps-by-shiseido-pro/id1578465832?itsct=apps_box_badge&amp;itscg=30200',
      _app_store_badge_url: 'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/zh-cn?size=250x83&amp;releaseDate=1647216000&h=6a18fc06d699d535437d7e388690bfbe',
      _google_play_app_url: 'https://play.google.com/store/apps/details?id=com.jp.co.shiseido.steps&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
      _google_play_badge_url: 'https://play.google.com/intl/en_us/badges/static/images/badges/zh-cn_badge_web_generic.png'
    },
    zh_tw: {
      _app_store_app_url: 'https://apps.apple.com/tw/app/steps-by-shiseido-pro/id1578465832?itsct=apps_box_badge&amp;itscg=30200',
      _app_store_badge_url: 'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/zh-hk?size=250x83&amp;releaseDate=1647216000&h=5c785649da861f11b46550df7372aded',
      _google_play_app_url: 'https://play.google.com/store/apps/details?id=com.jp.co.shiseido.steps&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
      _google_play_badge_url: 'https://play.google.com/intl/en_us/badges/static/images/badges/zh-tw_badge_web_generic.png'
    },
    th_th: {
      _app_store_app_url: 'https://apps.apple.com/th/app/steps-by-shiseido-pro/id1578465832?itsct=apps_box_badge&amp;itscg=30200',
      _app_store_badge_url: 'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/th-th?size=250x83&amp;releaseDate=1647216000&h=78603c460294d82f2c5d545a6b39852c',
      _google_play_app_url: 'https://play.google.com/store/apps/details?id=com.jp.co.shiseido.steps&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
      _google_play_badge_url: 'https://play.google.com/intl/en_us/badges/static/images/badges/th_badge_web_generic.png'
    }
  }
};

Hero.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Hero;
