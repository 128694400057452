import { connect } from 'react-redux';

import { reducer } from '../../redux/sagas/aws-cognito-redux-saga';

import HeaderComponent from './Header';
import { fetchUser } from '../../redux/actions/user';

const mapStatetoProps = state => ({
  auth: state.auth,
  user: state.user,
  copy: state.siteOptions.siteCopyELearningGlobal,
});

const mapDispatchToProps = dispatch => ({
  signedIn: () => {
    dispatch(reducer.signedIn());
  },
  signOut: () => {
    dispatch(reducer.signOut());
  },
  signIn: (username, password) => {
    dispatch(reducer.signIn(username, password));
  },
  fetchUser: username => dispatch(fetchUser(username)),
});

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(HeaderComponent);
