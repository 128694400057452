import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import App from './App';
import IdpRedirectPage from './pages/IdpRedirect/IdpRedirectPage';

/**
 * Root Component
 * * It takes care of locale redirects based on routes from App
 */
const Root = ({ store, persistor }) => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/idpredirect" component={IdpRedirectPage} />
          <Route path="/zh_sg" children={({ location }) => <Redirect to={`/en_sg${location.pathname.replace(/\/zh_sg/, '')}`} />} />
          <Route path="/:lang(ja_jp|zh_cn|ko_kr|en|en_id|en_hk|th_th|zh_hk|zh_tw|en_sg|zh_my)" component={App} />
          <Route
            path="/:category/:type/:id"
            render={({ match }) => (
              <Redirect to={`/en/${match.params.category}/${match.params.type}/${match.params.id}`} />
            )}
          />
          <Route
            path="/:type/:id"
            render={({ match }) => <Redirect to={`/en/${match.params.type}/${match.params.id}`} />}
          />
          <Route path="/:type/" render={({ match }) => <Redirect to={`/en/${match.params.type}`} />} />
          <Redirect to="/en" />
        </Switch>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired // eslint-disable-line
};

export default Root;
