import { Link } from 'react-router-dom';
import React from 'react';
import Placeholder from '../Placeholder';

import {
  Button,
  Card,
  ThumbnailWrapper,
  ModuleTitle,
  ModuleSubtitle,
  ModuleSubtitleText,
  ModuleSubtitleInfo,
  Thumbnail,
  CardContent,
  ProgressWrapper,
  Label,
  ActiveBorder,
  Circle,
  PlaceholderWrapper,
} from './ModuleCard.style';

const LinkWrapperStyles = {
  display: 'flex',
  textDecoration: 'none',
  height: '100%',
  padding: '1.2em',
  color: '#000',
};

const ModuleCard = ({ content, progress, locale, copy }) => {
  if (!content.title) {
    return (
      <PlaceholderWrapper>
        <Placeholder w="100%" h="230px" />
      </PlaceholderWrapper>
    );
  }

  const { finishChapterLength, memberOnlyChaptersLength } = content.modules.reduce((acc, m) => {
    const memberOnlyChapters = m.moduleChapters.filter(c => c.memberflag !== false);
    let currentModuleProgress;
    const moduleProgress = JSON.parse(progress);
    currentModuleProgress = moduleProgress.chapters.find(item => item.name === m.moduleSlug);

    let finishChapterLength = 0;
    if (currentModuleProgress) {
      const completedChapterNumbers = currentModuleProgress.completed;
      finishChapterLength = memberOnlyChapters.filter(c => completedChapterNumbers.includes(c.chapterNumber))
        .length;
    }
    return {
      finishChapterLength: acc.finishChapterLength + finishChapterLength,
      memberOnlyChaptersLength: acc.memberOnlyChaptersLength + memberOnlyChapters.length
    };
  }, { finishChapterLength: 0, memberOnlyChaptersLength: 0 });

  const percentage = (finishChapterLength * 100) / memberOnlyChaptersLength;
  const deg = percentage * 3.6;

  return (
    <Card>
      <Link
        to={{
          pathname: `/${locale}/course/${content.slug}`,
        }}
        style={LinkWrapperStyles}
      >
        <CardContent>
          <ModuleTitle>{content.title}</ModuleTitle>
          <ModuleSubtitle>
            <ModuleSubtitleText>{`${content.category} / ${content.level}`}</ModuleSubtitleText>
            <ModuleSubtitleInfo>
              {content.infoList.map((info, i) => (<React.Fragment>{info}{(i < content.infoList.length - 1) && <span>|</span>}</React.Fragment>))}
            </ModuleSubtitleInfo>
          </ModuleSubtitle>
          <Button>{!percentage ? copy.learn : copy.resume}</Button>
        </CardContent>
        <ThumbnailWrapper>
          {!percentage ? (
            <Thumbnail url={content.thumbnailUrl || '//placekitten.com/300/200'} />
          ) : (
            <ProgressWrapper>
              <ActiveBorder deg={deg}>
                <Circle>
                  <Label>{parseInt(percentage, 10)}%</Label>
                </Circle>
              </ActiveBorder>
            </ProgressWrapper>
          )}
        </ThumbnailWrapper>
      </Link>
    </Card>
  );
};

ModuleCard.defaultProps = {
  progress: null,
  percentage: null,
  copy: {
    chapters: 'chapters',
    minutes: 'mins',
    learn: 'learn',
    resume: 'resume',
  },
};

export default ModuleCard;
