import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import fetchModules from '../../redux/actions/modules';
import { fetchStylistsProgressService, fetchUserProgressService } from '../../services/api';
import { SCREEN_LOCKUP_WIDTH, MOBILE_PADDING } from '../../styles/variables.style';
import { List, Title, HorizontalLine } from '../Dashboard/ModuleList/ModuleList';
import ModuleCard from '../../components/ModuleCardProgress';
import Placeholder from '../../components/Placeholder';

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: ${SCREEN_LOCKUP_WIDTH + MOBILE_PADDING * 2}px;
  padding: ${MOBILE_PADDING}px;
`;

const PageTitle = styled.h1`
  font-size: 3em;
`;

const ListWrapper = styled.div`
  background: #e4e1e2;
  padding: ${MOBILE_PADDING}px 0;
`;

const SearchBar = styled.input`
  background: transparent;
  border: none;
  border-bottom: solid 2px #000;
  width: 100%;
  font-size: 1.6em;
  padding: 5px 5px 5px 0;
  outline: none;
`;

class ProgressPage extends Component {
  static defaultProps = {
    copy: {
      search: 'Search',
      progressPageTitle: 'Stylist Progress',
    },
    siteOptions: {},
  };

  state = {};

  componentDidMount = () => {
    const {
      modules,
      fetchModules,
      match: {
        params: { lang },
      },
    } = this.props;

    if (modules.length === 0) {
      fetchModules({ limit: 15, order: 'fields.moduleTitle' }, lang);
    }

    this.checkAndFetchStylistsProgress();
  };

  componentDidUpdate(props) {
    this.checkAndFetchStylistsProgress(props);
  }

  checkAndFetchStylistsProgress = async (props = this.props) => {
    const { stylists } = this.state;
    const { auth } = this.props;
    if (!stylists && auth && auth.info && auth.info.username) {
      try {
        this.setState(await fetchStylistsProgressService(auth.info.username), () => {
          this.state.stylists.forEach(async stylist => {
            this.setState({ [stylist.userId]: await fetchUserProgressService(stylist.userId) });
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  render() {
    const {
      modules,
      sections,
      match: {
        params: { lang },
      },
      user: { role },
      copy,
      siteOptions,
    } = this.props;
    const copyDashboard = siteOptions.siteCopyELearningDashboardPage || {};
    const { stylists, searchTerms } = this.state;
    const searchMatch = searchTerms && searchTerms.length > 0 && new RegExp(searchTerms, 'i');
    const locale = lang;
    const contents =
      sections.filter(s => s.modules.length).map(section => {
          return {
            key: section.slug,
            link: section.modules.length === 1 ?
              `/${locale}/course/${section.slug}/${section.modules[0].moduleSlug}` :
              `/${locale}/course/${section.slug}`,
            thumbnailUrl: section.thumbnail ? section.thumbnail.file.url : null,
            category: section.category,
            level: section.level,
            contents: section.contents,
            title: section.sectionTitle,
            infoList: [
              `${section.modules.length} ${section.modules.length === 1 ? copyDashboard.module : copyDashboard.modules} `,
              ` ${section.modules.reduce((acc, m) => acc + m.moduleChapters.filter(c => c.memberflag !== false).length, 0)} ${copyDashboard.chapters} `,
              ` ${section.sectionDuration} ${copyDashboard.minutes}`,
            ],
            ...section,
          }
        });

    return (
      <Fragment>
        <Wrapper>
          <PageTitle>{copy.progressPageTitle}</PageTitle>
        </Wrapper>
        <ListWrapper>
          <Wrapper>
            <Title>{copy.search || 'Search'}</Title>
            <SearchBar
              type="search"
              value={searchTerms}
              onChange={({ target: { value } }) => this.setState({ searchTerms: value })}
            />
          </Wrapper>
          {stylists ? (
            stylists
              .filter(
                stylist =>
                  searchMatch
                    ? ['family_name', 'given_name']
                        .concat(role > 1 ? ['salon'] : [])
                        .some(it => stylist[it].match(searchMatch))
                    : true
              )
              .map(stylist => (
                <Wrapper key={`${stylist.family_name}-${stylist.given_name}-${stylist.salon}`}>
                  <Title>
                    {role > 1 && `${stylist.salon} - `} {stylist.family_name} {stylist.given_name}
                  </Title>
                  <HorizontalLine small />
                  {contents && contents.length > 0 && this.state[stylist.userId] ? (
                    <List>
                      {contents.map(content => (
                        <li key={content.key}>
                          <ModuleCard
                            locale={lang}
                            content={content}
                            progress={
                              this.state[stylist.userId] && normalizeProgress(modules, this.state[stylist.userId])
                            }
                          />
                        </li>
                      ))}
                    </List>
                  ) : (
                    <List>
                      <li>
                        <Placeholder h="200px" w="300px" />
                      </li>
                      <li>
                        <Placeholder h="200px" w="300px" />
                      </li>
                      <li>
                        <Placeholder h="200px" w="300px" />
                      </li>
                    </List>
                  )}
                </Wrapper>
              ))
          ) : (
            <Wrapper>
              <Title>
                <Placeholder h="20px" w="250px" />
              </Title>
              <HorizontalLine />
              <List>
                <li>
                  <Placeholder h="200px" w="300px" />
                </li>
                <li>
                  <Placeholder h="200px" w="300px" />
                </li>
                <li>
                  <Placeholder h="200px" w="300px" />
                </li>
              </List>
            </Wrapper>
          )}
        </ListWrapper>
      </Fragment>
    );
  }
}

const normalizeProgress = (modules, progress) => {
  let partialProgress = [];
  if (progress) {
    partialProgress = JSON.parse(progress).chapters || [];
  }
  const output = JSON.stringify({
    chapters: modules.map(
      module => partialProgress.find(it => it.name === module.moduleSlug) || { name: module.moduleSlug, completed: [] }
    ),
  });
  return output;
};

const mapState = ({ auth, user, modules, sections, siteOptions }) => ({
  auth,
  user,
  modules,
  sections,
  siteOptions,
});

const mapDispatch = dispatch => ({
  fetchModules: (options, locale) => dispatch(fetchModules(options, locale)),
});

export default connect(
  mapState,
  mapDispatch
)(ProgressPage);
