/**
 * Bridging module provides thin wrappers for all Amplify functions used in our app.
 */

import { Auth } from 'aws-amplify';

export const getCurrentUser = async () => {
  return Auth.currentAuthenticatedUser()
    .then(userData => userData)
    .catch(() => console.log('Not signed in'));
};

export const getCurrentSession = async () => {
  return Auth.currentSession()
    .then(sessionData => sessionData)
    .catch(error => console.log(error));
};

export const signIn = async (email, password) => {
  try {
    return await Auth.signIn(email, password);
  } catch (error) {
    console.log('error signing in', error);
  }
};

export const snsLogin = providerName => {
  return Auth.federatedSignIn({ provider: providerName });
};

export const snsLogout = () => {
  return Auth.signOut();
};

export const signOut = () => {
  return Auth.signOut();
};

export const signUp = async ({ email, password, customAttributes }) => {
  return await Auth.signUp({
    username: email,
    password,
    attributes: {
      ...customAttributes,
    },
  });
};

export const refreshCurrentUserProperties = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    return user;
  } catch (error) {
    console.log('error currentAuthenticateUser: ', error);
  }
};

export const checkPassword = async password => {
  return await Auth.currentAuthenticatedUser()
    .then(user => {
      return Auth.changePassword(user, password, password);
    })
    .then(data => {
      return { success: true };
    })
    .catch(err => {
      return { success: false, error: err };
    });
};
