import { Component } from 'react';
import { withRouter } from 'react-router';

class GlobalScrollRestore extends Component {
  componentDidUpdate(props) {
    if (this.props.location !== props.location) {
      window.scrollTo({
        top: 0,
        behavior: (props.smooth && 'smooth') || 'auto',
      });
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(GlobalScrollRestore);
