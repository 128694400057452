import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import promiseMiddleware from 'redux-promise-middleware';

import rootReducer from './reducers';
import sagas from './sagas';
import { getScreenSize } from '../utils';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

let middleware = [promiseMiddleware(), sagaMiddleware];

if (process.env.NODE_ENV !== 'production') {
  const reduxLogger = require("redux-logger"); // eslint-disable-line
  const { createLogger } = reduxLogger;
  const loggerMiddleware = createLogger();
  middleware = [...middleware, loggerMiddleware];
}

export const configureStore = initialState =>
  createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middleware)));

const initialState = {
  modules: [],
  quizzes: {},
  screenSize: getScreenSize(),
};

export const store = configureStore(initialState);
sagaMiddleware.run(sagas);
export const persistor = persistStore(store);
