import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import media from '../../styles/media.style';

// import '../../styles/grid.css';

const Wrapper = styled.li`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 20px;
  background: #fff;
`;

const Position = styled.div`
  sup {
    font-size: 9px;
    letter-spacing: 0.9px;
    font-weight: 900;

    ${media.tabletL`
      font-size: 12px;
    `};
  }
`;

const Title = styled.div`
  padding: ${props => (props.positionEmpty ? '20px' : '0')} 0 20px 0;
  a {
    padding-top: 20px;
    line-height: 1.18;
    font-weight: bold;
    font-size: 12px;
    color: #000;
    text-decoration: none;
    text-transform: uppercase;

    ${media.tabletL`
      font-size: 22px;
    `};
  }
`;

const Description = styled.div`
  display: none;
  flex: 2 0;
  padding: 30px 0;


  p {
    line-height: 1.28;
    letter-spacing: 0.2px;
    font-size: 18px;
    color: #000000;
    padding: 0 20px;
    margin: 0;
    overflow: hidden;
  }

  ${media.tabletL`
    display: block;
  `};
`;

const IconPlay = styled.span`
  display: block;
  width: 52px;
  height: 52px;
  background: url('/images/play-button.svg') no-repeat 0 0;
`;

const LeftItem = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
  width: 66%;
  justify-content: flex-start;
  align-items: stretch;
`;

const CheckmarkWrapper = styled.div`
  width: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  ${media.tabletL`
    width: 90px;
  `};
`;

const TitleWrapper = styled.div`
  position: relative;
  padding: 17px 13px 17px 0;
  display: flex;
  flex-direction: column;

  ${media.tabletL`
    width: 300px;
    padding: 30px 34px 30px 0;
  `};
`;

const VideoThumbnail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0 !important;
  flex: 0 0 auto;
  max-width: 150px;

  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  ${media.tabletP`
    width: 33%;
    max-width: unset;
  `};

  ${media.tabletL`
    max-width: 300px;
    width: 300px;
  `};
`;

const VideoThumbnailLink = styled.div`
  display: block;
  width: 110px;
  max-width: 300px;
  height: 110px;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.tabletP`
    height: 160px;
    width: 100%;
  `};

  ${media.tabletL`
    height: 160px;
  `};
`;

const Checkmark = styled.div`
  display: inline-block;
  transform: rotate(45deg);
  height: 13px;
  width: 9px;
  border-bottom: 2px solid ${({ black }) => black ? '#707070' : '#ccc'};
  border-right: 2px solid ${({ black }) => black ? '#707070' : '#ccc'};
  ${media.tabletL`
    height: 26px;
    width: 18px;
    border-width: 0 3px 3px 0;
  `};
`;

const MemberBadge = styled.div`
  color: white;
  background-color: #666666;
  font-size: 10px;
  position: absolute;
  padding: 2px 5px;
  left: 0;
  bottom: 12px;
  text-align: center;
  line-height: 15px;
  height: 19px;
  border: none;

  ${media.tabletL`
    font-size: 10.5px;
    padding: 5px 10px;
    bottom: 20px;
    line-height: 15px;
    height: 23px;
  `};
`;

const ChapterListItem = ({ pos, sectionSlug, chapter, locale, slug, copy, completed }) => (
  <Wrapper>
    <LeftItem>
      <CheckmarkWrapper>
        {chapter.memberflag !== false && (completed.includes(chapter.chapterNumber) ? <Checkmark black /> : <Checkmark />)}
      </CheckmarkWrapper>
      <TitleWrapper>
        {chapter.memberflag !== false && (
          <Position>
            <sup>
              {copy.chapter} {chapter.chapterNumber}.
            </sup>
          </Position>
        )}
        <Title {...chapter.memberflag === false && { positionEmpty: true }}>
          <Link to={`/${locale}/course/${sectionSlug}/${slug}/${chapter.chapterNumber}`}>{chapter.chapterTitle}</Link>
        </Title>
        {chapter.memberflag !== false && <MemberBadge>{copy.member}</MemberBadge>}
      </TitleWrapper>
      <Description>
        <p>{chapter.chapterDescription}</p>
      </Description>
    </LeftItem>


    <VideoThumbnail>
      <Link
        to={{
          pathname: `/${locale}/course/${sectionSlug}/${slug}/${chapter.chapterNumber}`,
          state: { chapter: chapter },
        }}
      >
        <VideoThumbnailLink
          style={{
            backgroundImage: `url(https:${chapter.chapterImage &&
              chapter.chapterImage.file.url}?w=420&fm=jpg&fl=progressive)`,
          }}
        >
          <IconPlay />
        </VideoThumbnailLink>
      </Link>
    </VideoThumbnail>
  </Wrapper>
);

ChapterListItem.defaultProps = {
  copy: {
    chapter: 'Chapter',
  },
};

ChapterListItem.propTypes = {
  pos: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  chapter: PropTypes.shape({}).isRequired,
  copy: PropTypes.shape({
    chapter: PropTypes.string,
  }).isRequired,
};

export default ChapterListItem;
