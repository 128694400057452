import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Facebook from './assets/icon-facebook.svg';
import Instagram from './assets/icon-instagram.svg';
import Twitter from './assets/icon-twitter.svg';
import YouTube from './assets/icon-youtube.svg';

const Icon = styled.img`
  height: 30px;
  width: 30px;
`;

const icons = {
  Facebook,
  Instagram,
  Twitter,
  YouTube,
};

const SocialIcon = ({ type, url }) => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    <Icon src={icons[type]} alt="social" />
  </a>
);

SocialIcon.propTypes = {
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default SocialIcon;
