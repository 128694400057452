import styled from 'styled-components';

import media from '../../styles/media.style';
import { MOBILE_PADDING, WHITE, SCREEN_LOCKUP_WIDTH, BLACK } from '../../styles/variables.style';

export const QuizResultsHero = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  padding: 34px ${MOBILE_PADDING}px;
  min-height: 200px;
  background-image: url('https://images.ctfassets.net/qf666eyypqhw/3A4CBFhcHkAv7b9LjhayfY/babaab351611b333c2b9805db5db5bbf/video_3x.jpg?w=1440&q=100');

  ${media.tabletL`
    padding: 6em 0 8em;
  `};

  ${media.x2`
    background-image: url('https://images.ctfassets.net/qf666eyypqhw/3A4CBFhcHkAv7b9LjhayfY/babaab351611b333c2b9805db5db5bbf/video_3x.jpg?w=2880&q=100');
  `};

  ${media.x3`
    background-image: url('https://images.ctfassets.net/qf666eyypqhw/3A4CBFhcHkAv7b9LjhayfY/babaab351611b333c2b9805db5db5bbf/video_3x.jpg?w=4320&q=100');
  `};
`;

export const Content = styled.div`
  width: 100%;
  max-width: ${SCREEN_LOCKUP_WIDTH}px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px ${MOBILE_PADDING}px 120px;

  ${media.tabletL`
    padding: 25px 0 150px;
  `};
`;

export const Score = styled.h1`
  font-size: 48px;
  font-weight: 800;
  color: ${WHITE};

  ${media.tabletL`
    font-size: 75px;
  `};
`;

export const ContentText = styled.p`
  max-width: 590px;
  text-align: center;
  font-size: 14px;
  line-height: 30px;
  font-weight: 400;

  ${media.tabletL`
    font-size: 20px;
  `};
`;

export const RegisterBtnWrapper = styled.div`
  margin: 30px 0 0;

  ${media.tabletL`
    margin: 60px 0 0;
  `};

  a,
  a:visited {
    height: 51px;
    line-height: 51px;
    padding: 0 47px;
    background-color: ${BLACK};
    font-size: 12px;
    color: ${WHITE};
    text-transform: uppercase;
    display: block;
    text-decoration: none;
    text-align: center;
    font-weight: 800;
    letter-spacing: 1px;

    ${media.tabletL`
      font-size: 14px;
      height: 82px;
      line-height: 82px;
      padding: 0 100px;
    `};
  }

  a.download {
    background-color: #cf152d;
    margin-bottom: 20px;
    cursor: pointer;
  }
`;

export const ReviewBtnWrapper = styled.div`
  font-size: 12px;
  color: ${BLACK};
  padding: 0;
  margin: 16px 0 0;
  text-decoration: underline;
  text-transform: uppercase;

  a,
  a:visited {
    color: inherit;
    font-weight: 800;
    letter-spacing: 1px;
  }

  ${media.tabletL`
    margin: 30px 0 0;
    font-size: 14px;
  `};

  a:visited {
    color: ${BLACK};
  }
`;

export const ProfileInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: -157px;

  ${media.tabletL`
    bottom: -180px;
  `};
`;

export const ProfileImageWrapper = styled.div`
  position: relative;
`;

export const ProfileImage = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-image: ${({ url }) => `url(${url})`};

  ${media.tabletL`
    height: 170px;
    width: 170px;
  `};
`;

export const ProfileName = styled.h5`
  font-size: 16px;
  font-weight: 800;
  margin: 58px 0 0.3em;
  color: ${BLACK};
  text-transform: uppercase;

  ${media.tabletL`
    font-size: 36px;
    margin: 100px 0 0.3em;
  `};
`;

export const ProfileTitle = styled.p`
  font-size: 11px;
  color: #666;
  margin: 0;
  text-align: center;

  ${media.tabletL`
    font-size: 16px;
  `};
`;

export const Badge = styled.img`
  display: block;
  width: 52px;
  height: auto;
  margin: -25px 0 -30px 0;
`;
