import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/array';
import 'core-js/es6/string';
import 'core-js/es7/object';
import 'whatwg-fetch';

import React from 'react';
import { render } from 'react-dom';
import TagManager from 'react-gtm-module';

import polyfills from './polyfills';
import Root from './Root';
import { store, persistor } from './redux/store';
import { unregister } from './registerServiceWorker';

// import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import AmplifyConfig from './amplify-config';

console.log(AmplifyConfig);
Auth.configure(AmplifyConfig.Auth);

// GTM設定
const tagManagerArgs = {
  gtmId: 'GTM-P5G2HJ3',
};
TagManager.initialize(tagManagerArgs);

if (process.env.NODE_ENV === 'development' && polyfills.length > 0) {
  console.info('Using polyfills: ', polyfills);
}

const rootElement = document.getElementById('root');
render(<Root store={store} persistor={persistor} />, rootElement);

unregister();
