import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { reducer as form } from 'redux-form';
import { reducer as authReducer } from '../../redux/sagas/aws-cognito-redux-saga';

import authRedirect from './authRedirect';
import answers from './answers';
import modules from './modules';
import quizzes from './quizzes';
import user from './user';
import terms from './terms';
import faq from './faq';
import userProgress from './userProgress';
import userAvatar from './userAvatar';
import screenSize from './screenSize';
import sections from './sections';
import siteOptions from './siteOptions';
import signUpForm from './signUpForm';

import storage from 'redux-persist/lib/storage';
import { snsAuthReducer } from '../sagas/sns-auth-redux-saga';

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['snsAuth'],
};

const rootReducer = combineReducers({
  answers,
  auth: authReducer.reducer,
  authRedirect,
  quizzes,
  modules,
  user,
  userProgress,
  form,
  terms,
  faq,
  screenSize,
  sections,
  siteOptions,
  signUpForm,
  userAvatar,
  snsAuth: snsAuthReducer,
});

export default persistReducer(rootPersistConfig, rootReducer);
