import { connect } from 'react-redux';

import PrivateRouteComponent, { OwnerPrivateRoute } from './PrivateRoute';

const mapStatetoProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(PrivateRouteComponent);

export const OwnerRoute = connect(state => ({
  auth: state.auth,
  user: state.user,
}))(OwnerPrivateRoute);
