import { UPDATE_SCREEN_SIZE } from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SCREEN_SIZE: {
      return action.screenSize;
    }
    default:
      return state;
  }
};
