import styled from 'styled-components';

export const Container = styled.div`
  padding: 36px;
  position: relative;
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
`;

export const Form = styled.form``;

export const Title = styled.h2`
  font-weight: normal;
`;

export const Button = styled.button`
  width: 100%;
  height: 60px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const Confirmation = styled.div`
  text-align: center;
  font-size: 14px;
`;
