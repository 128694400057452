import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import ResetPasswordForm from './ResetPasswordForm';

const mapErrorCodeToCopy = {
  UserNotFoundException: 'email_not_found'
};

class ResetPassword extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    forgotPassword: PropTypes.func.isRequired,
    auth: PropTypes.object,
    init: PropTypes.func,
    history: PropTypes.object
  };
  static defaultProps = {
    copy: {
      email_not_found: 'This email address is not registered.'
    }
  };

  UNSAFE_componentWillMount() {
    this.props.init();
  }

  forgotPassword = values => {
    if (!values.email) {
      return;
    }
    this.props.forgotPassword(values.email.toLowerCase());
  };

  changePassword = () => {
    const { match } = this.props;
    this.props.history.push(`/${match.params.lang}/changepassword`);
  };

  render() {
    const { handleSubmit, auth, copy } = this.props;
    let error;
    if (auth && auth.error) {
      const copyName = mapErrorCodeToCopy[auth.error.code];
      error = copyName ? copy[copyName] : auth.error.message;
    }

    return (
      <ResetPasswordForm
        handleSubmit={handleSubmit}
        auth={auth}
        error={error}
        forgotPassword={this.forgotPassword}
        changePassword={this.changePassword}
        copy={copy}
      />
    );
  }
}

// Decorate the form component
export default reduxForm({
  form: 'resetPassword'
})(ResetPassword);
