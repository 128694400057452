import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Placeholder from '../Placeholder';
import {
  LocaleIndicator,
  Selector,
  Flag,
  Panel,
  positionSettings,
  Locale,
  SmallFlag,
  Region,
  Language,
} from './LocaleSelector.style';

class LocaleSelector extends React.Component {
  static defaultProps = {
    availableRegions: [],
    position: 'top',
    show: true,
    full: false,
  };

  static propTypes = {
    availableRegions: PropTypes.arrayOf(PropTypes.shape()),
    locale: PropTypes.string.isRequired,
    position: PropTypes.string,
    show: PropTypes.bool,
    full: PropTypes.bool,
  };

  state = {
    open: false,
  };

  componentDidMount = () => {
    window.addEventListener('touchmove', this.attemptClose);
    window.addEventListener('click', this.attemptClose);
  };

  componentDidUpdate = () => {
    const { show } = this.props;
    if (!show && this.state.open) this.close();
  };

  componentWillUnmount = () => {
    window.removeEventListener('touchmove', this.attemptClose);
    window.removeEventListener('click', this.attemptClose);
  };

  attemptClose = e => {
    if (this.selector && !this.selector.current.contains(e.target)) {
      this.close();
    }
  };

  close = () => {
    this.setState({ open: false });
  };

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  selector = React.createRef();

  render() {
    const { open } = this.state;
    const { availableRegions, full, locale, position } = this.props;
    const url = window.location.pathname
      .split('/')
      .slice(2)
      .join('/');
    const currentRegion = availableRegions.find(region => region.languages.some(lang => lang.locale === locale));
    const currentLanguage = currentRegion && currentRegion.languages.find(language => language.locale === locale);

    availableRegions.sort(function(a, b) {
      var aRegion = a['region'];
      var bRegion = b['region'];
      if (aRegion < bRegion) return -1;
      if (aRegion > bRegion) return 1;
      return 0;
    });

    return (
      <Selector innerRef={this.selector}>
        {currentRegion ? <Flag src={currentRegion.icon} onClick={this.toggle} /> : <Placeholder w="32px" h="24px" />}
        <Panel {...positionSettings[position]} open={open}>
          {availableRegions.map(region => (
            <React.Fragment key={region.region}>
              {region.languages.map(
                lang =>
                  locale === lang.locale ? (
                    <Locale key={lang.locale}>
                      <SmallFlag src={region.icon} />
                      <Region underline>{region.region}</Region>
                      <Language underline>{lang.label}</Language>
                    </Locale>
                  ) : (
                    <a key={lang.locale} to={`/${lang.locale}/${url}`} href={`/${lang.locale}/${url}`}>
                      <Locale>
                        <SmallFlag src={region.icon} />
                        <Region>{region.region}</Region>
                        <Language>{lang.label}</Language>
                      </Locale>
                    </a>
                  )
              )}
            </React.Fragment>
          ))}
        </Panel>

        {full && (
          <LocaleIndicator onClick={this.toggle}>
            {currentRegion ? `${currentRegion.region} / ${currentLanguage.label}` : <Placeholder w="60px" h="15px" />}
          </LocaleIndicator>
        )}
      </Selector>
    );
  }
}

const mapState = state => ({
  availableRegions: state.siteOptions.availableRegions,
});

export default connect(mapState)(LocaleSelector);
