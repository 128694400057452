import { UPDATE_AUTH_REDIRECT } from '../constants';

export default (state = null, action) => {
  switch (action.type) {
    case UPDATE_AUTH_REDIRECT: {
      return action.payload;
    }
    default:
      return state;
  }
};
