import { Auth } from 'aws-amplify';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { snsAuthAction } from '../../../redux/sagas/sns-auth-redux-saga';

const Container = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    z-index: 1000;
`;

const Dialog = styled.div`
    padding: 1rem;
    background: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: 1rem;
`;

const ConfirmButton = styled.button`
    display: inline-block;
    width: 150px;
    padding: 1rem;
    background: black;
    border: 1px solid transparent;
    text-align: center;
    color: white;
    cursor: pointer;

    :disabled {
        background: rgba(0, 0, 0, 0.5);
        cursor: wait;
    }
`;

const CancelButton = styled(Link)`
    display: inline-block;
    width: 150px;
    padding: 1rem;
    background: white;
    border: 1px solid black;
    text-align: center;
    color: black;
    cursor: pointer;
`;

class DeleteAccount extends Component {
    constructor() {
        super();

        this.state = {
            processing: false,
        };
    }

    confirm = e => {
        const { dispatch, match } = this.props;
        const locale = match.params.lang;

        this.setState({ processing: true });

        dispatch(snsAuthAction.snsLinkOffRequest({ provider: 'SignInWithApple' }));

        setTimeout(function () {
            Auth.currentAuthenticatedUser({ bypassCache: true })
                .then(
                    user => {
                        const { attributes } = user;
                        const email = `${attributes.email}.${Date.now().toString(16)}.deprecated`;

                        return Auth.updateUserAttributes(user, {
                            'email': email,
                            'custom:master_email': email,
                            'custom:apple_id': '',
                            'custom:facebook_id': ''
                        });
                    }
                )
                .then(
                    result => Auth.signOut()
                )
                .then(
                    () => {
                        window.location.href = `/${locale}`;
                    }

                )
                .catch(
                    e => console.log('DEBUG: Failed, ' + e)
                );
        }, 3000);
    };

    render() {
        const { match } = this.props;
        const locale = match.params.lang;

        return (
            <Container>
                <Dialog>
                    <h1 style={{ marginTop: '0' }}>Delete Account</h1>
                    <p>Are you sure?</p>
                    <ButtonGroup>
                        <ConfirmButton type="button" disabled={this.state.processing} onClick={this.confirm}>Confirm</ConfirmButton>
                        <CancelButton to={`/${locale}/dashboard/settings`}>Cancel</CancelButton>
                    </ButtonGroup>
                </Dialog>
            </Container>
        );
    }
}

export default connect()(DeleteAccount);
