import React from 'react';
import PropTypes from 'prop-types';

import { Container, Title, Form, Button } from './SignIn.style';

const PasswordReset = ({ onSubmit, copy }) => (
  <Container>
    <Title>{copy.change_password}</Title>
    <Form onSubmit={onSubmit}>
      <Button type="submit">{copy.reset_passsword}</Button>
    </Form>
  </Container>
);

PasswordReset.defaultProps = {
  copy: {
    change_password: 'Change Password',
    reset_passsword: 'Reset Password',
  },
};

PasswordReset.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default PasswordReset;
