export const fixedFetch = (path, config) =>
  new Promise((resolve, reject) => {
    fetch(path, config)
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => reject(err));
  });

export const getScreenWidth = () =>
  window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export const getScreenSize = () => {
  const width = getScreenWidth();
  return [600, 900, 1200, 1800, Infinity].findIndex(item => item > width);
};

export const throttle = (callback, wait, context = this, ...rest) => {
  let timeout = null;
  let callbackArgs = null;

  const later = () => {
    callback.apply(context, callbackArgs);
    timeout = null;
  };

  return () => {
    if (!timeout) {
      callbackArgs = rest;
      timeout = setTimeout(later, wait);
    }
  };
};

export const toParameterString = params => {
  Object.values(params).forEach(item => {
    if (typeof item !== 'number' && (typeof item !== 'string' || item.length <= 0)) {
      throw new Error('Arguments must be number or non-empty strings!');
    }
  });
  const list = Object.keys(params).map(key => `${key}=${params[key]}`);
  return list.join('&');
};

export const errorLogger = error => {
  let e = error;
  if (e.data) e = e.data;
  if (e.message) e = e.message;
  if (process.env.NODE_ENV !== 'production') {
    console.error(`Unhandled rejection caught with reason: ${JSON.stringify(e)}.`);
  }
};

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0; // eslint-disable-line
    const v = c == "x" ? r : (r & 0x3) | 0x8; // eslint-disable-line
    return v.toString(16);
  });
}

export const findParent = (selector, element) =>
  (typeof selector === 'string' && element && element.matches && element.matches(selector)) || selector === element
    ? element
    : element.parentNode && findParent(selector, element.parentNode);

export const initialValidatorObject = (v_translate, props) =>
  Object.keys(v_translate).reduce(
    (prev, key) => ({
      ...prev,
      [key]: v_translate[key](props.copy),
    }),
    {}
  );

export const updateValidatorObject = (v_translate, oldCopy, newCopy) => {
  const update = Object.keys(v_translate).filter(key => newCopy[key] !== oldCopy[key]);
  if (update.length === 0) {
    return null;
  }
  return update.reduce(
    (prev, key) => ({
      ...prev,
      [key]: v_translate[key](newCopy),
    }),
    {}
  );
};

export const isOwner = userRole => {
  const ownerRole = [1, 11, 31];
  return ownerRole.indexOf(userRole) > -1;
};

export const makeCertificateData = ({ locale, user, section }) => ({
  locale,
  user: {
    id: user.userId,
    name: `${user.given_name} ${user.family_name}`,
  },
  certificate: {
    course: {
      slug: section.slug,
      name: section.sectionTitle,
      level: section.level,
    },
    chapters: section.modules.map(m => m.moduleTitle),
  }
});

export const checkIfSameCertificate = ({ locale, user, section }) => cer => {
  return (
    cer.locale === locale &&
    cer.user &&
    cer.user.id === user.userId &&
    cer.user.name === `${user.given_name} ${user.family_name}` &&
    cer.certificate &&
    cer.certificate.course.slug === section.slug &&
    cer.certificate.course.name === section.sectionTitle &&
    cer.certificate.course.level === section.level &&
    cer.certificate.chapters.every((c, i) => c === section.modules.map(m => m.moduleTitle)[i])
  );
}
