import { GET_SECTIONS } from '../constants';

// const tagsPattern = [
//   ["hair", "god"],
//   ["SUPER"],
//   ["mic", "god"]
// ];


export default (state = [], action) => {
  switch (action.type) {
    case `${GET_SECTIONS}_PENDING`:
      return state;
    case `${GET_SECTIONS}_FULFILLED`: {
      // return action.payload.map((section, i) => ({
      //   slug: `${i}`,
      //   level: "Basic",
      //   category: "Hair Care",
      //   courseDuration: 18,
      //   contents: "theory",
      //   tags: tagsPattern[3 % i],
      //   heroImage: { file: { url: "//images.ctfassets.net/qf666eyypqhw/djfPy32mL6umwyQe8YCao/deeec5057a9f895e7a57c9b71395b903/black_shot.jpg" }},
      //   thumbnail: { file: { url: "//images.ctfassets.net/qf666eyypqhw/6Q1d1la7kIWkm86EomkaoQ/0a2654a97d51cf23198d197fb07ee9b5/L-1.jpg" }},
      //   ...section
      // }));
      return action.payload;
    }
    case `${GET_SECTIONS}_REJECTED`:
      return state;
    default:
      return state;
  }
};
