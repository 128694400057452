import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUser, fetchUserAvatar } from '../../redux/actions/user';
import { fetchUserProgress, updateUserProgress } from '../../redux/actions/userProgress';
import fetchSections from '../../redux/actions/sections';
import fetchModules from '../../redux/actions/modules';
import styled from 'styled-components';

import Loading from '../../components/Loading';
import UserSettingsForm from './UserSettingsForm';
import { SCREEN_LOCKUP_WIDTH, MOBILE_PADDING } from '../../styles/variables.style';
// import { reducer } from '../../redux/sagas/aws-cognito-redux-saga';
import { snsAuthAction } from '../../redux/sagas/sns-auth-redux-saga';

const Wrapper = styled.div`
  background-color: #fff;
  max-width: ${SCREEN_LOCKUP_WIDTH + MOBILE_PADDING * 2}px;
  width: 100%;
  padding: 0 ${MOBILE_PADDING}px;

  margin: 70px auto;
`;

class UserSettings extends Component {
  static defaultProps = {
    userProgress: [],
  };

  UNSAFE_componentWillMount() {
    const {
      modules,
      fetchModules,
      match,
      user,
      auth,
      fetchUser,
      fetchUserProgress,
      fetchUserAvatar,
      fetchSections,
      userAvatar,
      sections,
    } = this.props;

    if (modules.length === 0) {
      fetchModules({ limit: 15, order: 'fields.moduleTitle' }, match.params.lang);
    }

    if (!sections.length) {
      fetchSections({ order: '-fields.date', moduleOrder: 'fields.moduleTitle' }, match.params.lang);
    }

    if (auth.info && auth.info.username && user.length === 0) {
      fetchUser(auth.info.username);
      fetchUserProgress(auth.info.username);
    }

    if (user && user.avatar && userAvatar.length === 0) {
      fetchUserAvatar(user.avatar);
    }
  }

  componentWillReceiveProps(prevProps) {
    const {
      modules,
      fetchModules,
      match,
      user,
      auth,
      fetchUser,
      fetchUserProgress,
      fetchUserAvatar,
      fetchSections,
      userAvatar,
      sections,
    } = prevProps;

    if (modules.length === 0) {
      fetchModules({ limit: 15, order: 'fields.moduleTitle' }, match.params.lang);
    }

    if (!sections.length) {
      fetchSections({ order: '-fields.date', moduleOrder: 'fields.moduleTitle' }, match.params.lang);
    }

    if (auth.info && auth.info.username && user.length === 0) {
      fetchUser(auth.info.username);
      fetchUserProgress(auth.info.username);
    }

    if (user && user.avatar && userAvatar.length === 0) {
      fetchUserAvatar(user.avatar);
    }
  }

  componentDidMount = () => {
    const {
      modules,
      fetchModules,
      match,
      user,
      auth,
      fetchUser,
      fetchUserProgress,
      fetchUserAvatar,
      fetchSections,
      sections,
    } = this.props;

    if (modules.length === 0) {
      fetchModules({ limit: 15, order: 'fields.moduleTitle' }, match.params.lang);
    }

    if (!sections.length) {
      fetchSections({ order: '-fields.date', moduleOrder: 'fields.moduleTitle' }, match.params.lang);
    }

    if (auth.info && auth.info.username && user.length === 0) {
      fetchUser(auth.info.username);
      fetchUserProgress(auth.info.username);
    }

    if (user.length) {
      fetchUserAvatar(user.avatar);
    }
  };

  render() {
    const {
      user,
      siteOptions,
      sections,
      screenSize,
      match,
      auth,
      snsAuth,
      userAvatar,
      userProgress,
      updateUserProgress,
      modules,
      snsLinkRequest,
      snsLinkOffRequest,
      signIn,
    } = this.props;
    const locale = match.params.lang;
    let completedQuizzes;
    let isCompleted = false;

    if (userProgress.data) {
      completedQuizzes = JSON.parse(userProgress.data);
    }

    if (completedQuizzes) {
      if (completedQuizzes.completedQuizzes.length === modules.length) {
        isCompleted = true;
      }
    }

    const certificates =
      sections && completedQuizzes
        ? sections.filter(s => s.modules.length).map(section => {
            const isCompleted = section.modules.every(m => completedQuizzes.completedQuizzes.includes(m.moduleSlug));
            return {
              ...section,
              isCompleted,
            };
          })
        : [];

    return (
      <Wrapper>
        {user.length !== 0 ? (
          <UserSettingsForm
            formData={user}
            user={user}
            userAvatar={userAvatar}
            userProgress={userProgress}
            updateUserProgress={updateUserProgress}
            sections={sections}
            copy={siteOptions.siteCopyELearningDashboardSettings}
            locale={locale}
            auth={auth}
            snsAuth={snsAuth}
            isCompleted={isCompleted}
            certificates={certificates}
            screenSize={screenSize}
            snsLinkRequest={snsLinkRequest}
            snsLinkOffRequest={snsLinkOffRequest}
            signIn={signIn}
          />
        ) : (
          <Loading />
        )}
      </Wrapper>
    );
  }
}

const mapState = ({ auth, snsAuth, user, siteOptions, screenSize, sections, userAvatar, userProgress, modules }) => ({
  auth,
  snsAuth,
  user,
  siteOptions,
  screenSize,
  sections,
  userAvatar,
  userProgress,
  modules,
});
const mapDispatch = dispatch => ({
  fetchUser: username => dispatch(fetchUser(username)),
  fetchUserProgress: username => dispatch(fetchUserProgress(username)),
  updateUserProgress: (username, data, isCompleted) => dispatch(updateUserProgress(username, data, isCompleted)),
  fetchUserAvatar: key => dispatch(fetchUserAvatar(key)),
  fetchModules: (options, locale) => dispatch(fetchModules(options, locale)),
  fetchSections: (options, locale) => dispatch(fetchSections(options, locale)),
  snsLinkRequest: data => dispatch(snsAuthAction.snsLinkRequest(data)),
  snsLinkOffRequest: data => dispatch(snsAuthAction.snsLinkOffRequest(data)),
});

export default (UserSettings = connect(mapState, mapDispatch)(UserSettings));
